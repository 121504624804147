import { BsDash, BsTrashFill, BsPlus } from "react-icons/bs";
import { Flex, Box, Text, Button, Select } from "@chakra-ui/react";
import { format, parse, isSameDay } from "date-fns";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { generateStartTime, generateStopTime } from "../utils/timeFuction";
import { AiOutlineInfoCircle } from "react-icons/ai";

const TimeActionsRow = ({
  time,
  handleChange,
  setDividedTimes,
  handleDelete,
  setActionNot,
}) => {
  const pollDuration = useSelector(
    (state) => state.polls.createPollData.poll_duration
  );

  const [allTimes, setAllTimes] = useState([]);
  const [allStopTimes, setAllStopTimes] = useState([]);

  // console.log("time", time.start, time.end)
  const startTime = time.start.split(":");
  const startHour = startTime[0] * 60;
  const startMins = startTime[1];
  const totalStart = parseInt(startHour) + parseInt(startMins);
  const endTime = time.end.split(":");
  const endHour = endTime[0] * 60;
  const endMins = endTime[1];
  const totalEnd = parseInt(endHour) + parseInt(endMins);
  const dividedTime = (totalEnd - totalStart) % pollDuration;
  setDividedTimes(dividedTime);

  const hr = startTime?.[0];

  useEffect(() => {
    generateStartTime(15, setAllTimes, 0);
    generateStopTime(parseInt(pollDuration), setAllStopTimes, hr, startMins, 0);
  }, [pollDuration, hr, startMins]);

  if (time.start >= time.end || time.start === null) {
    setActionNot(true);
  } else {
    setActionNot(false);
  }

  return (
    <Flex
      bg="white"
      align="center"
      sx={{
        "& * input": {
          height: "2rem",
          boxSizing: "border-box",
          border: "1px solid gray",
          borderRadius: "md",
          px: 2,
          w: ["6rem", "6rem", "auto", "auto", "auto"],
          fontSize: ["sm", "sm", "auto", "auto", "auto"],
        },
      }}
    >
      <Flex mr={2} align="center">
        <Box>
          <Select
            placeholder="From"
            w="120px"
            name="start"
            // value={time.start}
            onChange={(e) => handleChange(e, time)}
          >
            {allTimes.map((singleTime, index) => {
              return (
                <option key={index} value={singleTime}>
                  {format(parse(singleTime, "HH:mm:ss", new Date()), "hh:mm a")}
                </option>
              );
            })}
          </Select>
         
        </Box>
        <Box mx={1}>
          <BsDash size="1rem" />
        </Box>
        <Box>
          <Select
            placeholder={"To"}
            w="120px"
            name="end"
            // value={time.start}
            onChange={(e) => handleChange(e, time)}
          >
            {allStopTimes.map(
              (singleTime, index) =>
                singleTime > time.start && (
                  <option key={index} value={singleTime}>
                    {format(
                      parse(singleTime, "HH:mm:ss", new Date()),
                      "hh:mm a"
                    )}
                  </option>
                )
            )}
          </Select>
        
        </Box>
      </Flex>
      <BsTrashFill
        color="#757575"
        style={{ marginRight: ".2rem", cursor: "pointer" }}
        size="1rem"
        onClick={() => handleDelete(time)}
      />
    </Flex>
  );
};

export const MultipleTimeTab = ({
  selectedDates,
  setSelectedDates,
  times,
  timeConvert,
  actionNot,
  dividedTimes,
  setDividedTimes,
  setActionNot,
  handleDuration,
  selectedDuration,
  setSelectedDuration,
}) => {
  const [closeTime, setCloseTime] = useState("");
  const pollDuration = useSelector(
    (state) => state.polls.createPollData?.poll_duration
  );

  const handleChange = (e, timeDate, timeId) => {
    const { name, value } = e.target;

    const filteredDates = selectedDates.map((selectedDate) =>
      selectedDate.date === timeDate.date
        ? {
            ...timeDate,
            date: timeDate.date,
            times: selectedDate.times.map((selectedTime) => {
              return selectedTime.id === timeId
                ? { ...selectedTime, [name]: value }
                : selectedTime;
            }),
          }
        : selectedDate
    );
    setSelectedDates(filteredDates);
  };

  const handleAdd = (timeDate) => {
    const filteredDates = selectedDates.map((selectedDate) => {
      return selectedDate.date === timeDate.date
        ? {
            ...timeDate,
            date: timeDate.date,
            times: [
              ...timeDate.times,
              {
                id: Math.floor(Math.random() * 50),
                start: "07:00",
                end: "08:30",
              },
            ],
          }
        : selectedDate;
    });
    setSelectedDates(filteredDates);
  };

  const handleDelete = (timeDate, timeId) => {
    let filteredDates = selectedDates.map((selectedDate) => {
      return selectedDate.date === timeDate.date
        ? {
            ...selectedDate,
            date: selectedDate.date,
            times: selectedDate.times.filter((selectedTime) => {
              return selectedTime.id !== timeId;
            }),
          }
        : selectedDate;
    });
    if (timeDate.times.length === 1) {
      filteredDates = filteredDates.filter(
        (dateObj) => dateObj.date !== timeDate.date
      );
    }
    setSelectedDates(filteredDates);
  };

  return (
    <Flex
      direction="column"
      alignItems="center"
      overflowY="scroll"
      height="90%"
      p={2}
      sx={{
        "&::-webkit-scrollbar": {
          width: "15px",
          "&-track": {
            backgroundColor: "#F9F9F9",
            borderRadius: " 100px",
          },
          "&-thumb": {
            border: "5px solid transparent",
            borderRadius: "100px",
            backgroundColor: "#e4e4e4",
            backgroundClip: "content-box",
          },
        },
      }}
    >
      <Text fontSize="18px" color="#707070" pl="2">
        Different time each day{" "}
        <Box as="span" fontSize="14px">
          (click date to select time)
        </Box>
      </Text>
      <Flex
        // bg="#fed7d7"
        bg={dividedTimes === 0 ? "#f2f2f2" : "#fed7d7"}
        p="5px"
        justifyContent={"space-between"}
        borderRadius={"3px"}
      >
        <AiOutlineInfoCircle size="16px" color="red" />
        <Text
          fontSize={["11px", "12px"]}
          // mb="10px"
          textAlign={"center"}
          color="#707070"
          px={["4","4"]}
        >
          {`The meeting time selected will be splitted by ${timeConvert(pollDuration)}`
          }
        </Text>
      </Flex>
      {selectedDates.map((date) => {
        return (
          <Flex
            direction="column"
            alignItems="center"
            key={date.date}
            _notLast={{ mb: [1, 1, 2, 2] }}
          >
            {selectedDates.some((selectedDate) => {
              const allDates = parse(
                selectedDate.date,
                "dd-MM-yyyy",
                new Date()
              );
              const currDate = parse(date.date, "dd-MM-yyyy", new Date());
              return isSameDay(allDates, currDate);
            }) && (
              <Flex justify="space-between" align="center">
                <Text fontSize="sm" color="#707070" pl="4" fontWeight="500">
                  {format(parse(date.date, "dd-MM-yyyy", new Date()), "MMM dd")}
                </Text>
              </Flex>
            )}
            {date.times.map((time) => (
              <TimeActionsRow
                key={time.id}
                time={time}
                handleDelete={() => handleDelete(date, time.id)}
                handleChange={(e) => handleChange(e, date, time.id)}
                actionNot={actionNot}
                closeTime={closeTime}
                setCloseTime={setCloseTime}
                setActionNot={setActionNot}
                dividedTimes={dividedTimes}
                setDividedTimes={setDividedTimes}
                handleDuration={handleDuration}
                selectedDuration={selectedDuration}
                setSelectedDuration={setSelectedDuration}
              />
            ))}
            <Button
              mt={2}
              bg="#F8F8F8"
              _hover={{ bg: "transparent" }}
              color="inherit"
              onClick={() => handleAdd(date)}
              rightIcon={
                <BsPlus
                  color="#757575"
                  style={{ marginRight: ".2rem", cursor: "pointer" }}
                  size="2rem"
                />
              }
            >
              Add Time
            </Button>
          </Flex>
        );
      })}
    </Flex>
  );
};
