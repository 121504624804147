import React from "react"
import { Box, Heading, Text } from "@chakra-ui/react"
// import msTeams from "../../assets/ms-teams.svg"
import hr from "../../assets/hrr.jpg"
import chief from "../../assets/chief.jpg";
import { TestimonialContent } from "./TestimonialContent"
import { Splide, SplideSlide } from "@splidejs/react-splide"
import "@splidejs/splide/dist/css/splide.min.css"
const datas = [
  {
    description: `I use it to plan meetings with team members, project
              collaborators, and external suppliers. It’s such a simple concept!`,
    img: hr,
    name: "Elizabeth Oyok",
    // company: "SKILLUP AFRICA",
    // companyImg: msTeams,
  },
  {
    description: `These guys know what they are doing, it has helped us greatly`,
    img: chief,
    name: "Jaabir Adekunle-Jimoh",
    // company: "Apple TEAM",
    // companyImg: msTeams,
  },
];

export const Testimonial = () => {
  return (
    <section style={{ marginBottom: "40px", textAlign: "left" }}>
      <Text textAlign="center" color="#6C4097" mb="10px" fontWeight="bold">
        TESTIMONIALS
      </Text>
      <Heading fontSize="1.5em" textAlign="center" color="#6C4097" mb="40px">
        Why people love Fixmeet
      </Heading>
      <Box bg="#6C4097" p="20px" borderRadius="10px">
        <Splide
          options={{
            rewind: true,
            autoplay: true,
            pauseOnHover: true,
            arrows: false,
          }}
        >
          {datas.map((data, id) => {
            return (
              <SplideSlide key={id}>
                <TestimonialContent
                  description={data?.description}
                  img={data?.img}
                  // companyImg={data.companyImg}
                  name={data?.name}
                  company={data?.company}
                  key={id}
                />
              </SplideSlide>
            )
          })}
        </Splide>
      </Box>
    </section>
  )
}
