import http from "../../../services/api";
import { AUTH_ROUTES } from "../../../services/routes.constants";

export const getContacts = async () => {
  const response = await http.get(AUTH_ROUTES.GET_CONTACTS);
  return response;
};

export const getGroups = async () => {
  const response = await http.get(AUTH_ROUTES.GET_GROUPS);
  return response;
};

export const getGroupContacts = async (groupId) => {
  const response = await http.get(AUTH_ROUTES.GET_GROUP_CONTACTS(groupId));
  return response;
};

export const sendInvites = async (formData) => {
  const response = await http.post(AUTH_ROUTES.SEND_INVITES, formData);
  return response;
};
