import { Link, useNavigate } from "react-router-dom";
import { Flex, Button, Circle, Box, Tooltip, Image } from "@chakra-ui/react";
import { RiCustomerService2Fill } from "react-icons/ri";
import { IoMdPerson } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../features/user";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { useState } from "react";

import logo from "../pages/landingPage/assets/fixmeet-logo.png";

const Nav = () => {
  const userName = useSelector((state) => state?.user?.name);
  const userzone = useSelector((state) => state?.user?.timezone);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const isLoggedIn = useSelector(state=>state?.user?.isLoggedIn)
  const [loading, setLoading] = useState(false)
  return (
    <Flex
      p={2}
      px={["5px", "30px"]}
      align="center"
      justify="space-between"
      boxShadow="md"
      pos="sticky"
      top={0}
      left={0}
      bg="white"
      zIndex={5}
      h="4rem"
    >
      <Flex width="15%" justify="space-between" align="center">
        <Link to="/dashboard">
          <Box width={["100px", "100px", "120px", "100%"]}>
            <Image
              mr="20px"
              src={logo}
              alt="fixmeet logo"
              width={["100px", "150px", "150px", "150px"]}
            />
          </Box>
        </Link>
        <Circle
          color="primary"
          border="2px solid #F3E6FF"
          p={2}
          ml={["10px", "20px"]}
        >
          <RiCustomerService2Fill
            cursor={"pointer"}
            onClick={() => navigate("/contact-us")}
            color="inherit"
            fontSize="1.2rem"
          />
        </Circle>
      </Flex>
      <Flex width={["50%", "25%"]} justify="flex-end" align="center">
        {(userName === "" || userzone === "") && isLoggedIn && (
          <Tooltip
            hasArrow
            placement="top"
            isOpen={open}
            // closeOnClick={open}
            label={
              userName === ""
                ? "Please update your name"
                : "Please update your timezone to get accurate poll times"
            }
          >
            <Box
              mt="-20px"
              mr="-10px"
              zIndex={100}
              onClick={() => setOpen(!open)}
            >
              <BsFillInfoCircleFill color="red" />
            </Box>
          </Tooltip>
        )}
        {isLoggedIn ? (
          <Link to="profile">
            <Circle color="primary" border="2px solid #F3E6FF" p={2} mr={4}>
              <IoMdPerson color="inherit" fontSize="1.2rem" />
            </Circle>
          </Link>
        ) : (
          <Button
            bg="none"
            color="primary"
            borderColor="primary"
            borderRadius={"5px"}
            // border="10px"
            variant={"outline"}
            _hover={{ bg: "#fff" }}
            fontSize={["0.8rem", "0.9rem", "1rem"]}
            px={["30px", "50px"]}
            mr={["10px", "20px"]}
            onClick={() => navigate("/login")}
          >
            Log In
          </Button>
        )}
        {isLoggedIn ? (
          <Button
            bg="none"
            _hover={{ bg: "none" }}
            fontSize={["0.8rem", "0.9rem", "1.3rem"]}
            color="primary"
            onClick={() => {
              setLoading(!loading);
              dispatch(logoutUser())}}
            isLoading={loading}
          >
            LOGOUT
          </Button>
        ) : (
          <Button
            bg="primary"
            _hover={{ bg: "primary" }}
            fontSize={["0.8rem", "0.9rem", "1rem"]}
            color="#fff"
            px={["30px", "20px"]}
            onClick={() => navigate("/login")}
            isLoading={user?.loading}
          >
            Get Started
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default Nav;
