import { useState, useRef  } from "react";
import { Navigate, Link } from "react-router-dom";
import { Button, Text, FormLabel, PinInputField, PinInput, HStack, Box, Flex } from "@chakra-ui/react";
// import GoogleLoginButton from "./GoogleLoginButton";
import { loginUser } from "../../../features/user";
import { useDispatch, useSelector } from "react-redux";
import { BiArrowBack } from "react-icons/bi";
// import { FiEye, FiEyeOff } from "react-icons/fi";

const Login = ({ setLogin, pcode, email, setLoginPage }) => {
  const [formValues, setFormValues] = useState({ email, pin: "" });
  const user = useSelector((state) => state.user);
   const dispatch = useDispatch();
  const formRef = useRef();
  // const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false)

  const handlePinChange = (e) => {
    if (e.length !== 4) return;
    setFormValues((initial) => ({ ...initial, pin: e }));
  };

  const handleSubmit = (e) => {
        setLoading(true);
    e.preventDefault();
    if (!formRef.current.reportValidity()) return;
    dispatch(loginUser(formValues))
    .then(()=>setLoading(false)
    )
    .catch(err=>setLoading(false));
    // setLoading(false)
  };

   if (user.name && user.email) {
     return <Navigate replace to="/dashboard" />;
   }

  return (
    <Box width={"100%"}>
      <Flex my="20px" cursor={"pointer"}>
        <BiArrowBack size={26} onClick={() => setLoginPage("neutral")} />
      </Flex>
      <Text
        as="h1"
        color="primary"
        fontWeight="bold"
        fontSize={["1.7rem", "2rem", "2rem", "2.5rem"]}
        mb={["10px", "15px", "30px"]}
      >
        Login{" "}
      </Text>
      <form ref={formRef}>
        
        <FormLabel fontSize={"20px"} isRequired>
          Enter Pin
        </FormLabel>
        <HStack justifyContent={"left"} spacing="12.4%" width="100%">
          <PinInput
            placeholder=""
            size="lg"
            // size="lg"

            mask
            onChange={(e) => handlePinChange(e)}
          >
            <PinInputField
              // height={16}
              // width={"200px !important"}
              color="#000"
              isRequired
              bg="#fff"
            />
            <PinInputField bg="#fff" />
            <PinInputField bg="#fff" />
            <PinInputField bg="#fff" />
          </PinInput>
        </HStack>
        
        <Flex justifyContent={"flex-end"} width={["94%", "72%"]}>
          <Text alignSelf="center" my="20px">
            Forgot pin?{" "}
            <Link to="../forget-password">
              <Text
                as="span"
                color="primary"
                fontWeight="bold"
                onClick={() => setLogin(true)}
                cursor="pointer"
              >
                Click here
              </Text>
            </Link>
          </Text>
        </Flex>
       
        <Button
          h={["40px", "40px", "50px"]}
          width={["96%", "100%", "100%", "72%"]}
          isLoading={loading}
          mb={["20px"]}
          type="submit"
          onClick={handleSubmit}
          // mb={2}
          isDisabled={formValues?.email === "" || formValues?.pin === ""}
          _hover={{ bg: "primary" }}
          fontSize="20px"
        >
          Send
        </Button>
        {/* <GoogleLoginButton pcode={pcode} /> */}
      </form>
    </Box>
  );
};

export default Login;
