import { useState } from "react";
import { Flex, Divider, Box } from "@chakra-ui/react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import Contact from "./Contact";
import ContactGroup from "./ContactGroup";

const PaginatedContacts = ({
  contacts: contactsProps,
  contactsPerPage: contactsPerPageProps,
  setRefresh,
  group,
}) => {
  const [paginatedContacts, setPaginatedContacts] = useState({
    contacts: contactsProps,
    currentIndex: 1,
    contactsPerPage: contactsPerPageProps,
  });
  const { contacts, currentIndex, contactsPerPage } = paginatedContacts;

  const indexOfLastContact = currentIndex * contactsPerPage;
  const indexOfFirstContact = indexOfLastContact - contactsPerPage;
  const currentContacts = contacts.slice(
    indexOfFirstContact,
    indexOfLastContact
  );
  const prev = currentIndex > 0 ? currentIndex - 1 : 0;
  const last = Math.ceil(contacts.length / contactsPerPage);
  // const next = last === currentIndex ? currentIndex : currentIndex + 1;

  const handlePreviousClick = () => {
    setPaginatedContacts({
      ...paginatedContacts,
      currentIndex: currentIndex - 1,
    });
  };

  const handleNextClick = () => {
    setPaginatedContacts({
      ...paginatedContacts,
      currentIndex: currentIndex + 1,
    });
  };

  return (
    currentContacts.length > 0 && (
      <>
        {currentContacts?.map((contact) => {
          return (
            <Box key={contact.id}>
              {group ? (
                <ContactGroup
                  group={contact}
                  key={contact.id}
                  setRefresh={setRefresh}
                  contacts={contacts}
                />
              ) : (
                <Contact
                  contact={contact}
                  key={contact?.id}
                  id={contact?.id}
                  setRefresh={setRefresh}
                />
              )}
              <Divider />
            </Box>
          );
        })}
        <Flex
          justify="flex-end"
          color="primary"
          mt={3}
          mr={3}
          mb={4}
          sx={{
            "& > div": {
              align: "center",
              justify: "center",
              borderWidth: "1px",
              borderColor: "primary",
              cursor: "pointer",
            },
          }}
        >
          {!(prev === 0) && (
            <Flex borderLeftRadius="md" borderRightRadius="none">
              <MdKeyboardArrowLeft
                onClick={handlePreviousClick}
                size="1.8rem"
              />
            </Flex>
          )}
          {!(currentIndex === last) && (
            <Flex
              align="center"
              justify="center"
              borderWidth="1px"
              borderColor="primary"
              borderLeftRadius="none"
              borderRightRadius="md"
            >
              <MdKeyboardArrowRight onClick={handleNextClick} size="1.8rem" />
            </Flex>
          )}
        </Flex>
      </>
    )
  );
};

export default PaginatedContacts;
