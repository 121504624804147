import { useEffect, useState, useRef } from "react";
import { Flex, Circle, Text, Button, Box } from "@chakra-ui/react";
import {
  MdPerson,
  // MdPersonAddAlt,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  // MdDelete,
} from "react-icons/md";
import {
  addMembersToGroup,
  deleteMembersFromGroup,
} from "../services/groupContact";
import BulkUpload from "./BulkUpload";
import {
  errorNotifier,
  fileUploadNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import { uploadContacts } from "../services/singleContact";

const GroupMember = ({
  id,
  selectedMembers,
  setSelectedMembers,
  name,
  email,
  setShowDeleteBtn,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (e) => {
    setIsChecked(!isChecked);
    if (selectedMembers.includes(parseInt(e.target.id))) {
      setSelectedMembers(
        selectedMembers.filter(
          (selectedMember) => selectedMember !== parseInt(e.target.id)
        )
      );
      return;
    }
    setSelectedMembers([...selectedMembers, parseInt(e.target.id)]);
  };
  selectedMembers.length > 0 ? setShowDeleteBtn(true) : setShowDeleteBtn(false);
  return (
    <Flex
      color="#A8A5A5"
      align="center"
      bg="#F7EFFF"
      px={4}
      py={1}
      justify="space-between"
    >
      <Flex align="center">
        <Circle p={2} bg="#F9F3FF" mr={4} alignSelf="center" color="primary">
          <MdPerson size="1.2rem" />
        </Circle>
        <Flex direction="column">
          <Text>{name}</Text>
          <Text>{email}</Text>
        </Flex>
      </Flex>
      <label htmlFor={id} onChange={handleChange}>
        <input
          onChange={handleChange}
          type="checkbox"
          id={id}
          checked={isChecked}
          style={{ width: "0", position: "absolute", opacity: "0" }}
        />
        {isChecked ? (
          <MdCheckBox color="#45DB3A" size="1.5rem" />
        ) : (
          <MdCheckBoxOutlineBlank size="1.5rem" />
        )}
      </label>
    </Flex>
  );
};

const AddorDeleteGroupMemberForm = ({
  contacts,
  groupId,
  remove,
  onClose,
  setRefresh,
}) => {
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDeleteBtn, setShowDeleteBtn] = useState(false);
  const [inputFile, setInputFile] = useState("");
  const toastRef = useRef();

  const payload = {
    contacts: selectedMembers,
    group_id: groupId,
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (remove) {
      await deleteMembersFromGroup(payload, onClose, setLoading);
      return;
    }
    await addMembersToGroup(payload, onClose, setLoading);
  };

  async function uploadHandler(file) {
    let formData = new FormData();
    if (file !== "") {
      formData.append("contacts", file);
      formData.append("group_id", groupId);
      try {
        await uploadContacts(formData);
        successNotifier("Contacts added to your List");
      } catch (e) {
        errorNotifier("Failed to add contacts to your list");
      }
    }
  }

  useEffect(() => {
    if (inputFile) {
      fileUploadNotifier(toastRef);
    }
    uploadHandler(inputFile)
      .then(() => {
        setRefresh([]);
      })
      .finally(() => toastRef.current?.onClose());
    // eslint-disable-next-line
  }, [inputFile, setRefresh]);

  return (
    <>
      <Box>
        <Flex justify="center">
          <Text fontSize="1.1rem" color="primary" w="100%" fontWeight="normal">
            {remove ? "Contacts" : "Add Contact"}
          </Text>
        </Flex>
        <Flex
          direction="column"
          gridGap={2}
          py={2}
          maxH="300px"
          overflowY="scroll"
        >
          {contacts.map((item, i) => (
            <GroupMember
              key={i}
              id={item?.id}
              name={item?.name}
              email={item?.email}
              selectedMembers={selectedMembers}
              setSelectedMembers={setSelectedMembers}
              setShowDeleteBtn={setShowDeleteBtn}
              showDeleteBtn={showDeleteBtn}
            />
          ))}
        </Flex>
      </Box>
      <Flex
        direction={["column", "column", "row"]}
        justifyContent="space-between"
        alignItems="center"
        width={"100%"}
        p="10px"
      >
        <Button
          mt="10px"
          mr="10px"
          color="#fff"
          w={["100%", "100%", "300px"]}
          fontWeight="bold"
          bg={remove ? "#f39ebb" : "#6c4097"}
          _hover={{ bg: "#6c4097" }}
          onClick={handleSubmit}
          disabled={selectedMembers.length === 0 ? true : null}
          isLoading={loading}
          display={showDeleteBtn ? "block" : "none"}
        >
          {remove ? "Delete Contact" : "Add Contact"}
        </Button>
        <Box mt="10px" width={["100%", "100%", "300px"]}>
          <BulkUpload
            modalName={"Upload Bulk Contact"}
            setInputFile={setInputFile}
            bg={remove ? "#6c4097" : "#f39ebb"}
          />
        </Box>
      </Flex>
    </>
  );
};

export default AddorDeleteGroupMemberForm;
