import { Flex, Circle, Text, Spinner, Tooltip, Image } from "@chakra-ui/react";
import { IoMdPerson } from "react-icons/io";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { BsShare } from "react-icons/bs"
import { MdOutlinePeopleAlt } from "react-icons/md";
import { getPollSummary, getPollTimes } from "../services/pollSummary";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { savePollData, savePollTimes } from "../../../features/poll";
import { useDispatch, useSelector } from "react-redux";
import { errorNotifier } from "../../../components/NotificationHandler";
  import logo from "../images/fixmeet-icon.png";


const MeetingInfo = ({ title, pcode, participants, voted, createdBy }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = useSelector((state) => state.user.userId);
  const [loadSpinner, setLoadSpinner] = useState(false);
  // console.log("code", pcode);

  const handleClick = async (pcode) => {
    setLoadSpinner(true);
     await getPollTimes(pcode).then((data) => {
       dispatch(savePollTimes(data));
     });
    await getPollSummary(pcode)
      .then((data) => {
        dispatch(savePollData(data));

        data?.poll_description.closed === 1 &&
        userId === data?.poll_description?.user_id
          ? navigate("/final-dates")
          : data?.poll_description?.closed === 0 
          ||
            data?.poll_description?.closed === 1
          ? navigate("/vote")
          : data?.poll_description?.closed === 2
          ? navigate("/vote")
          : errorNotifier("Invalid Poll");
      })
      .then(() => setLoadSpinner(false))
      .catch((err) => {
        setLoadSpinner(false);
       if(err?.response) {
          if (
            err?.response?.data?.message === "Not logged in, Log in to continue"
          ) {
            errorNotifier("You are logged in with another device");
            window.location.href = "/";
            localStorage.clear();
          } else {
            errorNotifier("Something went wrong");
          }
       }else{
         errorNotifier("Please check your network connection and try again")
       }
      });
   
  };
  // console.log("poll", pollSumary);
  return (
    <Flex py={4} px={[4, 4, 8, 8]} bg="#FBFBFB" direction={["column", "row"]}>
      <Circle
        p={2}
        bg="white"
        alignSelf="center"
        flexBasis="auto"
        display={["none", "block"]}
      >
        <Image boxSize="30px" alt="logo" src={logo} />
      </Circle>

      <Flex
        direction="column"
        // gridGap={2}
        flexBasis="calc(95%/2)"
        ml={[0, "5%"]}
        color="#757575"
      >
        {loadSpinner ? (
          <Spinner
            alignSelf={["center", "flex-end"]}
            color="primary"
            size="md"
          />
        ) : (
          <Flex justifyContent={["left", "left"]}>
            <Circle
              // p={2}
              bg="white"
              alignSelf="center"
              flexBasis="auto"
              display={["block", "none"]}
              mr="8px"
            >
              <Image boxSize="20px" alt="logo" src={logo} />
            </Circle>
            <Tooltip label="Click to view votes" hasArrow placement="top">
              <Text
                as="h5"
                fontWeight="bolder"
                cursor="pointer"
                onClick={() => handleClick(pcode)}
                textAlign={["left", "left"]}
              >
                {title}
              </Text>
            </Tooltip>
          </Flex>
        )}

        <Flex align="center" fontWeight="500" justifyContent={["left", "left"]}>
          <MdOutlinePeopleAlt />
          <Text ml="11px">{`${participants} participants`}</Text>
        </Flex>
      </Flex>

      <Flex
        gridGap={2}
        flexBasis="calc(95%/2)"
        justifyContent={["left", "left"]}
      >
        <IoMdPerson color="#C99EF3" size="1.3rem" />
        <Text>{`${voted} out of ${participants} voted`}</Text>
      </Flex>

      {userId === createdBy && (
        <Tooltip
          label="Share this poll to more participants"
          hasArrow
          placement="top"
          offset={0}
        >
          <Flex
            direction={["row", "column"]}
            justifyContent={["flex-end", "center"]}
            alignItems={["center"]}
            mt={["-40px", 0]}
            mb={["20px", 0]}
            gridGap={2}
            flexBasis="5%"
            display={["flex"]}
            cursor={"pointer"}
            onClick={() => {
              navigate("/share-link");
              sessionStorage.setItem("pcode", pcode);
            }}
          >
            <BsShare color="#A8A5A5" />
          </Flex>
        </Tooltip>
      )}
    </Flex>
  );
};

const MeetingsCardPanel = ({ meetings: meetingsProps }) => {
  // console.log(meetingsProps);
  const [paginatedContacts, setPaginatedContacts] = useState({
    meetings: meetingsProps,
    currentIndex: 1,
    meetingsPerPage: 3,
  });
  const { meetings, currentIndex, meetingsPerPage } = paginatedContacts;

  const indexOfLastContact = currentIndex * meetingsPerPage;
  const indexOfFirstContact = indexOfLastContact - meetingsPerPage;
  const currentMeetings = meetings?.slice(
    indexOfFirstContact,
    indexOfLastContact
  );
  const prev = currentIndex > 0 ? currentIndex - 1 : 0;
  const last = Math.ceil(meetings?.length / meetingsPerPage);
  
  const handlePreviousClick = () => {
    setPaginatedContacts({
      ...paginatedContacts,
      currentIndex: currentIndex - 1,
    });
  };

  const handleNextClick = () => {
    setPaginatedContacts({
      ...paginatedContacts,
      currentIndex: currentIndex + 1,
    });
  };
  
  return (
    <Flex direction="column" gridGap={2}>
      {currentMeetings.length > 0 && (
        <>
          {currentMeetings?.map((meeting, id) => {
            return (
              <MeetingInfo
                title={meeting?.poll_name}
                key={id}
                pcode={meeting?.pcode}
                participants={meeting?.participants}
                voted={meeting?.voted}
                createdBy={meeting?.user_id}
              />
            );
          })}
          <Flex
            justify="flex-end"
            color="primary"
            mt={3}
            mr={3}
            mb={4}
            sx={{
              "& > div": {
                align: "center",
                justify: "center",
                borderWidth: "1px",
                borderColor: "primary",
                cursor: "pointer",
              },
            }}
          >
            {!(prev === 0) && (
              <Flex borderLeftRadius="md" borderRightRadius="none">
                <MdKeyboardArrowLeft
                  onClick={handlePreviousClick}
                  size="1.8rem"
                />
              </Flex>
            )}
            {!(currentIndex === last) && (
              <Flex
                align="center"
                justify="center"
                borderWidth="1px"
                borderColor="primary"
                borderLeftRadius="none"
                borderRightRadius="md"
              >
                <MdKeyboardArrowRight onClick={handleNextClick} size="1.8rem" />
              </Flex>
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default MeetingsCardPanel;
