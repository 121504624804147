import React from "react";
import {
  Text,
  Flex,
  Box,
  Divider,
  Image
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

import flag from "../assets/flag.jpg"
import { BsFillHeartFill } from "react-icons/bs"
import logo from "../assets/footerLogo.png"

export const Footer = () => {
  return (
    <Box width="100%" pt={["40px", "70px"]} bg="#1d003a" color="#fff">
      <Flex
        px={["20px", "20px", "30px", "80px"]}
        direction={["column", "row", "row", "row"]}
        justifyContent={"space-between"}
        pb={["30px", "40px", "50px"]}
      >
        <Box as="flex" width={["100%", "40%", "40%", "33%"]}>
          <Link to="/">
            <Image
              src={logo}
              alt="footer logo"
              width={["100px", "120px", "150px"]}
              mb={["10px", "15px", "15px"]}
            />
          </Link>
          {/* <Text
            mb={["5px", "15px"]}
            fontSize={["18px", "20px", "24px"]}
            color="#c4b3d5"
            fontWeight={"bold"}
          >
            FIXMEET
          </Text> */}
          <Text fontSize={["14px", "14px", "16px"]}>
            Fixmeet is an online polling tool that helps one or more people
            decide the best time to meet based on the availability of all the
            people concerned. Fixmeet makes the complex process of fixing
            meeting times a simple hassle free process
          </Text>
        </Box>
        <Box>
          <Text
            fontSize={["18px", "20px", "22px"]}
            mb={["5px", "15px"]}
            mt={["10px", 0]}
            color="#c4b3d5"
          >
            Solutions
          </Text>
          <Text fontSize={["14px", "14px", "16px"]}>
            <a href="/#video-tutorial">Video Tutorial</a>
          </Text>

          <Text my={[0, "5px"]} fontSize={["14px", "14px", "16px"]}>
            <Link to="/terms-of-use">Terms of Use</Link>
          </Text>
          <Text my={[0, "5px"]} fontSize={["14px", "14px", "16px"]}>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </Text>
          <Text fontSize={["14px", "14px", "16px"]}>
            <a href="mailto:support@fitmeet.app">Support</a>
          </Text>
        </Box>
        <Box width={["100%", "33%"]}>
          <Text
            fontSize={["18px", "20px", "22px"]}
            mb={["5px", "15px"]}
            mt={["10px", 0]}
            color="#c4b3d5"
          >
            Company
          </Text>
          <Text mb={[0, "5px"]} fontSize={["14px", "14px", "16px"]}>
            <Link to="/">Home</Link>
          </Text>
          <Text mb={[0, "5px"]} fontSize={["14px", "14px", "16px"]}>
            <a href="/#about-fixmeet">About Fixmeet</a>
          </Text>
          <Flex fontSize={["14px", "14px", "16px"]}>
            <Link to="/contact-us">Contact Us</Link>
          </Flex>
        </Box>
      </Flex>
      <Divider />
      <Flex
        direction={["column", "row", "row"]}
        px={["20px", "20px", "20px", "50px"]}
        py="20px"
        justifyContent={["space-between"]}
        width={["100%", "100%", "100%"]}
        fontSize={["11px", "12px", "14px"]}
        fontWeight={"light"}
        letterSpacing={[1, 0, 1]}
      >
        <Flex
          justifyContent={["flex-start", "flex-start", "space-around"]}
          width={["100%", "70%", "70%", "33%"]}
        >
          <Text>
            {" "}
            <a href="https://zealightlabs.com" target="_blank" rel="noreferrer">
              <Flex>
                Built with{" "}
                <Box mt={["2px", "5px"]} mx={["8px", "2px", "8px"]}>
                  <BsFillHeartFill color="red" />
                </Box>{" "}
                by Zealight Innovation Labs
              </Flex>
            </a>
          </Text>
          <Divider mx={[0, "10px", 0]} orientation="vertical" />
        </Flex>
        <Flex
          justifyContent={["flex-start", "space-between", "space-around"]}
          width={["100%", "30%"]}
        >
          <Text>Copyright &copy; 2022</Text>
          <Divider mr={[0, "5px", 0]} orientation="vertical" />
        </Flex>
        <Flex width={["100%", "33%"]}>
          <Text mr={["7px"]}>Made in </Text>
          <Image
            src={flag}
            width={["20px", "24px"]}
            height={["12px", "14px"]}
            alt="Nigerian flag"
            mt="4px"
          />
        </Flex>
      </Flex>
    </Box>
  );
}