import {
  Flex,
  Text,
  Box,
  Button,
  Grid,
  GridItem,
  Circle,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { BsCalendarEvent } from "react-icons/bs";
import { BiMenuAltLeft } from "react-icons/bi";
import { MdPerson, MdPersonAdd } from "react-icons/md";

const PollDates = () => {
  return (
    <>
      <Flex
        direction="column"
        justify="center"
        textAlign="center"
        bg="white"
        py={2}
        minWidth="6rem"
        mb={1}
      >
        <Text fontSize="sm" color="#D2D2D2" fontWeight="bold">
          Mon., Nov.
        </Text>
        <Text fontSize="2rem" fontWeight="bold">
          17
        </Text>
        <Text fontSize=".6rem" color="#A8A5A5" letterSpacing="1.3px">
          9:00 AM
        </Text>
        <Text fontSize=".6rem" color="#A8A5A5" letterSpacing="1.3px">
          9:30 AM
        </Text>
      </Flex>
      <Flex justify="center" align="center" bg="white" py={1} mb={2}>
        <MdPerson size="1.2rem" />
        <Text ml={2}>2</Text>
      </Flex>
    </>
  );
};

const Vote = () => {
  return (
    <Flex direction="column" align="stretch" p={2}>
      <PollDates />

      <Flex
        h="2rem"
        bg="primary"
        borderRadius="md"
        color="white"
        align="center"
        justify="center"
        alignSelf="stretch"
        w="100%"
      >
        Yes
      </Flex>
    </Flex>
  );
};
const ParticipantCard = () => {
  return (
    <Flex gridGap={2} mb={2}>
      <Circle>
        <MdPerson size="1.2rem" />
      </Circle>
      <Flex direction="column">
        <Text>Hannah</Text>
        <Text fontSize="sm">hannahmontanah@gmail.com</Text>
      </Flex>
    </Flex>
  );
};

const VotingResults = () => {
  return (
    <>
      <Flex w="100%" color="#707070" direction="column" align="center" pb={4}>
        <Flex w="full" justify="space-between">
          <Flex
            direction="column"
            flexBasis="80%"
            p={4}
            gridGap={4}
            align="flex-start"
          >
            <Flex sx={{ "& > *": { lineHeight: "normal" } }}>
              <Box
                borderRadius="3px"
                boxSize="1rem"
                bg="#707070"
                mr={2}
                mt={2}
              ></Box>
              <Flex direction="column">
                <Text
                  color="primary"
                  fontSize={["1.5rem", "2rem", "2rem", "2rem"]}
                >
                  Need to meet urgently
                </Text>
                <Text fontStyle="italic" fontSize="1rem">
                  By Samuel
                </Text>
              </Flex>
            </Flex>
            <Flex sx={{ "& > *": { lineHeight: "normal" } }} width="25ch">
              <Box mr={2}>
                <BiMenuAltLeft />
              </Box>
              <Flex direction="column">
                <Text fontSize="1rem">
                  lorem ipsum heyenjsn huiy owjwnsn lets take all our meetings
                  serious because there will a gift
                </Text>
              </Flex>
            </Flex>
            <Flex sx={{ "& > *": { lineHeight: "normal" } }} width="25ch">
              <Box mr={2}>
                <BsCalendarEvent />
              </Box>
              <Flex direction="column">
                <Text fontSize="1rem">Nov. 13th 2021</Text>
                <Text fontStyle="italic" fontSize="1rem"></Text>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            direction="column"
            flexBasis="20%"
            alignSelf="center"
            gridGap="0.5rem"
          >
            <Flex alignItems="center" mb={2}>
              <MdPerson size="1.4rem" /> <Text>Yes 7</Text>
            </Flex>
            <Flex alignItems="center" mb={2}>
              <MdPerson size="1.4rem" /> <Text>Yes 7</Text>
            </Flex>
            <Flex alignItems="center" mb={2}>
              <MdPerson size="1.4rem" /> <Text>Yes 7</Text>
            </Flex>
          </Flex>
        </Flex>
        <Grid
          gridColumnGap={4}
          p={4}
          w="100%"
          templateRows="10rem auto"
          templateColumns="25% 75%"
        >
          <GridItem bg="white" gridColumn="1/2" gridRow="1/2"></GridItem>
          <GridItem bg="white" gridColumn="1/2" gridRow="2/3">
            <Text mb={2} textAlign="center" fontWeight="700" fontSize="md">
              Participants
            </Text>
            {[...Array(5)].map((item, i) => (
              <ParticipantCard key={i} />
            ))}
          </GridItem>
          <GridItem gridColumn="2/-1" gridRow="1/3">
            <Flex
              px={[2, 4, 6, 10]}
              py={2}
              mt={[6, null, null, null]}
              bg="#F8F8F8"
              overflowX="scroll"
              sx={{
                "&::-webkit-scrollbar": {
                  height: "15px",
                  "&-track": {
                    backgroundColor: "#F9F9F9",
                    borderRadius: " 100px",
                  },
                  "&-thumb": {
                    border: "5px solid transparent",
                    borderRadius: "100px",
                    backgroundColor: "#e4e4e4",
                    backgroundClip: "content-box",
                  },
                },
              }}
            >
              {[...Array(20)].map((item, i) => {
                return <Vote key={i} />;
              })}
            </Flex>
          </GridItem>
        </Grid>
        <Flex justify="flex-end" mt={4} gridGap="1rem">
          <Link to="/completed">
            <Button bg="primary" color="white">
              Choose Best Option
            </Button>
          </Link>
          <Link to="/select-meeting-date">
            <Button
              leftIcon={<MdPersonAdd size="1.3rem" />}
              bg="transparent"
              border="2px solid"
              borderColor="primary"
              _hover={{ bg: "transparent" }}
              color="primary"
            >
              Invite
            </Button>
          </Link>
        </Flex>
      </Flex>
    </>
  );
};

export default VotingResults;
