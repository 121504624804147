import { useState } from "react";

import {
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Flex,
  InputGroup,
  InputLeftElement,
  Input,
  Text,
  Box,
} from "@chakra-ui/react";
import MeetingsCardTab from "./MeetingsCardTab";
import MeetingsTabPanel from "./MeetingsTabPanel";
import { MdSearch } from "react-icons/md";
import { RiErrorWarningLine } from "react-icons/ri";

const MeetingsCard = ({ meetings }) => {

  const [filteredData, setFilteredData] = useState([]);
  //SEARCH FOR POLLS
  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    let result = [];

    result = meetings?.filter((data) => {
      return data?.poll_name.toLowerCase().includes(value);
    });
    return setFilteredData(result);
   
  };

  return (
    <Flex
      px={[1, 2, 2, 4]}
      boxShadow="0px 6px 12px #00000005"
      bg="#fff"
      w="98%"
      borderRadius="10px"
      minHeight="20rem"
    >
      <Tabs flexBasis="100%" defaultIndex={1}>
        <Flex
          direction={["column", "column", "row", "row"]}
          justify={[null, null, "space-between", "space-between"]}
          gridGap={[2, 4, 4, 6]}
          align="center"
          mb={["10px", "10px", 0]}
        >
          <TabList pl={4} flexBasis={["100%", "100%", "50%", "60%"]}>
            <MeetingsCardTab display="none" text="All" />
            <MeetingsCardTab text="Sent" />
            <MeetingsCardTab text="Received" />
            <MeetingsCardTab text="Completed" />
          </TabList>

          <InputGroup
            borderRadius="full"
            flexBasis={["70%", "50%", "50%", "40%"]}
            w={["70%", "50%", "50%", "40%"]}
          >
            <InputLeftElement
              color="primary"
              pointerEvents="none"
              pb="8px"
              children={<MdSearch color="inherit" />}
            />
            <Input
              borderRadius="full"
              h={["30px", "30px"]}
              type="text"
              placeholder="Search"
              _placeholder={{ textAlign: "center", color: "#D2D2D2", ml: 4 }}
              bg="#F7EFFF"
              onChange={handleSearch}
            />
          </InputGroup>
        </Flex>
        <TabPanels sx={{ "& > .chakra-tabs__tab-panel": { p: [1, 2, 4, 4] } }}>
          <TabPanel display={["none", "block"]}>
            <MeetingsTabPanel
              key={filteredData.length > 0 ? filteredData : meetings}
              meetings={filteredData.length > 0 ? filteredData : meetings}
            />
          </TabPanel>
          <TabPanel>
            <MeetingsTabPanel
              key={
                filteredData.length > 0
                  ? filteredData.filter((data) => data.status === "sent")
                  : meetings.filter((data) => data.status === "sent")
              }
              meetings={
                filteredData.length > 0
                  ? filteredData.filter((data) => data.status === "sent")
                  : meetings.filter((data) => data.status === "sent")
              }
            />
          </TabPanel>
          <TabPanel>
            <MeetingsTabPanel
              key={
                filteredData.length > 0
                  ? filteredData.filter((data) => data.status === "received")
                  : meetings.filter((data) => data.status === "received")
              }
              meetings={
                filteredData.length > 0
                  ? filteredData.filter((data) => data.status === "received")
                  : meetings.filter((data) => data.status === "received")
              }
            />
          </TabPanel>
          <TabPanel>
            <Flex
              fontSize={"14px"}
              bg="#f2f2f2"
              p={["5px"]}
              px={["5px", "20px"]}
              alignItems={"center"}
              mb="10px"
            >
              <Box w={["11%", "8%"]}>
                <RiErrorWarningLine size="26px" />
              </Box>
              <Text
                textAlign={["left", "left", "center"]}
                width={["88%", "80%", "60%"]}
              >
                Completed polls will be deleted automatically after 30 days
              </Text>
            </Flex>
            <MeetingsTabPanel
              key={
                filteredData.length > 0
                  ? filteredData.filter((data) => data.status === "closed")
                  : meetings.filter((data) => data.status === "closed")
              }
              meetings={
                filteredData.length > 0
                  ? filteredData.filter((data) => data.status === "closed")
                  : meetings.filter((data) => data.status === "closed")
              }
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default MeetingsCard;
