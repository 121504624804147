import { useState } from "react";
import { Flex, Circle, Text, Button, Spinner, Box } from "@chakra-ui/react";
import {
  MdPerson,
  MdPersonAddAlt,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
} from "react-icons/md";
import {
  successNotifier,
  errorNotifier,
} from "../../../components/NotificationHandler";
import AddContactForm from "../../home/components/AddContactForm";
import { sendInvites } from "../services/invite";
import image from "../images/group.png";
import CustomModal from "../../../components/CustomModal";

const GroupMember = ({
  id,
  email,
  selectedMembers,
  setSelectedMembers,
  name,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (e) => {
    setIsChecked(!isChecked);
    if (selectedMembers.includes(email)) {
      setSelectedMembers(
        selectedMembers.filter((selectedMember) => selectedMember !== email)
      );
      return;
    }
    setSelectedMembers([...selectedMembers, email]);
  };

  return (
    <Flex
      color="#A8A5A5"
      align="center"
      bg="#F7EFFF"
      px={4}
      py={2}
      justify="space-between"
    >
      <Flex align="center">
        <Circle p={2} bg="#F9F3FF" mr={4} alignSelf="center" color="primary">
          <MdPerson size="1.2rem" />
        </Circle>
        <Flex direction="column">
          <Text>{name}</Text>
          <Text>{email}</Text>
        </Flex>
      </Flex>
      <label htmlFor={id} onChange={handleChange}>
        <input
          onChange={handleChange}
          type="checkbox"
          id={id}
          checked={isChecked}
          style={{ width: "0", position: "absolute", opacity: "0" }}
        />
        {isChecked ? (
          <MdCheckBox color="#45DB3A" size="1.5rem" />
        ) : (
          <MdCheckBoxOutlineBlank size="1.5rem" />
        )}
      </label>
    </Flex>
  );
};

const InviteSingleContact = ({
  onClose,
  pcode,
  contactsLoading,
  contacts,
  setRefresh,
}) => {
  const [emails, setEmails] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleSubmit = async () => {
    setButtonLoading(true);
    const payload = { pcode, emails };
    sendInvites(payload)
      .then(() => successNotifier("Invite Sent successfully"))
      .catch((e) => {
        if (e?.response) {
        } else {
          errorNotifier("Please Check Your network and try again");
        }
        // console.log(e);
        setButtonLoading(false);
      })
      .finally(() => setButtonLoading(false));
  };

  return contactsLoading ? (
    <Flex justify="center" py={4}>
      <Spinner color="primary" />
    </Flex>
  ) : (
    <>
      <Flex
        direction="column"
        gridGap={2}
        py={2}
        maxH="300px"
        overflowY="scroll"
      >
        {contacts.length > 0 ? (
          contacts?.map((item, i) => (
            <GroupMember
              key={item?.id}
              id={item?.id}
              email={item?.email}
              name={item?.name}
              selectedMembers={emails}
              setSelectedMembers={setEmails}
            />
          ))
        ) : (
          <Box
            height="250px"
            backgroundImage={image}
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
          >
            <Text color="primary">You dont have any contact at the moment</Text>
          </Box>
        )}
      </Flex>
      <Flex justify="space-between">
        {contacts.length > 0 ? (
          <Button
            color="black"
            fontWeight="normal"
            w="100%"
            bg="#d8e8ff"
            _hover={{ bg: "#d8e8ff" }}
            leftIcon={<MdPersonAddAlt size="1.8rem" />}
            onClick={handleSubmit}
            disabled={emails.length === 0 ? true : null}
            isLoading={buttonLoading}
          >
            Invite Contact(s)
          </Button>
        ) : (
          <CustomModal
            buttonText="Add Contact"
            buttonProps={{
              _hover: { background: "none" },
              justifyContent: "flex-start",
              bg: "white",
              border: "1px solid white",
              color: "primary",
              borderColor: "primary",
            }}
            modalTitle="Add Contact"
          >
            <AddContactForm AddContactForm setRefresh={setRefresh} />
          </CustomModal>
        )}
      </Flex>
    </>
  );
};

export default InviteSingleContact;
