import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Text,
  FormLabel,
  Input,
  Flex,
  Textarea,
  Box,
} from "@chakra-ui/react";
import { BsPhone, BsTelephone } from "react-icons/bs";
import { FiMapPin } from "react-icons/fi";
import Banner from "../loginSignup/components/Banner";
import { contactUs } from "./service/contactUs";
import bannerImg from "../loginSignup/components/images/banner.png";
import bannerTwo from "../loginSignup/components/images/cover-two.jpg";
import skedu from "../loginSignup/components/images/skedu.png";
import Nav from "../../components/Nav";
import { useSelector } from "react-redux";
import { Footer } from "../landingPage/component/Footer";
import { errorNotifier } from "../../components/NotificationHandler";

const ContactUs = () => {
  const slideImages = [bannerImg, bannerTwo, skedu];
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    message: "",
  });
  const formRef = useRef();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const isLoggedIn = useSelector(state=>state.user.isLoggedIn)

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formValues);
    setLoading(true);
    contactUs(formValues, setLoading)
      .then((data) => {
        if (data) return navigate("/dashboard");
      })
      .catch((e) => {
        if (e?.response) {
          // errorNotifier(e.response?.data?.message);
          console.log("Contact Us Error", e.response);
        } else {
          console.log("Contact Us Error", e);
          errorNotifier("Please Check Your network and try again");
        }
        // console.log(e);
        setLoading(false);
      });
    // navigate("/dashboard");
  };

  return (
    <>
      {!isLoggedIn && <Nav />}
      <Flex
        h={["85vh", "100%", "85vh"]}
        bg="#fbfbfb"
        justifyContent={"center"}
        alignItems={"center"}
        direction={["column", "column", "row"]}
        mt={["120px", "0px", "0px"]}
      >
        <Flex
          order={[2, 2, 1]}
          direction="column"
          flexBasis="45%"
          sx={{
            "&": {
              " > form": {
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "stretch",
              },
            },
          }}
          px={4}
        >
          <Text
            as="h1"
            color="primary"
            fontWeight="bold"
            fontSize={["2rem", "2.2rem", "3rem"]}
            mt={[8, 0]}
            mb={[0, 4]}
          >
            Get In Touch{" "}
          </Text>
          <Flex direction="column" gridGap={2} mb={6} fontSize=".9rem">
            <Flex align="center" gridGap={2} mb={"20px"}>
              <FiMapPin size={26} />{" "}
              <Text fontSize={["16px", "18px", "20px"]}>
                25b, Marigold street, emerald estate, Ajah Lagos
              </Text>
            </Flex>
            <Flex align="center" gridGap={2} mb={"20px"}>
              <BsTelephone size={26} />{" "}
              <Text fontSize={["14px", "16px", "20px"]}>
                (+234) 703 262 3818
              </Text>
            </Flex>
            <Flex align="center" gridGap={2} mb={"20px"}>
              <BsPhone size={26} />{" "}
              <Text fontSize={["14px", "16px", "20px"]}>(+234) 1 888 0168</Text>
            </Flex>
          </Flex>
          <form ref={formRef}>
            <Flex gridGap={4} mb={4} w="100%">
              <Flex direction="column" w="50%">
                <FormLabel>Name </FormLabel>
                <Input
                  w="100%"
                  h={["40px", "40px", "45px"]}
                  onChange={handleChange}
                  name="name"
                  value={formValues.name}
                  required
                />
              </Flex>
              <Flex direction="column" w="50%">
                <FormLabel>Email</FormLabel>
                <Input
                  w="100%"
                  h={["40px", "40px", "45px"]}
                  onChange={handleChange}
                  name="email"
                  value={formValues.email}
                  required
                />
              </Flex>
            </Flex>
            <Textarea
              placeholder="Write your message here"
              onChange={handleChange}
              // mb={4}
              name="message"
              value={formValues.message}
              resize="none"
              mb="30px"
              required
            />
            <Button
              h={["40px", "40px", "45px"]}
              mb={["50px", 0]}
              type="submit"
              onClick={handleSubmit}
              isLoading={loading}
              _hover={{ bg: "primary" }}
              isDisabled={
                formValues.name === "" ||
                formValues.email === "" ||
                formValues.message === ""
              }
            >
              Send
            </Button>
          </form>
        </Flex>
        <Flex
          w={["100%", "100%", "50%"]}
          h={["200px", "100%"]}
          justifyContent={"center"}
          alignItems={["flex-start", "center"]}
          mt={[0, 0, "50px"]}
          p={["15px", "30px 20px"]}
          // h="70vh"
          order={[1, 1, 2]}
        >
          <Banner
            w="100%"
            boxH={["100%", "85vh"]}
            h={["180px", "92%"]}
            // h="80vh"
            data={slideImages}
          />
        </Flex>
      </Flex>
      <Box mt={["100px", "100px", "50px"]}>
        <Footer />
      </Box>
    </>
  );
};

export default ContactUs;
