import { AUTH_ROUTES } from "../../../services/routes.constants";
import http from "../../../services/api";

export const getPollSummary = async (pcode, setPollSummary, setLoading) => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_POLL_SUMMARY(pcode));
    // console.log("res", result.data.data);
    setPollSummary(result?.data?.data);
    setLoading(false);
    return result?.data?.data
  } catch (e) {
    setLoading(false);
  }

  // return result;
};

export const getPollTimes = async (pcode, setPollTimes, setLoading) => {
   try {
     const result = await http.get(AUTH_ROUTES.GET_POLL_TIMES(pcode));
    //  console.log("res", result.data);
     setPollTimes(result?.data);
     setLoading(false);
     return result?.data?.data;
   } catch (e) {
     setLoading(false);
   }
  // return result;
};
