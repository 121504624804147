import { ChakraProvider, Flex } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Home from "./pages/home";
import LoginSignup from "./pages/loginSignup";
import Nav from "./components/Nav";
import "./app.css";
import CreatePoll from "./pages/createPoll";
import SelectMeetingDate from "./pages/selectMeetingDate";
import PollSummary from "./pages/pollSummary";
import Completed from "./pages/completed";
import ShareLink from "./pages/shareLink";
import ForgetPassword from "./pages/forgetPassword";
import ContactUs from "./pages/contactUs";
import Profile from "./pages/profile";
import ProtectedRoutes from "./components/ProtectedRoutes";
import VotingScreen from "./pages/votingScreen";
import VotingResults from "./pages/votingResults";
import FinalDates from "./pages/finalDates";
import EmailLogin from "./pages/emailLogin/EmailLogin";
// import { FrontPage } from "./pages/landingPage/landing-page/index.js"
import theme from "./theme";
import { FrontPage } from "./pages/landingPage/landing-page";
import { PrivacyPage } from "./pages/landingPage/privacy/privacy";
import { Dpa } from "./pages/landingPage/dpa/Dpa";
import { Terms } from "./pages/landingPage/terms/termsAndCondition";
// import { useEffect } from "react";

function App() {
  // useEffect(()=> {

  // }, [])

  const user = useSelector((state) => state.user);

  return (
    <Router>
      <ChakraProvider theme={theme} resetCSS>
        <Flex direction="column">
          {(user.name || localStorage.getItem("11#221#")) && <Nav />}
          {/* <Box> */}
          <Routes>
            <Route path="/" element={<FrontPage />} />
            <Route path="/dpa" element={<Dpa />} />
            <Route path="/terms-of-use" element={<Terms />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/privacy-policy" element={<PrivacyPage />} />
            <Route path="/login" element={<LoginSignup />} />
            <Route path="/emailhandler" element={<EmailLogin />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route element={<ProtectedRoutes />}>
              <Route path="/dashboard" element={<Home />} />
              <Route path="/create-poll" element={<CreatePoll />} />
              <Route
                path="/select-meeting-date"
                element={<SelectMeetingDate />}
              />
              <Route path="/poll-summary" element={<PollSummary />} />
              <Route path="/completed" element={<Completed />} />
              <Route path="/share-link" element={<ShareLink />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/vote" element={<VotingScreen />} />
              <Route path="/voting-results" element={<VotingResults />} />
              <Route path="/final-dates" element={<FinalDates />} />
              <Route path="/*" element={<Navigate replace to="/" />} />
            </Route>
          </Routes>
          {/* </Box> */}
        </Flex>
      </ChakraProvider>
    </Router>
  );
}

export default App;
