import { Flex, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { closingDate } from "../../../components/DateFormat";

const PollDates = ({ data, date, start, end }) => {

     const setTimezone = useSelector((state) => state?.user?.timezone);
     const localTimeZone = useSelector((state) => state?.user?.localTimeZone);
     const timezone = setTimezone || localTimeZone;
  
  return (
    <Flex
      direction="column"
      justify="center"
      textAlign="center"
      alignItems={"center"}
      bg="white"
      p={2}
      _notLast={{ mr: 2 }}
      minWidth="6rem"
    >
      <Text fontSize="sm" color="#c4c4c4" fontWeight="bold">
        {closingDate(timezone,date, start).day}
      </Text>
      <Text fontSize="2rem" fontWeight="bold">
        {closingDate(timezone,date, start).date}
      </Text>
      <Text fontSize=".6rem" color="#A8A5A5" letterSpacing="1.3px">
        {closingDate(timezone,date, start).startEndTime}
      </Text>
      <Text
        fontSize=".6rem"
        color="#c4c4c4"
        letterSpacing="1.3px"
        fontWeight={"bold"}
      >
        {closingDate(timezone, date, end).startEndTime}
      </Text>
    </Flex>
  );
};

export default PollDates;
