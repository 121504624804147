import { useState, useRef } from "react";
// import { useNavigate } from "react-router-dom";
import { Button, Flex, FormLabel, Input } from "@chakra-ui/react";
import { editContact } from "../services/singleContact";

const EditContactForm = ({ contact_id, contact_name, email, setRefresh }) => {
  const [loading, setLoading] = useState(false)
  const [formValues, setFormValues] = useState({
    contact_name,
    email,
    contact_id,
  });
  const formRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
   setLoading(true)
    await editContact(formValues, setLoading).then(() =>setRefresh([]));
    // console.log(formValues);
    // navigate("/dashboard");
  };

  return (
    <Flex
      sx={{
        "& > form": {
          display: "flex",
          flexDirection: "column",
          width: "100%",
        },
      }}
    >
      <form ref={formRef}>
        <FormLabel>Name</FormLabel>
        <Input
          placeholder="Contact name"
          onChange={handleChange}
          mb={4}
          name="contact_name"
          value={formValues.contact_name}
          required
        />
        <FormLabel>Email address</FormLabel>
        <Input
          placeholder="Contact email"
          onChange={handleChange}
          mb={8}
          name="email"
          value={formValues.email}
          required
        />
        <Button
          alignSelf="stretch"
          type="submit"
          onClick={handleSubmit}
          isLoading={loading}
        >
          Update
        </Button>
      </form>
    </Flex>
  );
};

export default EditContactForm;
