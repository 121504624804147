import http from "../../../services/api";
import { AUTH_ROUTES } from "../../../services/routes.constants";

export const getMeetings = async (setLoading, setMeetings, setError) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_MEETINGS);
    // console.log("data", data);

    setMeetings(data);
    setLoading(false);
  } catch (e) {
    if (e?.response) {
    } else {
      setError(e);
      //  errorNotifier("Please Check Your network and try again");
    }
    // errorNotifier(e.response?.data.result);
    setLoading(false);
    // console.log(e.response.data);
    // setMeetings({ data: e.response.data, success: false });
    // e.response.data.message === "Not logged in, Log in to continue" ? window.location.href = "/" : window.location.href="";
  }
};
