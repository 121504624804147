import { Tab } from "@chakra-ui/react";

const MeetingsCardTab = ({ text, flexBasis, display }) => {
  return (
    <Tab
    
    display={[display, "block"]}
      flexBasis={flexBasis}
      outline="none !important"
      _focus={{ boxShadow: "none" }}
      px={4}
      py={3}
      color="#C99EF3"
      fontWeight="bold"
      _selected={{
        color: "primary",
        borderBottomColor: "primary",
        borderBottomWidth: ".3rem",
        borderBottomRadius: ".2rem",
      }}
    >
      {text}
    </Tab>
  );
};

export default MeetingsCardTab;
