import { AUTH_ROUTES } from "../../../services/routes.constants";
import http from "../../../services/api";
// import { errorNotifier } from "../../../components/NotificationHandler";

export const getPollSummary = async (pcode) => {
  // try {
  const result = await http.get(AUTH_ROUTES.GET_POLL_SUMMARY(pcode));

  return result?.data?.data;
};

export const getPollTimes = async (pcode) => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_POLL_TIMES(pcode));
    //  console.log("res", result.data);
    //  setPollTimes(result?.data);
    //  setLoading(false);
    return result?.data?.data;
  } catch (e) {
    // if (e.response.data.message) {
    //   localStorage.clear();
    //   window.location.href = "/";
    //   errorNotifier(e.response.data.message);
    // }
    //  setLoading(false);
  }
  // return result;
};
