import React from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  Divider,
} from "@chakra-ui/react";

export function ClosedPollNotification({ open, setOpen }) {
  const { onClose } = useDisclosure();

  const initialRef = React.useRef();
  const finalRef = React.useRef();

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={open}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="primary" fontSize={["16px", "20px"]}>Closed Poll</ModalHeader>
          <Divider mt="-5px" />
          <ModalCloseButton onClick={() => setOpen(false)} />
          <ModalBody pb={6}>
            <Text>
              Voting for this poll is closed. Close this modal to see more
              details from the poll
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setOpen(false)}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
