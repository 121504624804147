import React from "react";
import { Box, Text, Heading } from "@chakra-ui/react";
import Nav from "../../../components/Nav";
import { Footer } from "../component/Footer";
import { useSelector } from "react-redux";

export const PrivacyPage = () => {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  // const [showSignup, setShowSignup] = useState(false);

  return (
    <Box>
      {!isLoggedIn && <Nav />}
      <Box p={["20px 10%", "20px 10%", "50px 20%"]}>
        {/* <Nav setShowSignup={setShowSignup} /> */}
        <Heading pb={["20px"]} color="primary">
          Privacy Policy
        </Heading>
        <Text>
          Fixmeet (“Fixmeet”, “we”, or “us”) recognizes the importance of
          privacy. We want you to feel confident using our services, and this
          privacy notice (“Privacy Notice” or “Notice”) is to help you
          understand and describe how we collect, use, and disclose your
          information.
        </Text>
        <Text my="20px">
          Throughout this document, we will use a few terms to describe various
          products and roles. All of the text, data, information, software,
          graphics, photographs and more that we and our affiliates may make
          available to you, plus our websites and any services, plug-ins,
          software, or applications that we may provide all together constitute
          the Fixmeet “Website.” Fixmeet “Users” are the people who have created
          a Fixmeet account, and “Fixmeet Invitees” are people who have
          scheduled a meeting with a Fixmeet User but may or may not have
          registered with Fixmeet. A Fixmeet “Viewer” is a person who visits the
          Fixmeet Website but may or may not be a Fixmeet User or Fixmeet
          Invitee.
        </Text>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Information We Collect
        </Heading>
        <Text>
          We collect information about you directly from you and automatically
          through your use of our Website. We may combine information
          automatically collected with other information that we have collected
          about you including from publicly available sources. In order to help
          you protect yourself and your information, we encourage you to provide
          only that information that is necessary for using our Website. For
          example, to schedule a meeting you may only need to provide us with a
          name, email address, date, and time. Fixmeet is not directed to
          children under fifteen (15) years of age and we do not knowingly
          collect Personal Information from children under 15. If we discover
          that a child under 16 has provided us with Personal Information, we
          will promptly delete such Personal Information from our systems.
        </Text>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Information You Provide Voluntarily.
        </Heading>
        <Text my="20px">
          Fixmeet collects the following information, either directly from
          Fixmeet Users, Invitees, or Viewers, or through third-parties
          regarding Users, Invitees, or Viewers.
        </Text>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Appointment Information.
        </Heading>
        <Text>
          A Fixmeet User, Invitee, or a third-party may voluntarily give us
          certain information. This can include names, email addresses, and
          phone numbers; email addresses of other people; the subject of the
          meeting; and any other information provided to us or as required by
          the Fixmeet User upon scheduling, pursuant to the Fixmeet Terms of
          Use. If you receive an invitation from a Fixmeet User but do not wish
          to become a Fixmeet User, please contact the Fixmeet User who sent the
          invitation through other means to set up your meeting.
        </Text>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Calendar Information.
        </Heading>
        <Text>
          A Fixmeet User may connect their calendar with Fixmeet. Our calendar
          integration only checks the duration and free/busy status of the
          events in your calendar so that we don’t book you when you’re busy. We
          never store who you are meeting with, their email address, the meeting
          title, or any other details about the appointments in your connected
          calendar.
        </Text>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Billing Information.
        </Heading>
        <Text>
          If you purchase a premium version of Fixmeet, our third-party payment
          processors will collect and store your billing address and credit card
          information. We store the last four digits of your credit card number,
          card type, and the expiration date.
        </Text>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Information Collected Automatically.
        </Heading>
        <Text>
          Like many websites, we and our Service Providers use Cookies, web
          beacons, and other technologies to receive and store certain types of
          information when you interact with us through your computer or mobile
          device subject to your opt-out preferences (see Your Rights and
          Choices section below). Using these technologies helps us customize
          your experience with our Website, improve your experience, and tailor
          marketing messages. Here are some of the types of information we
          collect:
        </Text>
        <Heading
          my="10px"
          color={"primary"}
          as="h3"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Log & Device data.
        </Heading>
        <Text>
          When you use Fixmeet, our servers automatically record information
          (“log data”), including information that your browser sends whenever
          you visit our Website. This log data may include the web address you
          came from or are going to, your device model, operating system,
          browser type, unique device identifier, IP address, mobile network
          carrier, and time zone or location. Whether we collect some or all of
          this information often depends on what type of device you’re using and
          its settings. For example, different types of information are
          available depending on whether you’re using a Mac or PC, or an iPhone
          or an Android phone. To learn more about what information your device
          makes available to us, please check the policies of your device
          manufacturer or software provider.
        </Text>
        <Heading
          my="10px"
          color={"primary"}
          as="h3"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Cookie data.
        </Heading>
        <Text>
          Depending on how you’re accessing our services and subject to your
          opt-out preferences, we may use “Cookies” (a small text file sent by
          your computer each time you visit our Website, unique to your Fixmeet
          account or your browser) or similar technologies to record log data.
          When we use Cookies, we may use ‘session’ Cookies (that last until you
          close your browser) or ‘persistent’ Cookies (that last until you or
          your browser deletes them). For example, we may use Cookies to keep
          you logged into Fixmeet. Some of the Cookies we use are associated
          with your Fixmeet account (including personal information about you,
          such as the email address you gave us), and other Cookies are not.
          Fixmeet provides a centralized cookie management service across the
          entire Fixmeet application. You can find the link to manage your
          cookie preferences at the bottom of the Fixmeet homepage.{" "}
        </Text>
        <Heading
          my="10px"
          color={"primary"}
          as="h3"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Other Web Site Analytics Services.
        </Heading>
        <Text>
          Subject to your opt-out preferences (see ‘Your Rights and Choices’
          below), we use third-party Service Providers such as Google Analytics
          to provide certain analytics and Viewer interactions services to
          Fixmeet in connection with our operation of our Website, including the
          collection and tracking of certain data and information regarding the
          characteristics and activities of visitors to Fixmeet. You may opt-out
          of relevant cookies using opt-out features on their respective
          websites.
        </Text>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          How We Use Your Information.
        </Heading>
        <Text>
          We may use information that we collect about you, including Personal
          Data, to:
        </Text>
        <Heading
          my="10px"
          color={"primary"}
          as="h3"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Provide the Fixmeet Service.
        </Heading>
        <Text>
          We will use your information to provide our Website to you; including
          facilitating scheduling; managing your account; responding to your
          inquiries; and for other customer service and support purposes. We use
          the payment information you provide to us in order to alert you of
          past, current, and upcoming charges, to allow us to present the
          billing history to you on your billing page in the Website, and to
          perform internal financial processes, such as looking at the status of
          a credit card charge. In the event of a credit card dispute, we also
          share account information with your bank to verify the legitimacy of a
          charge.
        </Text>
        <Heading
          my="10px"
          color={"primary"}
          as="h3"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Understand and improve our products.
        </Heading>
        <Text>
          We will perform research and analysis about your use of, or interest
          in, our products, services, or content, or products, services or
          content offered by others. We do this to help make our products better
          and to develop new products.
        </Text>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          1.0 Communicate with you.
        </Heading>
        <Heading
          my="10px"
          color={"primary"}
          as="h3"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          1.1 Service related communications.
        </Heading>
        <Text>
          We may send you service and administrative emails to ensure the
          service is working properly. We will also email you regarding your
          calendar appointments. These messages are considered part of the
          service and you may not opt out of these messages.
        </Text>
        <Heading
          my="10px"
          color={"primary"}
          as="h3"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          1.2 Promotional.
        </Heading>
        <Text>
          Subject to your opt-out preference, we may send you emails about new
          product features or other news about Fixmeet or on topics we think
          would be relevant to you. You may opt out of receiving these
          communications at any time. Visit the ‘Your Rights and Choices’
          section below. For Fixmeet Invitees, please be assured that we do not
          use the email addresses that you enter to schedule a meeting with a
          Fixmeet User to send any type of direct marketing. We will also use
          your information to respond to your questions or comments.
        </Text>
        <Heading
          my="10px"
          color={"primary"}
          as="h3"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          1.4 Administrative.
        </Heading>
        <Text>
          We may contact you to inform you about changes in your services, our
          service offering and other important service-related notices, such as
          changes to the Notice or about security or fraud notices.
        </Text>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Protecting Rights and Interests.
        </Heading>
        <p>
          We will use your information to protect our rights and interests as
          well as the rights and interests of our Users and any other person, as
          well as to enforce this Notice or our Terms of Use.
        </p>
        <Heading
          my="10px"
          color={"primary"}
          as="h3"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Legal Compliance.
        </Heading>
        <p>
          We may use your information to comply with applicable legal or
          regulatory obligations, including complying with requests from law
          enforcement or other governmental authorities, or in legal proceedings
          involving Fixmeet.
        </p>
        <Heading
          my="10px"
          color={"primary"}
          as="h3"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Other
        </Heading>
        <p>
          We also may use your information to manage our business or perform
          functions as otherwise described to you at the time of collection
          subject to your consent.
        </p>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          With Whom We May Share Your Information.
        </Heading>
        <p>
          We do not sell your information to any third parties or disclose it in
          exchange for money or other valuable consideration. We do not share
          your Personal Data with others except as indicated within this Notice,
          or when we inform you and give you an opportunity to opt-out of having
          your Personal Data shared. We will never use Invitee data to send
          direct marketing via emails, SMS, physical mailings, or other similar
          communication channels to advertise or promote the use of our product
          and services or those of a third-party. We may share information we
          collect about you, including Personal Data, in the following ways:
          With third-party Service Providers, agents, contractors, or government
          entities. We use other companies, agents or contractors (“Service
          Providers”) to perform services on our behalf or to assist us with
          providing services to you: We may engage Service Providers to process
          credit card transactions or other payment methods. We may also engage
          Service Providers to provide services such as monitoring and
          developing Fixmeet services; aiding in communications, infrastructure,
          and IT services; customer service; debt collection; analyzing and
          enhancing data. These Service Providers may have access to your
          personal or other information in order to provide these functions. In
          addition, some of the above-listed types of information that we
          request may be collected by third-party Service Providers on our
          behalf. We may share information with Service Providers and government
          entities for legal, security, and safety purposes. This includes
          sharing information in order to enforce policies or contracts, address
          security breaches, and assist in the investigation of fraud, security
          issues, or other concerns. We require Service Providers to agree to
          take reasonable steps to keep the Personal Data that we provide to
          them secure. We do not authorize them to use or disclose your Personal
          Data except in connection with providing their services.
        </p>
        <Heading
          my="10px"
          color={"primary"}
          as="h3"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Affiliates.
        </Heading>
        <p>
          We may disclose information to current or future affiliates or
          subsidiaries for research, marketing, and other purposes consistent
          with this Privacy Notice. We may share your data if we believe that
          disclosure is reasonably necessary to comply with a law, regulation,
          legal, or governmental request; to respond to a subpoena, court order,
          warrant, or other legal process; to enforce applicable Terms of Use or
          this Notice, including investigation of potential violations thereof;
          to protect the safety, rights, or property of the public, any person,
          or Fixmeet; to detect, prevent, or otherwise address, security or
          technical issues, illegal, or suspected illegal activities (including
          fraud); or as evidence in litigation in which we are involved, as part
          of a judicial or regulatory proceeding. If you are using Fixmeet as a
          paid member of an organization or using your organization’s email
          domain (thereby representing yourself as a member of the
          organization), we may share your email address and plan information
          with an authorized agent of your company upon request in order for
          them to administer the account for the company.
        </p>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Your Rights and Choices.
        </Heading>
        <Heading
          my="10px"
          color={"primary"}
          as="h3"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Account.
        </Heading>
        <Text>
          In order to keep your Personal Data accurate and complete, you can log
          in to review and update your account information, including contact
          and billing information, via your account settings page. You may also
          contact us to request information about the personal data we have
          collected from you and to request the correction, modification or
          deletion of such Personal Data. We will do our best to honor your
          requests subject to any legal and contractual obligations.
        </Text>
        <Text mt="20px">
          If you would like to make a request, cancel your account or request we
          delete or no longer use your account information to provide you our
          Website, contact us at Support@fitmeet.app or the address set forth at
          the end of this Notice. Subject to applicable law, we will retain and
          use your account information only as necessary to comply with our
          legal obligations, resolve disputes, and enforce our agreements.
        </Text>
        <Heading
          my="10px"
          color={"primary"}
          as="h3"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          E-mail.
        </Heading>
        <p>
          As described above, if you do not wish to receive promotional emails
          from us, you may opt out at any time by following the opt-out link
          contained in the email itself. Please note that it may take up to ten
          (10) days to process your request. Please also note that if you opt
          out of receiving marketing communications from us, we may continue to
          send to you service-related emails which are not available for
          opt-out. If you do not wish to receive any service-related emails from
          us, you have the option to delete your account.
        </p>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Cookies.
        </Heading>
        <p>
          You may also refrain from providing or may withdraw your consent for
          Cookies via your browser settings. Your browser’s help function should
          contain instructions on how to set your computer to accept all
          Cookies, to notify you when a Cookie is issued, or to not receive
          Cookies at any time. Please keep in mind that Cookies are required to
          authenticate Fixmeet Users as well as perform some actions within
          Fixmeet (such as to pay for an event as an Invitee via Fixmeet), so in
          order to use the Website, some Cookies are often required. You may
          also manage the use of targeting, performance, and functional cookies
          on this website by clicking the Cookie Settings link located on the
          footer of this page.
        </p>
        <Heading
          my="10px"
          color={"primary"}
          as="h3"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Third-party analytics services.
        </Heading>
        <p>
          Some of the services used provide the ability to opt-out. You may opt
          out of Google Analytics’ services using the Opt-Out feature on their
          website. The Google Analytics’ service is provided by Google Inc. You
          can opt-out from Google Analytics service from using your information
          by installing the Google Analytics Opt-out Browser tool:{" "}
          <a className="anchor" href="https://tools.google.com/dlpage/gaoptout">
            https://tools.google.com/dlpage/gaoptout
          </a>
          For more information on the privacy practices of Google, please visit
          Google Privacy & Terms web page:{" "}
          <a className="anchor" href="https://policies.gooogle.com/privacy">
            https://policies.gooogle.com/privacy
          </a>
        </p>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Additional Rights.
        </Heading>
        <p>
          Subject to local law, you may have additional rights under the laws of
          your jurisdiction regarding your personal data, such as the right to
          complain to your local data protection authority.
        </p>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Security and Storage of Information.
        </Heading>
        <Text>
          Fixmeet takes the security of your personal data very seriously. We
          work hard to protect the personal data that you provide from loss,
          misuse, and unauthorized access, or disclosure. Given the nature of
          communications and information processing technology, there is no
          guarantee that personal data will be absolutely safe from access,
          alteration, or destruction by a breach of any of our physical,
          technical, and managerial safeguards.
        </Text>
        <Text mt="20px">
          You should take steps to protect against unauthorized access to your
          device and account by, among other things, choosing a unique and
          complex password that nobody else knows or can easily guess and
          keeping your log-in and password private. We are not responsible for
          any lost, stolen, or compromised passwords or for any activity on your
          account via unauthorized password activity. We retain the personal
          data we collect for so long as is reasonably necessary to fulfill the
          purposes for which the data was collected, to perform our contractual
          and legal obligations, and for any applicable statute of limitations
          periods for the purposes of bringing and defending claims.
        </Text>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Third-Party Links.
        </Heading>
        <p>
          Our Website may contain links to third-party websites and
          applications. Subject to your opt-out preferences (see Your Rights and
          Choices), we may also use third-party advertisers, ad networks, and
          other advertising, marketing, and promotional companies, to serve
          advertisements on our Website. Any access to and use of such linked
          websites and applications is not governed by this Notice but instead
          is governed by the privacy policies of those third parties. We do not
          endorse these parties, their content, or any products and services
          they offer, and we do not endorse these parties, their content, or any
          products and services they offer, and we are not responsible for the
          information practices of such third-party websites or applications.
        </p>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Do Not Track.
        </Heading>
        <p>
          We do not currently recognize or respond to browser-initiated Do Not
          Track signals as there is no consistent industry standard for
          compliance.
        </p>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Dispute Resolution.
        </Heading>
        <p>
          Details regarding Fixmeet’s dispute resolution process are available
          in the Fixmeet Terms of Use.
        </p>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Changes to this Notice.
        </Heading>
        <p>
          This Notice is current as of the Effective Date set forth above. This
          Notice may change if there is a material change to the way information
          is handled at Fixmeet, or to clarify our Notice or adjust clerical
          errors. If any changes are made, we’ll post them on this page, so
          please be sure to check back periodically. If you continue to use
          Fixmeet after those changes are in effect, you agree to the revised
          Notice.
        </p>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Contacting Us.
        </Heading>
        <p>
          If you have any questions or comments about this Notice, please
          contact us at{" "}
          <a className="anchor" href="mailto:support@fitmeet.app">
            support@fitmeet.app
          </a>{" "}
          For questions or comments outside of the Privacy Notice, please
          contact us at{" "}
          <a className="anchor" href="mailto:support@fitmeet.app">
            support@fitmeet.app
          </a>
          .
        </p>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Sharing Personal Information.
        </Heading>
        <p>
          We may disclose your Personal Information to a third-party for a
          business purpose. When we disclose Personal Information for a business
          purpose, we enter a contract that describes the purpose and requires
          the recipient to both keep that Personal Information confidential and
          not use it for any purpose except performing your contract.
        </p>
        <p>
          We disclose your Personal Information for a business purpose to the
          following categories of third parties:
          <Box p="20px 30px">
            <ul>
              <li>Service Providers.</li>
              <li>
                Third parties to whom you or your agents authorize us to
                disclose your Personal Information in connection with the
                services we provide to you.
              </li>
              <li>Government representatives as required by law.</li>
            </ul>
          </Box>
          In the preceding twelve (12) months, we have not sold any Personal
          Information.
        </p>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Access to Specific Information and Data Portability Rights.
        </Heading>
        <p>
          You have the right to request that we disclose certain information to
          you about our collection and use of your Personal Information over the
          past 12 months. Once we receive and confirm your verifiable consumer
          request, we will disclose to you
          <Box p="20px 30px">
            <ul>
              <li>
                categories of Personal Information we collected about you.
              </li>
              <li>
                The categories of sources for the Personal Information we
                collected about you.
              </li>
              <li>
                Our business or commercial purpose for collecting or selling
                that Personal Information.
              </li>
              <li>
                The categories of third parties with whom we share that Personal
                Information.
              </li>
              <li>
                The specific pieces of Personal Information that we have
                collected about you.
              </li>
            </ul>
          </Box>
        </p>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Deletion Request Rights.
        </Heading>
        <p>
          You have the right to request that we delete any of your Personal
          Information that we have collected from you and retained, subject to
          certain exceptions. All deletion requests will be managed in
          accordance with Fixmeet Deletion Procedures. Deletion requests should
          be sent to Support@fitmeet.app. Once we receive and confirm your
          verifiable request, we will delete (and direct our Service Providers
          to delete) your Personal Information from our records, unless an
          exception applies. We may deny your deletion request if retaining the
          information is necessary for us or our service providers to:
          <Box p="20px 30px">
            <ul>
              <li>
                Complete the transaction for which we collected the Personal
                Information, provide a service that you requested, take actions
                reasonably anticipated within the context of our ongoing
                business relationship with you, or otherwise perform our
                contract with you.
              </li>
              <li>
                Detect security incidents, protect against malicious, deceptive,
                fraudulent, or illegal activity, or prosecute those responsible
                for such activities.
              </li>
              <li>
                Debug software to identify and repair errors that impair
                existing intended functionality.
              </li>
              <li>
                Comply with the California Electronic Communications Privacy
                Act.
              </li>
              <li>
                Enable solely internal uses that are reasonably aligned with
                consumer expectations based on your relationship with us.
              </li>
              <li>Comply with a legal obligation.</li>
              <li>
                Otherwise lawfully use that information in compatibility with
                the context in which you provided it.
              </li>
            </ul>
          </Box>
        </p>
      </Box>
      <Footer />
    </Box>
  );
};
