import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  Flex,
  Text,
  Box,
  Button,
  Circle,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Divider,
  Tooltip,
} from "@chakra-ui/react";

import { BsCalendar2Date, BsCalendarEvent } from "react-icons/bs";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { BiMenuAltLeft } from "react-icons/bi";
import { MdPerson } from "react-icons/md";
import { AiFillStar } from "react-icons/ai";
import { GiTimeBomb } from "react-icons/gi";
import { IoTimeOutline } from "react-icons/io5";
import { GoLocation } from "react-icons/go";

import dayjs from "dayjs";

import { sendVote, voteAllNo, voteAllYes } from "./services/vote";
import Banner from "../loginSignup/components/Banner";
import FullPageLoader from "../../components/full-page-loader";
import CautionAlertDialog from "../../components/CautionAlertDialog";
import { ClosedPollNotification } from "./components/modal";
import { timeago } from "./utils/timeAgo";
import { closingDate } from "../../components/DateFormat";
import { savePollTimes } from "../../features/poll";
import { useDispatch } from "react-redux";
import { getPollTimes } from "../home/services/pollSummary";

import banner from "./assts/banner.png";
import skedu from "../loginSignup/components/images/skedu-small.png";


const PollDates = ({ setVotes, start, end, date, id }) => {
  const setTimezone = useSelector((state) => state?.user?.timezone);
  const localTimeZone = useSelector((state) => state?.user?.localTimeZone);
  const timezone = setTimezone || localTimeZone;

  return (
    <>
      <Flex
        direction="column"
        justify="center"
        textAlign="center"
        bg="white"
        py={2}
        minWidth="6rem"
        mb={1}
      >
        <Text fontSize="sm" color="#D2D2D2" fontWeight="bold">
          {closingDate(timezone, date, start).day}
        </Text>
        <Text fontSize={["2rem"]} fontWeight="bold">
          {closingDate(timezone, date, start).date}
        </Text>
        <Text fontSize=".6rem" color="#A8A5A5" letterSpacing="1.3px">
          {closingDate(timezone, date, start).startEndTime}
        </Text>
        <Text fontSize=".6rem" color="#A8A5A5" letterSpacing="1.3px">
          {closingDate(timezone, date, end).startEndTime}
        </Text>
      </Flex>
      <Flex justify="center" align="center" bg="white" py={1} mb={2}>
        <MdPerson size="1.2rem" />
        <Text ml={2}>{setVotes?.length}</Text>
      </Flex>
    </>
  );
};

const Vote = ({
  setVotes,
  setVote,
  vote,
  start,
  end,
  date,
  setVoteOptions,
  id,
  setTimeId,
}) => {
  const [voteOption, setVoteOption] = useState();
  const [tip] = useState(false);
  const pollClosed = useSelector(
    (state) => state?.polls?.poll?.poll_description?.closed
  );

  const nonSelectedProps = {
    color: "#707070",
    bg: "#E6E6E6",
    _hover: { background: "#E6E6E6" },
    _active: { background: "#E6E6E6" },
  };
  const yesProps = {
    color: "white",
    bg: "primary",
    _hover: { background: "primary" },
    _active: { background: "primary" },
  };
  const noProps = {
    color: "primary",
    bg: "#F39EBB",
    _hover: { background: "#F39EBB" },
    _active: { background: "#F39EBB" },
  };
  const notSureProps = {
    color: "primary",
    bg: "#DEBEFE",
    _hover: { background: "#DEBEFE" },
    _active: { background: "#DEBEFE" },
  };

  const generateProps = () => {
    switch (voteOption) {
      case "Yes":
        return yesProps;
      case "No":
        return noProps;
      case "Not Sure":
        return notSureProps;
      default:
        return nonSelectedProps;
    }
  };

  return (
    <Flex
      direction="column"
      align="stretch"
      p={2}
      onClick={() => {
        setTimeId(id);
      }}
    >
      <PollDates
        setVotes={setVotes}
        start={start}
        end={end}
        date={date}
        id={id}
      />
      {pollClosed === 0 && (
        <Menu strategy="fixed">
          <MenuButton
            as={Button}
            aria-label="Options"
            mb={2}
            {...generateProps()}
          >
            {voteOption || "Select"}
          </MenuButton>
          <MenuList p={0}>
            <Flex>
              <VotingMenuItem
                setVoteOption={setVoteOption}
                setVoteOptions={setVoteOptions}
                id={id}
                setVote={setVote}
                vote={vote}
                voteOption="Yes"
                bg="primary"
                color="white"
                text="Yes"
              />
              <VotingMenuItem
                setVoteOption={setVoteOption}
                setVoteOptions={setVoteOptions}
                id={id}
                setVote={setVote}
                vote={vote}
                voteOption="No"
                bg="#F39EBB"
                color="primary"
                text="No"
              />
              <VotingMenuItem
                setVoteOption={setVoteOption}
                setVoteOptions={setVoteOptions}
                id={id}
                setVote={setVote}
                vote={vote}
                voteOption="Not Sure"
                bg="#DEBEFE"
                color="primary"
                text="Not Sure"
              />
            </Flex>
          </MenuList>
        </Menu>
      )}
      {setVotes?.map((vote, i) => {
        const votes = vote?.date_voted.split(" ");
        return (
          <Tooltip
            key={i}
            label={
              <>
                <Text>{`${vote?.contact_name}`}</Text>
                <Text>{`${dayjs(votes[0]).format("DD-MM-YYYY")}`}</Text>
              </>
            }
            hasArrow
            placement="top"
            fontSize={"13px"}
            textTransform={"capitalize"}
            textAlign={"center"}
            closeOnClick={tip}
          >
            <Flex
              h="2rem"
              bg={
                vote?.status === "Yes"
                  ? "primary"
                  : vote?.status === "No"
                  ? "#F39EBB"
                  : vote?.status === "Not Sure"
                  ? "#DEBEFE"
                  : "primary"
              }
              borderRadius="md"
              color={vote?.status === "Yes" ? "white" : "primary"}
              align="center"
              justify="center"
              alignSelf="stretch"
              w="100%"
              mt="3px"
              cursor={"pointer"}
            >
              {vote?.status}
            </Flex>
          </Tooltip>
        );
      })}
    </Flex>
  );
};

const ParticipantCard = ({ name, email }) => {
  return (
    <Flex gridGap={2}>
      <Circle>
        <MdPerson size={["1.2rem"]} />
      </Circle>
      <Flex direction="column">
        <Text
          textTransform={"capitalize"}
          fontSize={["0.7rem", "0.8rem", "1rem"]}
        >
          {name}
        </Text>
        <Text fontSize="sm">{email}</Text>
      </Flex>
    </Flex>
  );
};

const VotingMenuItem = ({
  setVoteOption,
  setVoteOptions,
  vote,
  setVote,
  id,
  voteOption,
  text,
  bg,
  color,
}) => {
  const handleClick = () => {
    setVoteOptions(voteOption);
    setVoteOption(text);
    const newVotes = { status: voteOption, time_id: id };
    const unique = vote.map((v) => v.time_id);
    const uniqueArray = unique.includes(newVotes.time_id);
    if (uniqueArray === false) setVote([...vote, newVotes]);
  };

  return (
    <MenuItem
      w="auto"
      as={Box}
      p={1}
      _active={{ background: "none" }}
      _focus={{ bg: "none" }}
    >
      <Button
        onClick={handleClick}
        color={color}
        bg={bg}
        _hover={{ bg }}
        _focus={{ bg }}
      >
        {text}
      </Button>
    </MenuItem>
  );
};

const VotingScreen = () => {
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [yesBtnLoading, setYesBtnLoading] = useState(false);
  const [noBtnLoading, setNoBtnLoading] = useState(false);
  const [voteOptions, setVoteOptions] = useState("");
  const [timeId, setTimeId] = useState("");
  const navigate = useNavigate();
  const data = useSelector((state) => state?.polls?.poll);
  const times = useSelector((state) => state?.polls?.pollTimes);
  const [vote, setVote] = useState([]);
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();

  const pcode = data?.poll_description?.pcode;
  const userId = useSelector((state) => state.user.userId);
  const pollClosed = useSelector(
    (state) => state?.polls?.poll?.poll_description?.closed
  );

  useEffect(() => {
    setLoading(false);
  }, []);

  const {
    participants = [],
    poll_description = [],
    top_vote,
    selected_time,
  } = data;
  const topVotes = pollClosed === 0 ? top_vote : selected_time;

  const handleVote = () => {
    const payload = {
      pcode,
      votes: vote,
    };
    setBtnLoading(true);
    sendVote(payload)
      .then((data) =>
        data?.status === 200
          ? getPollTimes(pcode).then((response) => {
              dispatch(savePollTimes(response));
            })
          : ""
      )
      // .then((data) => (data?.status === 200 ? navigate("/dashboard") : ""))
      .then(() => setBtnLoading(false))
      .catch((err) => setBtnLoading(false));
  };

  const allYes = () => {
    setYesBtnLoading(true);
    const payload = {
      pcode,
    };
    voteAllYes(payload)
      .then((data) =>
        data?.status === 200
          ? getPollTimes(pcode).then((response) => {
              dispatch(savePollTimes(response));
            })
          : ""
      )
      // .then((data) => (data?.status === 200 ? navigate("/dashboard") : ""))
      .then(() => {
        setYesBtnLoading(false);
      })
      .catch((err) => setYesBtnLoading(false));
  };

  //Vote all no
  const allNo = () => {
    setNoBtnLoading(true);
    const payload = {
      pcode,
    };
    voteAllNo(payload)
      .then((data) =>
        data?.status === 200
          ? getPollTimes(pcode).then((response) => {
              dispatch(savePollTimes(response));
            })
          : ""
      )
      // .then((data) => (data?.status === 200 ? navigate("/dashboard") : ""))
      .then(() => setNoBtnLoading(false))

      .catch((err) => {
        setNoBtnLoading(false);
        // console.log("err", err);
      });
  };
  const setTimezone = useSelector((state) => state?.user?.timezone);
  const localTimeZone = useSelector((state) => state?.user?.localTimeZone);
  const timezone = setTimezone || localTimeZone;

  const splitDate = poll_description?.end_date.split(" ");
  const endDate = splitDate[0];

  const nextSlide = (e) => {
    // alert("cliked")
    document.getElementById("container").scrollLeft += 200;
   
  };

  const prevSlide = () => {
    document.getElementById("container").scrollLeft -= 200;
  };

  return loading ? (
    <FullPageLoader />
  ) : (
    <>
      <Flex
        w="100%"
        color="#707070"
        direction="column"
        align="center"
        pb={4}
        mb="20px"
      >
        {(pollClosed === 1 || pollClosed === 2) && (
          <ClosedPollNotification open={open} setOpen={setOpen} />
        )}

        <Flex
          w="full"
          justify="space-between"
          direction={["column", "column", "row"]}
          px={[0, "20px", "30px", "80px"]}
          py={[0, "20px", "60px"]}
        >
          <Flex
            direction="column"
            flexBasis={["40%", "40%", "45%", "40%"]}
            p={4}
            gridGap={2}
            align="flex-start"
            order={[2, 2, 1]}
          >
            <Flex
              sx={{ "& > *": { lineHeight: "normal" } }}
              alignItems="center"
              width="100%"
            >
              <Flex direction="column">
                <Text
                color={"primary"}
                  fontWeight={"semibold"}
                  fontSize={["1.5rem", "1.5rem", "1.5rem", "2.2rem"]}
                >
                  {poll_description?.poll_name}
                </Text>
                <Flex>
                  <Text
                    fontStyle="italic"
                    fontSize="0.9rem"
                    textTransform={"capitalize"}
                  >
                    By {poll_description?.user?.name || "--"}
                  </Text>
                  <Text fontSize="0.9rem" ml="6px">
                    {timeago(poll_description.created_at)}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex sx={{ "& > *": { lineHeight: "normal" } }} width="100%">
              <Box mr={2}>
                <BiMenuAltLeft size={26} />
              </Box>
              <Flex direction="column">
                <Text fontSize="0.9rem">
                  {poll_description?.poll_description}
                </Text>
              </Flex>
            </Flex>
            <Flex sx={{ "& > *": { lineHeight: "normal" } }} width="100%">
              <Box mr={4}>
                <BsCalendarEvent />
              </Box>
              <Flex direction={["row"]}>
                <Text fontSize={["0.9rem", "1rem"]}>
                  {closingDate(timezone, endDate, "11:59:00 PM").month}{" "}
                  {closingDate(timezone, endDate, "11:59:00 PM").date}{" "}
                  {closingDate(timezone, endDate, "11:59:00 PM").year}
                </Text>
                <Flex ml="20px">
                  <IoTimeOutline />
                  <Text fontStyle="italic" fontSize="1rem" ml="5px">
                    {poll_description?.poll_duration % 60 !== 0
                      ? (poll_description?.poll_duration % 60) + " minutes"
                      : poll_description?.poll_duration / 60 +
                        `${
                          poll_description?.poll_duration / 60 > 1
                            ? " hours"
                            : " hour"
                        }`}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex>
              <GoLocation />
              <Text fontSize={["0.8rem", "0.9rem"]} ml="10px">
                {/* {poll_description?.poll_timezone} */}
                {timezone}
              </Text>
            </Flex>
            <Divider />
            <Flex direction="column" flexBasis="20%" gridGap="0.5rem">
              <Text color={"primary"} fontWeight={"semibold"}>
                {pollClosed === 0 ? "Top Vote" : "Selected Date"}
              </Text>
              <Flex
                direction="column"
                mt={["0px", "0px"]}
                fontSize={["14px", "16px"]}
              >
                <Flex alignItems={"center"} w="90px">
                  <AiFillStar size="18px" color={"gold"} />
                  <Text ml="8px">
                    <Box as="span" fontWeight={"bold"}>
                      Yes:
                    </Box>{" "}
                    {topVotes && topVotes[0]?.yes_count}
                  </Text>
                </Flex>
                <Flex alignItems={"center"}>
                  <BsCalendar2Date />
                  <Text ml="10px">
                    <Box as="span" fontWeight={"bold"}>
                      Date:
                    </Box>{" "}
                    {topVotes?.length > 0
                      ? closingDate(
                          timezone,
                          topVotes[0]?.date,
                          topVotes[0]?.start
                        ).month
                      : ""}{" "}
                    {topVotes?.length > 0
                      ? closingDate(
                          timezone,
                          topVotes[0]?.date,
                          topVotes[0]?.start
                        ).date
                      : ""}{" "}
                    {topVotes?.length > 0
                      ? closingDate(
                          timezone,
                          topVotes[0]?.date,
                          topVotes[0]?.start
                        ).year
                      : ""}
                  </Text>
                </Flex>
                <Flex alignItems={"center"}>
                  <GiTimeBomb />
                  <Text ml="10px">
                    <Box as="span" fontWeight={"bold"}>
                      Time:
                    </Box>{" "}
                    {topVotes?.length > 0
                      ? closingDate(
                          timezone,
                          topVotes[0]?.date,
                          topVotes[0]?.start
                        ).startEndTime
                      : ""}{" "}
                    -{" "}
                    {topVotes?.length > 0
                      ? closingDate(
                          timezone,
                          topVotes[0]?.date,
                          topVotes[0]?.end
                        ).startEndTime
                      : ""}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Box flexBasis={["100%", "50%"]} order={[1, 1, 2]} p="15px">
            <Banner boxH={["100%"]} h={["200px", "200px"]} w="100%" data={[banner, skedu]} />
          </Box>
        </Flex>
        <Divider />
        <Flex
          className="formerGrid"
          gridColumnGap={4}
          p={4}
          w="100%"
          // overflowY={"scroll"}
          maxHeight={["100%", "100%", "100%"]}
        >
          <Flex
            p="20px"
            bg="#f2f2f2"
            direction="column"
            gridGap="0.2rem"
            display={["none", "none", "flex", "flex"]}
            height={"100%"}
          >
            <Text mb={2} textAlign="left" fontWeight="700" fontSize="md">
              Participants
            </Text>
            {participants?.length > 0 ? (
              participants?.map((item, i) => (
                <ParticipantCard
                  name={item?.name}
                  email={item?.email}
                  key={i}
                />
              ))
            ) : (
              <Text>No Participant Available Yet</Text>
            )}
          </Flex>
          <Flex
            height={"100%"}
            width={"5%"}
            direction={"column"}
            alignSelf={"flex-start"}
            mt="8%"
            display={["none", "block"]}
          >
            <FaChevronLeft cursor={"pointer"} onClick={prevSlide} size="24px" />
          </Flex>
          <Flex
            flexGrow={1}
            px={[2, 4, 6, 2]}
            py={2}
            bg="#F8F8F8"
            overflowX="scroll"
            id="container"
            style={{ scrollBehavior: "smooth" }}
            scrollBehavior="smooth"
            sx={{
              "&::-webkit-scrollbar": {
                height: "15px",
                "&-track": {
                  backgroundColor: "#F9F9F9",
                  borderRadius: " 100px",
                },
                "&-thumb": {
                  border: "5px solid transparent",
                  borderRadius: "100px",
                  backgroundColor: "#e4e4e4",
                  backgroundClip: "content-box",
                },
              },
            }}
          >
            {times?.length > 0 ? (
              times?.map((item, i) => {
                return (
                  <Vote
                    key={i}
                    setVotes={item?.votes}
                    date={item?.date}
                    start={item?.start}
                    end={item?.end}
                    voteOptions={voteOptions}
                    setVoteOptions={setVoteOptions}
                    id={item?.id}
                    timeId={timeId}
                    setTimeId={setTimeId}
                    setVote={setVote}
                    vote={vote}
                  />
                );
              })
            ) : (
              <Text>No Time available for this meeting at the moment</Text>
            )}
          </Flex>
          <Flex
            // height={"100%"}
            width={"5%"}
            direction={"column"}
            alignSelf={"flex-start"}
            mt="8%"
            display={["none", "block"]}
          >
            <FaChevronRight
              cursor={"pointer"}
              size="24px"
              onClick={nextSlide}
            />
          </Flex>
        </Flex>
        {times?.length > 0 ? (
          <Flex width={"60%"} justifyContent={"center"} alignItems={"center"}>
            {pollClosed === 0 && (
              <Flex
                width="100%"
                justifyContent={"space-between"}
                direction={["column", "row"]}
              >
                <Flex
                  justifyContent={"center"}
                  width={["100%", "100%", "100%", "100%", "60%"]}
                  direction={["column", "column", "row"]}
                  alignItems={["center"]}
                >
                  <Button
                    bg="primary"
                    color="white"
                    w="170px"
                    mb={["8px", "8px", 0]}
                    mr={[0, 2]}
                    _hover={{ bg: "primary" }}
                    // px="55px"
                    onClick={handleVote}
                    isLoading={btnLoading}
                    isDisabled={vote?.length < 1}
                  >
                    Vote
                  </Button>
                  <Button
                    mr={[0, 2]}
                    mb={["8px", "8px", 0]}
                    bg="transparent"
                    border="2px solid"
                    borderColor="primary"
                    _hover={{ bg: "transparent" }}
                    color="primary"
                    isLoading={noBtnLoading}
                    onClick={() => allNo()}
                  >
                    None works for me
                  </Button>
                  <Button
                    w="170px"
                    mr={[0, 2]}
                    mb={["8px", 0]}
                    bg="#04aa6d"
                    border="none"
                    borderRadius={"5px"}
                    isLoading={yesBtnLoading}
                    // borderColor="primary"
                    _hover={{ bg: "teal" }}
                    color="#fff"
                    onClick={() => allYes()}
                  >
                    All works for me
                  </Button>
                </Flex>
                {userId === data?.poll_description?.user_id &&
                  pollClosed === 0 && (
                    <Flex justifyContent={"center"} mt={["30px", 0]}>
                      <CautionAlertDialog
                        buttonText="Close Poll"
                        buttonProps={{
                          bg: "red.500",
                          _hover: { background: "red" },
                          flexBasis: "170px",
                          justifyContent: "center",
                          color: "#fff",
                        }}
                        cautionTitle="Are you sure you want to close this poll?"
                        onContinue={() => navigate("/final-dates")}
                      />
                    </Flex>
                  )}
              </Flex>
            )}
          </Flex>
        ) : (
          ""
        )}
      </Flex>
    </>
  );
};

export default VotingScreen;
