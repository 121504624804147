import { useState, useEffect, useRef } from "react";
import {
  Flex,
  Tabs,
  TabList,
  InputGroup,
  TabPanels,
  TabPanel,
  Button,
  Input,
  InputLeftElement,
  Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  Box,
} from "@chakra-ui/react";
import { AiOutlinePlus } from "react-icons/ai";
import { MdSearch } from "react-icons/md";
import ContactGroup from "./ContactGroup";
import Contact from "./Contact";
import ContactsAndMeetingsTab from "./ContactsAndMeetingsTab";
import CreateGroupForm from "./CreateGroupForm";
import AddContactForm from "./AddContactForm";
import CustomModal from "../../../components/CustomModal";
import { searchContact, uploadContacts } from "../services/singleContact";
import { searchGroup } from "../services/groupContact";
import PaginatedContacts from "./PaginatedContacts";
import {
  successNotifier,
  errorNotifier,
  fileUploadNotifier,
} from "../../../components/NotificationHandler";
import BulkUpload from "./BulkUpload";


const ContactsAndMeetings = ({ contacts, groups, setRefresh }) => {
  const [inputFile, setInputFile] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [groupInputValue, setGroupInputValue] = useState("");
  const [searchContacts, setSearchContacts] = useState([]);
  const [searchGroups, setSearchGroups] = useState([]);
  const [clicked, setClicked] = useState("one");
  const [, setShowSearch] = useState(false);
  const toastRef = useRef();

  async function uploadHandler(file) {
    let formData = new FormData();
    if (file !== "") {
      formData.append("contacts", file);
      try {
        await uploadContacts(formData);
        successNotifier("Contacts added to your List");
      } catch (e) {
        errorNotifier("Failed to add contacts to your list");
      }
    }
  }

  useEffect(() => {
    if (inputFile) {
      fileUploadNotifier(toastRef);
    }
    uploadHandler(inputFile)
      .then(() => {
        setRefresh([]);
      })
      .finally(() => toastRef.current?.onClose());
  }, [inputFile, setRefresh]);

  const handleSearch = async (e, apiCall, setData, setValue) => {
    setValue(e.target.value);
    await apiCall(e.target.value, setData);
  };

  return (
    <>
      <Tabs flexBasis="100%" borderRadius="0px">
        <TabPanels sx={{ "& .chakra-tabs__tab-panel": { p: [1, 2, 4, 2] } }}>
          <TabPanel>
            <Tabs>
              <TabList>
                <ContactsAndMeetingsTab
                  clickText={() => setClicked("one")}
                  clicked={clicked}
                >
                  Contacts
                </ContactsAndMeetingsTab>
                <ContactsAndMeetingsTab clickText={() => setClicked("two")}>
                  All Groups
                </ContactsAndMeetingsTab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Flex wrap="no-wrap" alignItems="center">
                    <InputGroup
                      borderRadius="full"
                      flexBasis="70%"
                      onClick={() => setShowSearch(true)}
                    >
                      <InputLeftElement
                        color="primary"
                        pointerEvents="none"
                        children={<MdSearch color="inherit" pointer="cursor" />}
                        pb="8px"
                      />
                      <Input
                        borderRadius="full"
                        type="text"
                        h={["30px", "30px"]}
                        placeholder="Search"
                        _placeholder={{
                          textAlign: "center",
                          color: "#D2D2D2",
                          ml: 4,
                        }}
                        bg="#F7EFFF"
                        value={inputValue}
                        onChange={(e) => {
                          handleSearch(
                            e,
                            searchContact,
                            setSearchContacts,
                            setInputValue
                          );
                        }}
                      />
                    </InputGroup>
                    <Menu lazyBehavior="keepMounted">
                      <MenuButton
                        as={Button}
                        aria-label="Options"
                        color="primary"
                        leftIcon={<AiOutlinePlus />}
                        bg="transparent"
                        _hover={{ background: "transparent" }}
                        _active={{ background: "transparent" }}
                      >
                        Add Contact
                      </MenuButton>
                      <MenuList>
                        <Text
                          color="primary"
                          size="sm"
                          p={2}
                          w="25ch"
                          align="center"
                        >
                          You can add your contact in bulk using an excel file
                          or add a single contact{" "}
                        </Text>
                        <Flex justifyContent={"center"}>
                          <MenuItem
                            w="auto"
                            as={Box}
                            _active={{ background: "none" }}
                            _focus={{ bg: "none" }}
                          >
                            <CustomModal
                              buttonText="Single"
                              buttonProps={{
                                _hover: { background: "none" },
                                justifyContent: "flex-start",
                                bg: "white",
                                border: "1px solid white",
                                color: "primary",
                                borderColor: "primary",
                              }}
                              modalTitle="Add Contact"
                            >
                              <AddContactForm setRefresh={setRefresh} />
                            </CustomModal>
                          </MenuItem>
                          <Box mt="7px">
                            <BulkUpload setInputFile={setInputFile} />
                          </Box>
                        </Flex>
                      </MenuList>
                    </Menu>
                  </Flex>
                  <Divider my={2} />
                  <Flex direction="column" gridGap={1}>
                    {searchContacts?.show ? (
                      searchContacts?.response.map((contact) => {
                        return (
                          <Box key={contact?.id}>
                            <Contact
                              contact={contact}
                              key={contact?.id}
                              id={contact?.id}
                              setRefresh={setRefresh}
                            />
                            <Divider />
                          </Box>
                        );
                      })
                    ) : (
                      <PaginatedContacts
                        key={contacts}
                        contactsPerPage={10}
                        setRefresh={setRefresh}
                        contacts={contacts}
                      />
                    )}
                  </Flex>
                </TabPanel>
                <TabPanel>
                  <Flex wrap="no-wrap" alignItems="center">
                    <InputGroup
                      borderRadius="full"
                      flexBasis="70%"
                      onClick={() => setShowSearch(true)}
                    >
                      <InputLeftElement
                        color="primary"
                        pointerEvents="none"
                        children={<MdSearch color="inherit" />}
                        pb="8px"
                      />
                      <Input
                        borderRadius="full"
                        type="text"
                        h={["30px", "30px"]}
                        placeholder="Search"
                        _placeholder={{
                          textAlign: "center",
                          color: "#D2D2D2",
                          ml: 4,
                        }}
                        bg="#F7EFFF"
                        value={groupInputValue}
                        onChange={(e) => {
                          handleSearch(
                            e,
                            searchGroup,
                            setSearchGroups,
                            setGroupInputValue
                          );
                        }}
                      />
                    </InputGroup>
                    <CustomModal
                      buttonText="Add Group"
                      buttonProps={{
                        bg: "transparent",
                        color: "primary",
                        leftIcon: <AiOutlinePlus />,
                        _hover: { background: "transparent" },
                        _active: { background: "transparent" },
                      }}
                      modalTitle="Add Group"
                    >
                      <CreateGroupForm setRefresh={setRefresh} />
                    </CustomModal>
                  </Flex>
                  <Divider my={2} />
                  <Flex direction="column" gridGap={1}>
                    {searchGroups.show ? (
                      searchGroups.response.map((group) => {
                        return (
                          <Box key={group?.id}>
                            <ContactGroup
                              group={group}
                              key={group.id}
                              setRefresh={setRefresh}
                              contacts={contacts}
                            />
                            <Divider />
                          </Box>
                        );
                      })
                    ) : (
                      <Box>
                        <PaginatedContacts
                          key={groups}
                          contactsPerPage={10}
                          setRefresh={setRefresh}
                          contacts={groups}
                          group
                        />
                      </Box>
                    )}
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default ContactsAndMeetings;
