import React from "react"
import { Box, Flex, Stack, Button, Image } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import logo from "../assets/fixmeet-logo.png";

export const Nav = ({ setShowSignup }) => {
  // const navigate = useNavigate();

  return (
    <Box>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        py="20px"
        position="relative"
        zIndex="10"
      >
        <Stack direction="row" alignItems="center" spacing="20px">
          <Link to="/">
            <Image src={logo} alt="fixmeet-logo" width={["100px", "150px"]} />
          </Link>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Link to="/login">
            <Button
              bg="#6C4097"
              _hover={{ bg: "#6C4097" }}
              color="#fff"
              border="1px solid #6C4097"
              fontSize={[".8em", "1em"]}
              p={["10px 30px", "20px 40px"]}
            >
              {" "}
              Create
            </Button>
          </Link>

          {/* <Button
            bg="#6C4097"
            _hover={{ bg: "#6C4097" }}
            color="#fff"
            fontSize={[".8em", "1em"]}
            p={["10px", "20px"]}
            onClick={() => navigate("/login")}
          >
            Get Started
          </Button> */}
        </Stack>
      </Flex>
    </Box>
  );
}
