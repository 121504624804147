import { useState } from "react";
import { Button } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { googleLogin } from "../services/login";
import { googleLogin as googleLoginAction } from "../../../features/user";
import { GoogleLogin } from "react-google-login";
import { errorNotifier } from "../../../components/NotificationHandler";

const GoogleLoginButton = ({ pcode }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const clientId =
    "971023816918-kricmfsa4mlcit454fvner70bsv3ppeu.apps.googleusercontent.com";
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const onSuccess = (res) => {
    const { email, name } = res.profileObj;
    if (name && email) {
      setLoading(true);
      googleLogin(name, email, pcode)
        .then((response) => {
          const {
            data: { data },
          } = response;
          // console.log("ggg", data)
          dispatch(
            googleLoginAction({
              name: data?.user?.name,
              email: data?.user?.email,
              isPremium: data?.user.is_premium,
              userId: data?.user?.id,
              token: data?.token,
              timezone: data?.user?.user_timezone,
              localTimeZone: timezone,
            })
          );
        })
        .catch((e) => errorNotifier())
        .finally(() => setLoading(false));
    }
  };

  const onFailure = () => {
    // errorNotifier();
  };

  return (
    <Button
      as="div"
      bg="#F8F6F6"
      isLoading={loading}
      h={["40px", "40px", "50px"]}
      width={["96%", "100%", "100%", "72%"]}
      _hover={{ bg: "#F8F6F6" }}
      color="#3D3D3D"
      sx={{
        "&": {
          "& > button": {
            width: "100%",
            justifyContent: "center",
            background: "#F8F6F6 !important",
            boxShadow: "none !important",
            "& div": {
              background: "#F8F6F6 !important",
            },
          },
        },
      }}
    >
      <GoogleLogin
        clientId={clientId}
        buttonText="Sign In with Google"
        onSuccess={onSuccess}
        onFailure={onFailure}
        redirectUri="https://fixmeet.app"
        cookiePolicy="single_host_origin"
      />
    </Button>
  );
};

export default GoogleLoginButton;
