import { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Text, Flex, FormLabel, Input, Button } from "@chakra-ui/react";
import { MdArrowBack } from "react-icons/md";
import { forgotPassword } from "./service";

const ForgetPassword = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("");
  const formRef = useRef();
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    const payload = { email }
    if (!formRef.current.reportValidity()) return;
    forgotPassword(payload, setLoading).then((data)=> {
      if(data.status === 200) navigate("/login");
      setLoading(false)
    }).catch(e=>setLoading(false))
  };
  return (
    <Flex
      justifyContent={"center"}
      alignItems={"center"}
      borderRadius="lg"
      bg="#fbfbfb"
      direction="column"
      p={4}
      h="85vh"
      w={["97%", "70%", "50%", "100%"]}
      sx={{ "& label": { color: "#D2D2D2" } }}
    >
      <Flex
        color="#707070"
        bg="#fff"
        p={["10px", "50px"]}
        direction={["column", "row"]}
      >
        <Link to="/login">
          <MdArrowBack
            style={{
              marginRight: "1rem",
              marginTop: ".5rem",
              fontSize: "2rem",
            }}
          />
        </Link>
        <Flex direction="column">
          <Text
            as="h2"
            fontSize={["1.2rem", "1.5rem", "2rem"]}
            fontWeight="semibold"
          >
            Forget Pin
          </Text>
          <Text as="h3" fontSize={["1rem", "1.4rem"]} mb={8}>
            Enter your email address and we'll send you a new pin
          </Text>
          <form ref={formRef}>
            <FormLabel color="#393838!important" fontWeight="600">
              Email Address{" "}
            </FormLabel>
            <Input
              name="email"
              bg="#fbfbfb"
              h={["35px", "50px"]}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              mb={8}
              required
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            />
            <Flex justify="center" gridGap={4}>
              <Link to="..">
                <Button
                  bg="none"
                  _hover={{ bg: "none" }}
                  border="1px solid #707070"
                  color="#707070"
                  px="48px"
                >
                  Cancel
                </Button>
              </Link>
              <Button
                type="submit"
                onClick={handleSubmit}
                isLoading={loading}
                isDisabled={email === ""}
                px="50px"
                _hover={{ bg: "teal" }}
              >
                Send
              </Button>
            </Flex>
          </form>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ForgetPassword;
