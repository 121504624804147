import { Flex, Divider, Text, Box, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { BsCalendarEvent, BsArrowLeft } from "react-icons/bs";
import { BiMenuAltLeft } from "react-icons/bi";
import PollDates from "./components/PollDates";
import { useEffect, useState } from "react";
import { getPollSummary, getPollTimes } from "./services/pollSummary";
import FullPageLoader from "../../components/full-page-loader";
import { IoTimeOutline } from "react-icons/io5";
import { timeago } from "../votingScreen/utils/timeAgo";
import { closingDate } from "../../components/DateFormat";
import { useSelector } from "react-redux";
// import dayjs from "dayjs";

const Home = () => {
  const [pollSummary, setPollSummary] = useState([]);
  const [pollTimes, setPollTimes] = useState([]);
  const [loading, setLoading] = useState(true);
  const pcode = sessionStorage.getItem("pcode");
  const setTimezone = useSelector((state) => state?.user?.timezone);
  const localTimeZone = useSelector((state) => state?.user?.localTimeZone);
  const timezone = setTimezone || localTimeZone;

  useEffect(() => {
    getPollSummary(pcode, setPollSummary, setLoading);
    getPollTimes(pcode, setPollTimes, setLoading);
  }, [pcode]);
  const { poll_description } = pollSummary;

   const splitDate =
     poll_description?.end_date && poll_description?.end_date?.split(" ");
   const endDate = splitDate && splitDate[0];

  return loading ? (
    <FullPageLoader />
  ) : (
    <>
      <Flex w="100%" color="#707070" direction="column" alignItems="center" pb={4}>
        <Flex
          direction="column"
          width={["100%", "100%", "100%", "40%"]}
          gridGap={4}
          align="center"
        >
          <Text fontSize={["20px", "24px"]} mt="20px" color="primary" fontWeight={"bold"}>
            Poll Summary
          </Text>
          <Divider />
          <Flex sx={{ "& > *": { lineHeight: "normal" } }}>
            <Box
              borderRadius="3px"
              boxSize="1rem"
              bg="#707070"
              mr={2}
              mt={2}
            ></Box>
            <Flex direction="column">
              <Text
                color="primary"
                fontSize={["1.5rem", "2rem", "2rem", "2rem"]}
              >
                {poll_description?.poll_name}
              </Text>
              <Flex>
                <Text
                  fontStyle="italic"
                  fontSize="0.9rem"
                  textTransform={"capitalize"}
                >
                  By {poll_description?.user?.name || "--"}
                </Text>
                <Text ml="6px" fontSize="0.9rem">
                  {timeago(poll_description?.created_at)}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex sx={{ "& > *": { lineHeight: "normal" } }} width="25ch">
            <Box mr={2}>
              <BsCalendarEvent />
            </Box>
            <Flex direction="row">
              <Text fontSize="1rem" fontWeight={"semibold"} ml="8px">
                {/* {poll_description?.end_date} */}
                {closingDate(timezone, endDate, "11:59:00 PM").month}{" "}
                {closingDate(timezone, endDate, "11:59:00 PM").date}{" "}
                {closingDate(timezone, endDate, "11:59:00 PM").year}
              </Text>
              <Flex ml="20px">
                <IoTimeOutline />
                <Text fontStyle="italic" fontSize="1rem" ml="5px">
                  {poll_description?.poll_duration % 60 !== 0
                    ? (poll_description?.poll_duration % 60) + " minutes"
                    : poll_description?.poll_duration / 60 +
                      `${
                        poll_description?.poll_duration / 60 > 1
                          ? " hours"
                          : " hour"
                      }`}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex sx={{ "& > *": { lineHeight: "normal" } }} width="25ch">
            <Box mr={2}>
              <BiMenuAltLeft size={24} />
            </Box>
            <Flex direction="column">
              <Text fontSize="1rem">{poll_description?.poll_description}</Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          flexBasis={["100%", "100%", "100%", "40%"]}
          px={[2, 4, 6, 0]}
          py={2}
          mt={[6, null, null, null]}
          // justifySelf={"center"}
          justifyContent={"center"}
          align={"center"}
          bg="#F8F8F8"
          w={["98%", "98%", "100%"]}
          overflowX="scroll"
          sx={{
            "&::-webkit-scrollbar": {
              height: "15px",
              "&-track": {
                backgroundColor: "#F9F9F9",
                borderRadius: " 100px",
              },
              "&-thumb": {
                border: "5px solid transparent",
                borderRadius: "100px",
                backgroundColor: "#e4e4e4",
                backgroundClip: "content-box",
              },
            },
          }}
        >
          {pollTimes?.data?.map((item, i) => {
            return (
              <PollDates
                data={item}
                date={item?.date}
                start={item?.start}
                end={item?.end}
                key={i}
              />
            );
          })}
        </Flex>
        <Flex justify="flex-end" mt={4}>
          <Link to="/select-meeting-date">
            <Button
              mr={2}
              leftIcon={<BsArrowLeft />}
              bg="transparent"
              border="2px solid"
              borderColor="primary"
              _hover={{ bg: "transparent" }}
              color="primary"
              // onClick={()=>setPollSummary([])}
            >
              Back
            </Button>
          </Link>
          <Link to="/share-link">
            <Button bg="primary" color="white">
              Continue
            </Button>
          </Link>
        </Flex>
      </Flex>
    </>
  );
};

export default Home;
