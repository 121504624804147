export const AUTH_ROUTES = {
  GET_USER: "auth/get-user",
  REGISTER_USER: "auth/register",
  LOGIN_USER: "auth/login",
  GOOGLE_LOGIN: "auth/google-login",
  LOGOUT_USER: "auth/logout",
  CREATE_POLL: "create-poll",
  SELECT_SINGLE_DATES: "single-date-time-handler",
  SELECT_MULTIPLE_DATES: "multiple-date-time-handler",
  FORGOT_PASSWORD: "auth/forgot-pin",
  UPDATE_ACCOUNT: "auth/profile",
  UPDATE_PIN: "auth/pin",
  CONTACT_US: "send-feedback",
  GET_USER_WITH_EMAIL: (email) => `auth/email/${email}`,
  // EMAIL_LINK_LOGIN: "emailhandler?pcode=${pcode}&email=${email}",

  //CONTACTS
  GET_CONTACTS: "contacts",
  CREATE_CONTACT: "create-contact",
  DELETE_CONTACT: (id) => `delete-contact/${id}`,
  UPDATE_CONTACT: "update-contact",
  UPLOAD_CONTACTS: "bulk-contacts",
  SEARCH_CONTACT: (inputValue) => `search-contact/${inputValue}`,

  //POLL
  GET_ALL_POLLS: "all-polls",
  GET_COMPLETED_POLLS: "completed-polls",
  GET_POLL_SUMMARY: (pcode) => `poll/${pcode}`,
  GET_POLL_TIMES: (pcode) => `get-poll-times/${pcode}`,
  GET_VOTERS: (pcode) => `voters/${pcode}`,
  SEND_VOTE: "vote",
  GET_MEETINGS: "polls",
  GET_POLLS_INVITED_TO: "polls-invited-to",
  SEARCH_POLLS: (inputValue) => `search-polls/${inputValue}`,
  SEARCH_GROUP: (inputValue) => `search-group/${inputValue}`,
  SEND_INVITES: "send-invites",
  SEND_FINAL_DATE: "send-final-date",
  VOTE_ALL_YES: "vote-all-yes",
  VOTE_ALL_NO: "vote-all-no",

  // GROUPS
  CREATE_GROUP: "create-group",
  GET_GROUPS: "groups",
  GET_GROUP_CONTACTS: (groupId) => `group-contacts/${groupId}`,
  GET_OTHER_MEMBERS: (groupId) => `other-contacts/${groupId}`,
  DELETE_GROUP: (id) => `delete-group/${id}`,
  EDIT_GROUP: (id) => `update-group/${id}`,
  ADD_MEMBERS_TO_GROUP: "mass-assign",
  REMOVE_MEMBERS_FROM_GROUP: "mass-remove",
};
