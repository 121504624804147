import React from "react";
import { Image, Box } from "@chakra-ui/react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";


export default function Banner({ h, w, image, boxH, data }) {

  return (
    <Splide
      options={{
        rewind: true,
        autoplay: true,
        pauseOnHover: true,
        arrows: false,
      }}
    >
      {data.map((datum, id) => {
        return (
          <SplideSlide key={id}>
            <a
              href="https://zealightlabs.com"
              target={"_blank"}
              rel="noreferrer"
            >
              <Box bg="#fff" h={boxH || "100vh"}>
                <Image src={datum} h={h || "100%"} w={w} />
              </Box>
            </a>
          </SplideSlide>
        );
      })}
    </Splide>
  );
}
