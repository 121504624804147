import { isValidElement, Children, cloneElement } from "react";
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Button,
} from "@chakra-ui/react";

export default function CustomModal({
  buttonText,
  modalTitle,
  children,
  buttonProps,
  textAlign,
  fontSize,
  size,
  px, 
  py
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const childrenWithProps = Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a TS error too.
    if (isValidElement(child)) {
      return cloneElement(child, { onClose });
    }

    return child;
  });
  return (
    <>
      <Button onClick={onOpen} {...buttonProps} fontSize={fontSize || "16px"} px={px}>
        {buttonText}
      </Button>
      <ChakraModal size={size} isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay bg="rgba(158, 198, 255, 0.4)" />
        <ModalContent sx={{ borderRadius: "14px" }} py={py || 5}>
          <ModalHeader borderBottom="2px solid #f4f4f4" textAlign={textAlign}>
            {modalTitle}
          </ModalHeader>
          <ModalCloseButton
            fontSize="20px"
            mt={5}
            mr={5}
            _focus={{ borderBox: "none" }}
          />
          <ModalBody px={px || 8} py={{ base: py || 6, md: py || 4, lg: py || 6 }}>
            {childrenWithProps}
          </ModalBody>
        </ModalContent>
      </ChakraModal>
    </>
  );
}
