import { BsDash, BsTrashFill, BsPlus } from "react-icons/bs";
import { Flex, Box, Text, Button, Select } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { generateStartTime, generateStopTime } from "../utils/timeFuction";
import { useState, useEffect } from "react";
import { parse, format } from "date-fns";
// import dayjs from "dayjs";

const TimeActionsRow = ({
  time,
  handleChange,
  setDividedTimes,
  handleDelete,
  setActionNot,
}) => {
  const pollDuration = useSelector(
    (state) => state.polls.createPollData.poll_duration
  );

  const [allTimes, setAllTimes] = useState([]);
  const [allStopTimes, setAllStopTimes] = useState([]);

  // console.log("time", time.start, time.end)
  const startTime = time?.start.split(":");
  const startHour = startTime[0] * 60;
  const startMins = startTime[1];
  const totalStart = parseInt(startHour) + parseInt(startMins);
  const endTime = time?.end.split(":");
  const endHour = endTime[0] * 60;
  const endMins = endTime[1];
  const totalEnd = parseInt(endHour) + parseInt(endMins);
  const dividedTime = (totalEnd - totalStart) % pollDuration;
  setDividedTimes(dividedTime);

  const hr = startTime?.[0];
  useEffect(() => {
    generateStartTime(15, setAllTimes, 0);
    generateStopTime(parseInt(pollDuration), setAllStopTimes, hr, startMins, 0);
  }, [pollDuration, hr, startMins]);

  if (time.start >= time.end || time.start === null) {
    setActionNot(true);
  } else {
    setActionNot(false);
  }

  return (
    <Flex
      bg="white"
      align="center"
      sx={{
        "& * input": {
          height: "2rem",
          boxSizing: "border-box",
          border: "1px solid gray",
          borderRadius: "md",
          px: 2,
          w: ["6rem", "6rem", "auto", "auto", "auto"],
          fontSize: ["sm", "sm", "auto", "auto", "auto"],
        },
      }}
    >
      <Flex mr={2}>
        <Box>
          <Select
            placeholder="From"
            w="120px"
            name="start"
            onChange={(e) => handleChange(e, time)}
          >
            {allTimes.map((singleTime, index) => {
              return (
                <option key={index} value={singleTime}>
                  {format(parse(singleTime, "HH:mm:ss", new Date()), "hh:mm a")}
                </option>
              );
            })}
          </Select>
        </Box>
        <Box mx={1} my="8px">
          <BsDash size="1rem" />
        </Box>
        <Box>
          <Select
            placeholder={"To"}
            w="120px"
            name="end"
            // value={time.start}
            onChange={(e) => handleChange(e, time)}
          >
            {allStopTimes?.map(
              (singleTime, index) =>
                singleTime > time?.start && (
                  <option key={index} value={singleTime}>
                    {format(
                      parse(singleTime, "HH:mm:ss", new Date()),
                      "hh:mm a"
                    )}
                  </option>
                )
            )}
          </Select>
        </Box>
      </Flex>
      <BsTrashFill
        color="#757575"
        style={{ marginRight: ".2rem", cursor: "pointer" }}
        size="1.3rem"
        onClick={() => handleDelete(time.id)}
      />
    </Flex>
  );
};

export const SingleTimeTab = ({
  times,
  setTimes,
  timeConvert,
  setDividedTimes,
  setActionNot,
}) => {
  // const [closeTime, setCloseTime] = useState("");
  const pollDuration = useSelector(
    (state) => state.polls.createPollData?.poll_duration
  );

  const handleChange = (e, time) => {
    const { name, value } = e.target;
    const filteredTime = times.map((item) => {
      return item.id !== time.id ? item : { ...time, [name]: value };
    });

    setTimes(filteredTime);
  };

  const handleAdd = () => {
    return setTimes([
      ...times,
      { id: ++times.length, start: "07:00", end: "00:00" },
    ]);
  };

  const handleDelete = (timeId) => {
    const filteredTimes = times.filter((time) => time.id !== timeId);
    setTimes(filteredTimes);
  };

  return (
    <Flex
      direction="column"
      alignItems="center"
      overflowY="scroll"
      height="90%"
      p={2}
      sx={{
        "&::-webkit-scrollbar": {
          width: "15px",
          "&-track": {
            backgroundColor: "#F9F9F9",
            borderRadius: " 100px",
          },
          "&-thumb": {
            border: "5px solid transparent",
            borderRadius: "100px",
            backgroundColor: "#e4e4e4",
            backgroundClip: "content-box",
          },
        },
      }}
    >
      <Flex
        direction={"column"}
        justify="center"
        align="center"
        w="100%"
        mb="10px"
      >
        <Text
          fontSize={["18px", "20px"]}
          mt="10px"
          textAlign={"center"}
          color="#707070"
          pl="4"
        >
          Same time for all dates
        </Text>
        <Flex
          // bg="#fed7d7"
          bg={"#f2f2f2"}
          p="5px"
          justifyContent={"space-between"}
          borderRadius={"3px"}
        >
          <AiOutlineInfoCircle size="16px" color="red" />
          <Text
            fontSize={["12px", "12px"]}
            // mb="10px"
            textAlign={"center"}
            color="#707070"
            pl="4"
          >
            {`The meeting time selected will be splitted by ${timeConvert(
              pollDuration
            )}`}
          </Text>
        </Flex>
      </Flex>
      {times?.map((time, i) => {
        return (
          <Box key={i} _notLast={{ mb: [1, 1, 2, 2] }}>
            <TimeActionsRow
              // key={i}
              time={time}
              // actionNot={actionNot}
              // closeTime={closeTime}
              // setCloseTime={setCloseTime}
              setActionNot={setActionNot}
              handleDelete={handleDelete}
              handleChange={handleChange}
              // dividedTimes={dividedTimes}
              setDividedTimes={setDividedTimes}
              // handleDuration={handleDuration}
              // selectedDuration={selectedDuration}
              // setSelectedDuration={setSelectedDuration}
            />
          </Box>
        );
      })}
      <Button
        mt={2}
        bg="#F8F8F8"
        _hover={{ bg: "transparent" }}
        color="inherit"
        onClick={handleAdd}
        rightIcon={
          <BsPlus
            color="#757575"
            style={{ marginRight: ".2rem", cursor: "pointer" }}
            size="2rem"
          />
        }
      >
        Add Time
      </Button>
    </Flex>
  );
};
