import { Flex, Circle, Text, Box, Button } from "@chakra-ui/react";
import { SiGmail } from "react-icons/si";
import { MdGroups, MdPerson } from "react-icons/md";
import CustomModal from "../../components/CustomModal";
import InviteSingleContact from "./components/InviteSingleContact";
import InviteGroup from "./components/InviteGroup";
import InviteEmail from "./components/InviteEmail";
import { Link } from "react-router-dom";
import { BsShareFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { errorNotifier } from "../../components/NotificationHandler";
import { useEffect, useState } from "react";
import { getContacts, getGroups } from "./services/invite";

const IconBox = ({ icon, text }) => {
  return (
    <Flex direction="column" align="center">
      <Circle border="1px solid #CEE2FF" color="primary" p={[2, 6]} mb={3}>
        {icon}
      </Circle>
      <Text color="#757575" fontSize={["10px", "sm"]} mr={["5px", 0]}>
        {text}
      </Text>
    </Flex>
  );
};

const ShareLink = () => {
  const user = useSelector((state) => state.user.name);
  const premiumUser = useSelector((state) => state.user.isPremium);
  const pcode = sessionStorage.getItem("pcode");
  const [refresh, setRefresh] = useState({});
  const [contacts, setContacts] = useState([]);
  const [groups, setGroups] = useState([]);
  const [contactsLoading, setContactsLoading] = useState(false);
  const [groupsLoading, setGroupsLoading] = useState(false);

  useEffect(() => {
    setContactsLoading(true);
    setGroupsLoading(true);
    getContacts().then((response) => {
      setContactsLoading(false);
      setContacts(response?.data?.data);
    });
    getGroups()
      .then((response) => {
        setGroups(response?.data?.data);
      })
      .finally(() => setGroupsLoading(false));
  }, [refresh]);

  const shareLink = () => {
    if (navigator.share) {
      navigator
        .share({
          title: `Poll Invites from ${user}`,
          text: `Hi ${user} has created a meeting poll on fixmeet and has invited you to participate, click the link below to join the poll`,
          url: `https://fixmeet.app/login?pcode=${pcode}`,
        })
        .then(() => null)
        .catch((error) => null);
    } else {
      errorNotifier(`Your system doesn't support sharing`);
    }
  };

  return (
    <Box h="calc(100vh - 12rem)" d="grid" placeContent="center" color="#707070">
      <Flex justify="space-around" w="100%" alignItems="center">
        {premiumUser === 1 && (
          <Flex>
            <CustomModal
              buttonText={
                <IconBox text="Gmail" icon={<SiGmail size="2rem" />} />
              }
              buttonProps={{
                bg: "transparent",
                _hover: { background: "transparent" },
                _active: { background: "transparent" },
              }}
              modalTitle="Send Link to Email(s)"
            >
              <InviteEmail pcode={pcode} />
            </CustomModal>
            <CustomModal
              buttonText={
                <IconBox
                  text="Single Contact"
                  icon={<MdPerson size="2rem" />}
                />
              }
              buttonProps={{
                bg: "transparent",
                _hover: { background: "transparent" },
                _active: { background: "transparent" },
              }}
              modalTitle="Select Contact(s) to Invite"
            >
              <InviteSingleContact
                pcode={pcode}
                contacts={contacts}
                contactsLoading={contactsLoading}
                setRefresh={setRefresh}
              />
            </CustomModal>
            <CustomModal
              buttonText={
                <IconBox text="Group" icon={<MdGroups size="2rem" />} />
              }
              buttonProps={{
                bg: "transparent",
                _hover: { background: "transparent" },
                _active: { background: "transparent" },
              }}
              modalTitle="Select Group(s) to Invite"
            >
              <InviteGroup
                pcode={pcode}
                groups={groups}
                groupsLoading={groupsLoading}
                setRefresh={setRefresh}
              />
            </CustomModal>
          </Flex>
        )}
        <Box cursor={"pointer"} onClick={() => shareLink()}>
          <IconBox
            text="share to contacts"
            icon={<BsShareFill size="2rem" />}
          />
        </Box>
      </Flex>
      <Flex alignItems={"center"} justifyContent={"center"} mt="15%">
        <Link to="/completed">
          <Button>Done</Button>
        </Link>
      </Flex>
    </Box>
  );
};

export default ShareLink;
