import React from "react"
import { Box, Heading, Img, Stack, Text } from "@chakra-ui/react"
import fadedDash from "../assets/faded-dash.svg"
import groupUser from "../assets/group-user.svg"
import twoGroupUser from "../assets/two-user-group.svg"
import singleUser from "../assets/single-user.svg"

export const FeatureList = () => {
  return (
    <Box>
      <Stack
        direction={["column", "row"]}
        justifyContent="space-between"
        alignItems="center"
        textAlign="left"
        spacing="15px"
      >
        <Stack
          spacing="10px"
          bg="#F3E6FF"
          maxWidth="300px"
          p="20px"
          borderRadius="5px"
        >
          <Box position="relative" maxWidth="150px" h="150px">
            <Img
              src={fadedDash}
              alt="faded-dash"
              position="absolute"
              zIndex="9"
            />
            <Img
              src={groupUser}
              alt="group-user"
              position="absolute"
              zIndex="10"
              marginLeft="60px"
            />
          </Box>
          <Heading
            color="#6C4097"
            fontSize="1.35em"
            maxW={["100%", "initial"]}
            // fontFamily="Trenda-bold"
          >
            Decide Together
          </Heading>
          <Text fontSize=".8em " maxW="300px" color="#6C4097">
            Fix your meeting date and time based on all participants’
            availability and share the final decision easily via email with all
            participants with your custom message (your actual meeting link or
            venue)
          </Text>
        </Stack>
        <Stack
          spacing="10px"
          bg="#F3E6FF"
          maxWidth="300px"
          p="20px"
          pb={"80px"}
          borderRadius="5px"
        >
          <Box position="relative" maxWidth="150px" h="150px">
            <Img
              src={fadedDash}
              alt="faded-dash"
              position="absolute"
              zIndex="9"
            />
            <Img
              src={twoGroupUser}
              alt="group-user"
              position="absolute"
              zIndex="10"
              marginLeft="60px"
            />
          </Box>
          <Heading
            color="#6C4097"
            fontSize="1.35em"
            maxW={["100%", "initial"]}
            // fontFamily="Trenda-bold"
          >
            Easy Polling
          </Heading>
          <Text fontSize=".8em" maxW="300px" color="#6C4097">
            Fix a range for your best days and times your meeting can hold to
            allow all participants respond with their availability.
          </Text>
        </Stack>
        <Stack
          spacing="10px"
          bg="#F3E6FF"
          maxWidth="300px"
          p="20px"
          pb={["80px"]}
          borderRadius="5px"
        >
          <Box position="relative" maxWidth="150px" h="150px">
            <Img
              src={fadedDash}
              alt="faded-dash"
              position="absolute"
              zIndex="9"
            />
            <Img
              src={singleUser}
              alt="group-user"
              position="absolute"
              zIndex="10"
              marginLeft="60px"
            />
          </Box>
          <Heading
            color="#6C4097"
            fontSize="1.35em"
            maxW={["100%", "initial"]}
            // fontFamily="Trenda-bold"
          >
            Share Everywhere
          </Heading>
          <Text fontSize=".8em" maxW="300px" color="#6C4097">
            With Fixmeet, you can share your meeting polls via email, social
            media or any platform of your choice with easy
          </Text>
        </Stack>
      </Stack>
    </Box>
  );
}
