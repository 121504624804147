import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { emailLoginUser } from "../../features/user";
// import { emailLogin } from './services/emailHandler';
import { getPollSummary, getPollTimes } from "../home/services/pollSummary";
import { savePollData, savePollTimes } from "../../features/poll";
import FullPageLoader from "../../components/full-page-loader";
import { errorNotifier } from "../../components/NotificationHandler";
import { useNavigate } from "react-router-dom";
// import dayjs from "dayjs";

export default function EmailLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = useSelector((state) => state.user.userId);
  const [loadSpinner, setLoadSpinner] = useState(false);

  const url = window.location.href;
  const urlString = new URL(url);
  const pcode = urlString.searchParams?.get("pcode");
  const email = urlString.searchParams?.get("email");

  useEffect(() => {
    setLoadSpinner(true);
    const controller = new AbortController();

    if (email && pcode) {
      dispatch(emailLoginUser(email))
        .then(() => {})
        .then(() => {
          //  getPollTimes(pcode).then((data) => {
          //    dispatch(savePollTimes(data));
          //  });
          getPollSummary(pcode)
            .then((data) => {
              dispatch(savePollData(data));
              if (
                data?.poll_description.closed === 1 &&
                userId === data?.poll_description?.user_id
              ) {
                navigate("/final-dates");
              } else if (
                data?.poll_description.closed === 0 ||
                data?.poll_description.closed === 1 ||
                data?.poll_description.closed === 2
              ) {
                navigate("/vote");
              } else {
                errorNotifier("Invalid Poll");
                navigate("/dashboard");
              }
            })
            .then(() => setLoadSpinner(false))
            .catch((err) => {
              if (
                err.response?.data?.message ===
                "Not logged in, Log in to continue"
              ) {
                errorNotifier("You are logged in with another device");
                window.location.href = "/";
                // localStorage.clear();
              } else {
                errorNotifier("Something went wrong");
              }
            });
          getPollTimes(pcode).then((data) => {
            dispatch(savePollTimes(data));
          });
        });
      return;
    } else {
      navigate("/");
    }
    return () => controller.abort();
  }, [navigate, userId, dispatch, email, pcode]);

  return loadSpinner && <FullPageLoader />;
}
