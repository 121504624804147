import { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Text,
  FormLabel,
  Input,
  Button,
  Flex,
  Textarea,
  Divider,
  Select,
  Box,
  FormControl,
} from "@chakra-ui/react";
import { MdArrowBack } from "react-icons/md";
import { createPoll as createPollService } from "./services/createPoll";
import {
  errorNotifier,
  successNotifier,
  // errorNotifier,
} from "../../components/NotificationHandler";
import { saveCreatePollData } from "../../features/poll";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AiOutlineCloseCircle } from "react-icons/ai";
import TimezoneSelect from "react-timezone-select";

const CreatePoll = () => {
  const dispatch = useDispatch();
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const storedPollData = useSelector((state) => state?.polls?.createPollData);
  const [formValues, setFormValues] = useState({
    title: storedPollData?.poll_name,
    description: storedPollData?.poll_description,
    endDate: storedPollData?.end_date,
    hours: "",
    minutes: "",
  });
  const [loading, setLoading] = useState(false);
  const [custom, setCustom] = useState(true);
  const navigate = useNavigate();
  const formRef = useRef();

  // console.log("time", timezone)
  const [duration, setDuration] = useState(storedPollData?.poll_duration);
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const handleDuration = (e) => {
    const value = e.target.value;
    setDuration(value);
    if (value === "") setCustom(false);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (value > 12) {
      return setFormValues({ ...formValues, hours: 12 });
    }

    setFormValues({ ...formValues, [name]: value });
  };
  const handleHours = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (value > 12) {
      return setFormValues({ ...formValues, hours: 12 });
    }

    setFormValues({ ...formValues, [name]: value });
  };

  const handleMinutes = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (value > 59) {
      return setFormValues({ ...formValues, minutes: 59 });
    }

    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    const customTime =
      parseInt(formValues.hours) * 60 + parseInt(formValues?.minutes);

    e.preventDefault();
    const payload = {
      poll_name: formValues?.title,
      poll_description: formValues?.description,
      poll_duration:
        customTime ||
        parseInt(formValues.hours) * 60 ||
        0 + parseInt(formValues.minutes) ||
        duration,
      end_date: formValues.endDate,
      timezone: timezone.value || localTimezone,
      timezone_label: timezone.label || localTimezone,
    };

    if (!formRef.current.reportValidity()) return;
    try {
      setLoading(true);
      const response = await createPollService(payload);
      successNotifier(response?.data?.message);
      sessionStorage.setItem("pcode", response?.data?.data?.pcode);
      dispatch(
        saveCreatePollData({ ...payload, pcode: response?.data?.data?.pcode })
      );
      navigate("/select-meeting-date");
      setFormValues({
        title: "",
        description: "",
        endDate: "",
        hours: "",
        minutes: "",
      });
    } catch (e) {
      if (e?.response) {
      } else {
        errorNotifier("Please Check Your network and try again");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex
      placeContent="center"
      borderRadius="lg"
      bg="#fbfbfb"
      direction="column"
      justifyContent={["center"]}
      alignItems={["center"]}
      p={[0, 4]}
      w={["100%", "100%", "100%", "100%"]}
      h="calc(100vh)"
    >
      <Flex
        borderRadius="lg"
        bg="white"
        border="2px solid #f2f2f2"
        direction="column"
        color="#707070"
        p={[6, 4, 2, 4]}
        w={["100%", "100%", "60%"]}
        sx={{ "& label": { color: "#D2D2D2" } }}
      >
        <Flex color="primary" align="center" fontSize="2rem">
          <Link to="..">
            <MdArrowBack style={{ marginRight: "1rem" }} />
          </Link>
          <Text as="h2" fontWeight="bold">
            Create Poll
          </Text>
        </Flex>
        <Divider my={6} />
        <form ref={formRef}>
          <FormControl isRequired>
            <FormLabel color="#999 !important">Title</FormLabel>
            <Input
              bg="#fbfbfb"
              type="text"
              name="title"
              value={formValues.title}
              onChange={handleChange}
              mb={8}
              px={4}
              placeholder="What's the occasion?"
              _placeholder={{ color: "#D2D2D2", fontStyle: "italic" }}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel color="#999 !important">Description</FormLabel>
            <Textarea
              type="text"
              name="description"
              value={formValues.description}
              bg="#fbfbfb"
              resize="none"
              onChange={handleChange}
              mb={8}
            />
          </FormControl>
          <Flex
            mb={2}
            w="100%"
            justifyContent={["center", "space-between"]}
            direction={["column", "row"]}
          >
            <Box w={["100%", "35%", "35%", "33%"]}>
              <Flex direction="column" w={["100%", "94%", "90%", "70%"]}>
                <FormControl isRequired>
                  <FormLabel color="#999 !important">
                    Poll Closing Date
                  </FormLabel>
                  <Input
                    variant="flush"
                    type="date"
                    px={1}
                    sx={{
                      "&&::-webkit-calendar-picker-indicator": {
                        margin: 0,
                      },
                    }}
                    name="endDate"
                    value={formValues.endDate}
                    onChange={handleChange}
                    borderBottom="1px solid #D2D2D2"
                    required
                  />
                </FormControl>
              </Flex>
            </Box>
            <Flex
              width={["100%", "35%", "35%", "33%"]}
              direction={["column"]}
              alignItems={["left", "left"]}
              // justify="center"
              mt={["20px", 0]}
            >
              <FormLabel color="#999 !important" textAlign={"left"}>
                Timezone(Optional)
              </FormLabel>
              <TimezoneSelect
                value={timezone}
                onChange={setTimezone}
                selected
              />
            </Flex>
            {custom ? (
              <Flex
                w={["100%", "30%", "30%", "33%"]}
                direction={["column"]}
                alignItems={["left", "flex-end"]}
                mt={["20px", 0]}
              >
                <FormControl isRequired width={["100%", "100%", "80%"]}>
                  <FormLabel
                    color="#999 !important"
                    alignSelf={["left", "center"]}
                  >
                    Meeting Duration
                  </FormLabel>
                  <Select
                    placeholder="Select"
                    w={["100%", "90%", "90%", "100%"]}
                    onChange={handleDuration}
                  >
                    <option value={15}>15 Minutes</option>
                    <option value={30}>30 Minutes</option>
                    <option value={60}>1 Hour</option>
                    <option value={120}>2 Hours</option>
                    <option value={180}>3 Hours</option>
                    <option value={240}>4 Hours</option>
                    <option value="">Custom Duration</option>
                  </Select>
                </FormControl>
              </Flex>
            ) : (
              <Flex
                width={["100%", "40%"]}
                direction="column"
                display={["flex"]}
                ml={[0, "20px"]}
              >
                <Flex mt={["20px", 0]}>
                  <FormLabel color="#999 !important" textAlign={"left"}>
                    Custom Duration
                  </FormLabel>
                  <Box mt="4px">
                    <AiOutlineCloseCircle onClick={() => setCustom(true)} />
                  </Box>
                </Flex>

                <Flex align="baseline">
                  <FormLabel>Hours</FormLabel>
                  <Input
                    variant="flush"
                    type="number"
                    borderBottom="1px solid #D2D2D2"
                    borderRadius="none"
                    p={0}
                    max="12"
                    min="0"
                    name="hours"
                    value={formValues.hours}
                    onChange={handleHours}
                    // required
                  />
                  <FormLabel color="#999 !important">Minutes</FormLabel>
                  <Input
                    type="number"
                    variant="flush"
                    borderBottom="1px solid #D2D2D2"
                    borderRadius="none"
                    name="minutes"
                    value={formValues.minutes}
                    onChange={handleMinutes}
                    p={0}
                    max="59"
                    min="00"
                    // required
                  />
                </Flex>
              </Flex>
            )}
          </Flex>
          <Flex justify="flex-end" mt={4}>
            <Button
              type="submit"
              onClick={handleSubmit}
              isLoading={loading}
              isDisabled={
                formValues.title === "" ||
                formValues.description === "" ||
                (duration === "" &&
                  formValues.hours === "" &&
                  duration === "" &&
                  formValues.minutes === "")
                // formValues.hours === ""
              }
              _hover={{ bg: "primary" }}
            >
              Proceed
            </Button>
          </Flex>
        </form>
      </Flex>
    </Flex>
  );
};

export default CreatePoll;
