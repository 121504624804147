import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { errorNotifier } from "../components/NotificationHandler";
import http from "../services/api";
import { AUTH_ROUTES } from "../services/routes.constants";

export const getUser = createAsyncThunk("user/getUser", async (data) => {
  const response = await http.post(AUTH_ROUTES.GET_USER, data);
  return response;
});

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.post(AUTH_ROUTES.LOGIN_USER, data);
      return response;
    } catch (e) {
       if (e?.response) {
         if (e?.response?.data?.message==="Invalid Login Credentials") {
           return errorNotifier("Please check the email and pin");
         }
         if (e?.response?.data?.message?.pin) {
           return errorNotifier(e?.response?.data?.message?.pin[0]);
         }
        //  else{errorNotifier("Something Went Wrong");}
       } else {
         console.log("err", e);
         errorNotifier("Please check your network connection and try again");
         return rejectWithValue(e);
       }
      // console.log("err", e);
      return rejectWithValue(e.response);
    }
  }
);

export const emailLoginUser = createAsyncThunk(
  "user/emailLoginUser",
  async (email) => {
    const response = await http.get(AUTH_ROUTES.GET_USER_WITH_EMAIL(email));
    return response;
  }
);

export const registerUser = createAsyncThunk(
  "user/registerUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.post(AUTH_ROUTES.REGISTER_USER, data);
      return response;
    } catch (e) {
      if (e?.response) {
        if (e?.response?.data?.message?.email){
          return errorNotifier(e?.response?.data?.message?.email[0]);
        }
        if (e?.response?.data?.message?.pin) {
          return errorNotifier(e?.response?.data?.message?.pin[0]);
        }
      }else{
        console.log(e);
        errorNotifier("Please Check your network connection and try again");
         return rejectWithValue(e);
      }
      console.log(e);
      return rejectWithValue(e.response);
    }
  }
);

export const logoutUser = createAsyncThunk("user/logoutUser", async () => {
  try {
    const response = await http.post(AUTH_ROUTES.LOGOUT_USER);
    return response;
  } catch (e) {
    console.log("err", e);
  }
});

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const initialState = {
  userId: "",
  name: "",
  email: "",
  isPremium: "",
  loading: false,
  isLoggedIn: false,
  timezone: "",
  localTimeZone: timezone,
  label: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: {
    [registerUser.pending]: (state) => {
      state.loading = true;
    },
    [registerUser.fulfilled]: (state, action) => {
      console.log("register user fulfilled", action);
      const {
        payload: {
          data: { data },
        },
      } = action;
      state.name = data.user.name;
      state.email = data.user.email;
      state.userId = data.user.id;
      state.isPremium = data.user.is_premium;
      localStorage.setItem("11#221#", data.token);
      state.loading = false;
      state.isLoggedIn = true;
      state.timezone = data.user.user_timezone || "";
      state.localTimeZone = timezone;
    },
    [registerUser.rejected]: (state, action) => {
      console.log("register user rejected", action.payload);
      state.loading = false;
      state.isLoggedIn = false;
    },
    [loginUser.pending]: (state) => {
      state.loading = true;
    },
    [loginUser.fulfilled]: (state, action) => {
      const {
        payload: {
          data: { data },
        },
      } = action;
      state.name = data.user.name;
      state.email = data.user.email;
      state.userId = data.user.id;
      state.isPremium = data.user.is_premium;
      localStorage.setItem("11#221#", data.token);
      state.loading = false;
      state.isLoggedIn = true;
      state.timezone = data.user.user_timezone;
      state.localTimeZone = timezone;
    },
    [loginUser.rejected]: (state, action) => {
      console.log("register user rejected", action.payload);
      state.loading = false;
      state.isLoggedIn = false;
    },
    //checking
    [emailLoginUser.pending]: (state) => {
      state.loading = true;
    },
    [emailLoginUser.fulfilled]: (state, action) => {
      const {
        payload: {
          data: { data },
        },
      } = action;
      state.name = data.user.name;
      state.email = data.user.email;
      state.userId = data.user.id;
      state.isPremium = data.user.is_premium;
      localStorage.setItem("11#221#", data.token);
      state.loading = false;
      state.isLoggedIn = true;
      state.timezone = data.user.user_timezone;
      state.localTimeZone = timezone;
    },
    [emailLoginUser.rejected]: (state, action) => {
      state.loading = false;
      state.isLoggedIn = false;
    },

    //
    [logoutUser.pending]: (state) => {
      state.loading = true;
    },
    [logoutUser.fulfilled]: (state) => {
      state.name = "";
      state.email = "";
      state.userId = "";
      state.isPremium = "";
      state.loading = false;
      state.isLoggedIn = false;
      localStorage.removeItem("11#221#");
      // localStorage.clear()
      console.log("logout fulfilled");
    },
    [logoutUser.rejected]: (state, action) => {
      console.log("logOut user rejected", action.response);
      if (action.response.data.message) {
        window.location.href = "/";
        localStorage.clear();
      }
      state.loading = false;
    },
  },
  reducers: {
    googleLogin: (state, action) => {
      const { name, email, userId, isPremium, token, timezone, localTimeZone } =
        action.payload;
      state.name = name;
      state.email = email;
      state.isLoggedIn = true;
      state.userId = userId;
      state.isPremium = isPremium;
      localStorage.setItem("11#221#", token);
      state.timezone = timezone;
      state.localTimeZone = localTimeZone;
    },
    updateProfile: (state, action) => {
      const { name, timezone, label } = action.payload;
      state.timezone = timezone;
      state.name = name;
      state.label = label;
    },
  },
});

// Action creators are generated for each case reducer function
export const { googleLogin, updateProfile } = userSlice.actions;

export default userSlice.reducer;
