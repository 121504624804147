import http from "../../../services/api";
import { AUTH_ROUTES } from "../../../services/routes.constants";
import {
  successNotifier,
  errorNotifier,
} from "../../../components/NotificationHandler";

export const sendFinalDate = async (formData, setLoading) => {
  try {
    const response = await http.post(AUTH_ROUTES.SEND_FINAL_DATE, formData);
// console.log("hhh", response)
setLoading(false)
    successNotifier(response.data.message);
    return response
  } catch (e) {
    setLoading(false);
    console.log(e.response);
    errorNotifier(e.response);
  }
};
