import { Box, Heading, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import Nav from "../../../components/Nav";
import { Footer } from "../component/Footer";

export const Dpa = () => {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  return (
    <Box>
      {!isLoggedIn && <Nav />}
      <Box p={["20px 10%", "20px 10%", "50px 20%"]}>
        <div>
          <Heading pb={["20px"]} color="primary">
            Fixmeet Data Processing Addendum
          </Heading>
          <p>
            This Data Processing Addendum, including the Standard Contractual
            Clauses referenced herein (“DPA”), amends and supplements any
            existing and currently valid agreement or Terms of Use (the
            “Agreement”) either previously or concurrently made between you
            (together with subsidiary(ies) and affiliated entities,
            collectively, “Customer”) and Fixmeet (together with subsidiary(ies)
            and affiliated entities, collectively “Processor”) and sets forth
            other terms that apply to the extent any information you provide to
            Processor pursuant to the Agreement includes Personal Data (as
            defined below). This DPA is effective as of the last signature date
            on the agreement.
          </p>
          <Heading
            my="10px"
            color={"primary"}
            as="h2"
            fontSize={["20px"]}
            fontWeight="bold"
          >
            1.0 Defined Terms. The following definitions are used in this DPA:
          </Heading>
          <p>
            1.1 “Authorized Personnel” means (a) Processor’s employees who have
            a need to know or otherwise access Personal Data for the purposes of
            performing applicable services; and (b) Processor’s contractors,
            agents, and auditors who have a need to know or otherwise access
            Personal Data to enable Processor to perform its obligations under
            the Agreement and this DPA, and who are bound in writing by
            confidentiality and other obligations sufficient to protect Personal
            Data in accordance with the terms and conditions of this DPA.
          </p>

          <Text my="20px">
            1.2 “CCPA” means the California Consumer Privacy Act.
          </Text>

          <p>
            1.3 “Data Protection Laws” means all applicable federal, state, and
            foreign data protection, privacy and data security laws, as well as
            applicable regulations and formal directives intended by their
            nature to have the force of law, including, without limitation, the
            EU Data Protection Laws and the CCPA but excluding, without
            limitation, consent decrees.
          </p>

          <Text my="20px">
            1.4 “EU Data Protection Laws” means all laws and regulations of the
            European Union, the European Economic Area, their member states,
            Switzerland, and the United Kingdom, applicable to the processing of
            Personal Data for the services under the Agreement, including (where
            applicable) the GDPR.
          </Text>

          <p>
            1.5 “GDPR” means the General Data Protection Regulation (Regulation
            (EU) 2016/679 of the European Parliament and of the Council of 27
            April 2016 on the protection of natural persons with regard to the
            processing of personal data and on the free movement of such data.)
          </p>

          <Text my="20px">
            1.6 “Personal Data” means any information relating to an identified
            or identifiable natural person that is submitted to, or collected
            by, Fixmeet in connection with the services provided by Processor,
            when such data is protected as “personal data” or “personally
            identifiable information” or a similar term under Data Protection
            Law(s).
          </Text>

          <p>
            1.7 “Process” or “Processing” means any operation or set of
            operations which is performed upon Personal Data, whether or not by
            automatic means, such as collection, recording, organization,
            storage, adaptation or alteration, retrieval, consultation, use,
            disclosure by transmission, dissemination or otherwise making
            available, alignment or combination, blocking, erasure or
            destruction.
          </p>

          <Text my="20px">
            1.8 “Security Breach” means a confirmed breach of Processor’s
            security measures leading to the accidental or unlawful destruction,
            loss, alteration, unauthorized disclosure of, or access to Personal
            Data where such compromise of the Personal Data meets the
            definitions of both “personal data” (or like term) and “security
            breach” (or like term) under Data Protection Law(s) governing the
            particular circumstances.
          </Text>

          <p>
            1.9 “Standard Contractual Clauses” means the model clauses for the
            transfer of Personal Data to processors established in third
            countries approved by the European Commission, the approved version
            of which is set out in the European Commission ImplementingDecision
            (EU) 2021/914 of 4 June 2021 and at{" "}
            <a
              className="anchor"
              href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?uri=CELEX%3A32021D0914&locale=e"
            >
              https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?uri=CELEX%3A32021D0914&locale=e
            </a>
            , which clauses are incorporated herein by this reference.
          </p>

          <Text my="20px">
            1.10 Any definitions not otherwise included in this Data Processing
            Addendum, and found in the Privacy Notice, End User License
            Agreement, and Terms of Use are hereby adopted by reference in this
            Addendum.
          </Text>

          <Heading
            my="10px"
            color={"primary"}
            as="h2"
            fontSize={["20px"]}
            fontWeight="bold"
          >
            2.0 Processing and Transfer of Personal Data.
          </Heading>
          <p>
            2.1 Processor shall process Personal Data in accordance with
            Customer’s written instructions (unless waived in a written
            requirement) provided during the term of this DPA. The parties agree
            that the Agreement, including this DPA, together with Customer’s use
            of the Processor’s service in accordance with the Agreement,
            constitute Customer’s complete and final written instruction to
            Processor in relation to the Processing of Personal Data, and
            additional instructions outside the scope of these instructions
            shall require a prior written and mutually executed agreement
            between Customer and Processor. In the event Processor reasonably
            believes there is a conflict with any Data Protection Law and
            Customer’s instructions, Processor will inform Customer promptly and
            the parties shall cooperate in good faith to resolve the conflict
            and achieve the goals of such instruction.
          </p>
          <Text my="20px">
            2.2 Except for usage of Personal Data as necessary to bring and
            defend claims, to comply with requirements of the legal process, to
            cooperate with regulatory authorities, and to exercise other similar
            permissible uses as expressly provided under Data Protection Laws,
            Processor shall not retain, use, sell, or disclose the Personal Data
            that is not de-identified or aggregated for analytics, for any
            purpose, including other commercial purposes, outside of the direct
            business relationship with Customer.
          </Text>
          <p>
            2.3 The parties acknowledge and agree that processing of the
            Personal Data will occur in the United States and perhaps other
            jurisdictions outside the residence of the data subjects, and
            Customer shall comply with all notice and consent requirements for
            such transfer and processing to the extent required by Data
            Protection Laws.
          </p>
          <Heading
            my="10px"
            color={"primary"}
            as="h2"
            fontSize={["20px"]}
            fontWeight="bold"
          >
            3.0 EU Data Protection Laws.
          </Heading>
          <p>
            3.1 Transfers of EU Personal Data. Customer acknowledges and agrees
            that Processor is located in the United States and that Customer’s
            provision of Personal Data from the European Economic Area,
            Switzerland, and the United Kingdom (“EU”) to Processor for
            processing is a transfer of EU Personal Data to the United States.
            All transfers of Customer Personal Data out of the EU (“EU Personal
            Data”) to the United States shall be governed by the Standard
            Contractual Clauses. The terms of the Standard Contractual Clauses,
            together with Appendices 1 and 2 set out in Exhibit A to this DPA,
            are incorporated in this DPA by this reference solely as required
            with respect to EU Personal Data for the services provided by
            Processor for Customer under the Agreement.
          </p>
          <Text>
            3.2 GDPR Contractual Requirements. Processor shall: (a) assist, to a
            reasonable extent, the fulfillment of Customer’s obligations to
            respond to requests for exercising a data subject’s rights with
            respect to Personal Data under Chapter III of GDPR; (b) assist, to a
            reasonable extent, Customer in complying with its obligations with
            respect to EU Personal Data pursuant to Articles 32 to 36 of GDPR;
            (c) make available to Customer information reasonably necessary to
            demonstrate compliance with its obligations as a processor specified
            in Article 28 of GDPR; (d) maintain a record of all categories of
            processing activities carried out on behalf of Customer in
            accordance with Article 30(2) of the GDPR; and (e) cooperate, on
            request, with an EU supervisory authority in the performance of the
            services under the Agreement.
          </Text>

          <Heading
            my="10px"
            color={"primary"}
            as="h2"
            fontSize={["20px"]}
            fontWeight="bold"
          >
            4.0 Compliance with Data Protection Laws.
          </Heading>
          <p>
            4.1 Representation and Warranty. Customer represents and warrants on
            behalf of itself and its employees that the Personal Data provided
            to Processor for processing under the Agreement and this DPA is
            collected and/or validly obtained and utilized by Customer and its
            employees in compliance with all Data Protection Laws, including
            without limitation the disclosure, informed affirmative consent and
            targeted advertising provisions of the CCPA and EU Data Protection
            Laws, including without limitation Chapter II of the GDPR, and
            Customer shall defend, indemnify and hold harmless Processor from
            and against all loss, expense (including reasonable out-of-pocket
            attorneys’ fees and court costs), damage, or liability arising out
            of any claim arising out of a breach of this Section 4.1.
          </p>
          <Text my="20px">
            4.2 Data Security. Processor will utilize commercially reasonable
            efforts to protect the security, confidentiality, and integrity of
            the Personal Data transferred to it using reasonable administrative,
            physical, and technical safeguards. Notwithstanding the generality
            of the foregoing, Processor shall: (a) not use or disclose Personal
            Data for any purpose other than those purposes instructed or
            permitted by Customer; (b) only use and disclose Personal Data in a
            manner and to the extent permitted in this DPA or as otherwise
            agreed between the Parties and observe all limitations as to such
            use or disclosure as Customer may notify to Processor; (c) employ
            reasonable administrative, physical, and technical safeguards
            (including commercially reasonable safeguards against worms, Trojan
            horses, and other disabling or damaging codes) to afford protection
            of the Personal Data in accordance with Data Protection Laws as
            would be appropriate based on the nature of the Personal Data; (d)
            utilize commercially reasonable efforts to keep the Personal Data
            reasonably secure and in an encrypted form, and use industry
            standard security practices and systems applicable to the use of
            Personal Data to prevent, and take prompt and proper remedial action
            against unauthorized access, copying, modification, storage,
            reproduction, display, or distribution of Personal Data; (e) cease
            to retain documents containing Personal Data, or remove the means by
            which Personal Data can be associated with particular individuals
            reasonably promptly after it is reasonable to assume that (i) the
            specified purposes are no longer being served by Processor’s
            retention of Personal Data, and (ii) retention is no longer
            necessary for legal or business purposes; and (f) upon receiving a
            request from Customer to correct an error or omission in the
            Personal Data about the individual that is in the possession or
            under the control of Processor, correct the Personal Data as soon as
            reasonably practicable.
          </Text>
          <p>
            4.3 Authorized Personnel; Sub-processors. Processor shall ensure
            that Authorized Personnel have committed themselves to
            confidentiality or are under an appropriate statutory obligation of
            confidentiality with obligations at least as restrictive as those
            contained in this DPA. In addition, Processor is authorized to use
            sub-processors provided that Processor shall enter into an agreement
            with the sub-processor containing data protection obligations that
            are at least as restrictive as the obligations under this DPA.
          </p>
          <Text>
            4.4 Security Breaches. After confirmation of a Security Breach,
            Processor will promptly, without undue delay: (a) notify Customer of
            the Security Breach; (b) investigate the Security Breach; (c)
            provide Customer with details about the Security Breach; and (d)
            take reasonable actions to prevent a recurrence of the Security
            Breach. Processor agrees to cooperate in Customer’s handling of the
            matter by: (i) providing reasonable assistance with Customer’s
            investigation; and (ii) making available relevant records, logs,
            files, data reporting, and other materials related to the Security
            Breach’s effects on Customer, as required to comply with Data
            Protection Laws. 4.5 Data Subject Requests. Processor will cooperate
            with Customer to address data subject rights and requests afforded
            by Data Protection Laws.
          </Text>
          <p>
            4.5 Data Subject Requests. Processor will cooperate with Customer to
            address data subject rights and requests afforded by Data Protection
            Laws.
          </p>
          <Heading
            my="10px"
            color={"primary"}
            as="h2"
            fontSize={["20px"]}
            fontWeight="bold"
          >
            5.0 Audits and Certifications.
          </Heading>
          <p>
            5.1 Within thirty (30) days of Customer’s written request, and no
            more than once annually and subject to the confidentiality
            obligations set forth in the Agreement (unless such information is
            reasonably required to be disclosed as a response to a data
            subject’s inquiries under Data Protection Laws), Processor shall
            make available to Customer (or a mutually agreed upon third-party
            auditor) information regarding Processor’s compliance with the
            obligations set forth in this DPA, including reasonable
            documentation. An NDA may be required to receive this information.
          </p>
          <Heading
            my="10px"
            color={"primary"}
            as="h2"
            fontSize={["20px"]}
            fontWeight="bold"
          >
            6.0 Miscellaneous.
          </Heading>
          <p>
            6.1 In the event of any conflict or inconsistency between this DPA
            and Data Protection Laws, Data Protection Laws shall prevail. In the
            event of any conflict or inconsistency between the terms of this DPA
            and the terms of the Agreement, the terms of this DPA shall prevail
            solely to the extent that the subject matter concerns the processing
            of Personal Data.
          </p>
          <Text my="20px">
            6.2 To the extent that it is determined by any data protection
            authority that the Agreement or this DPA is insufficient to comply
            with Data Protection Laws or changes to Data Protection Laws,
            Customer and Processor agree to cooperate in good faith to amend the
            Agreement or this DPA or enter into further mutually agreeable data
            processing agreements in an effort to comply with all Data
            Protection Laws.
          </Text>
          <p>
            6.3 Each Party’s liability arising out of or related to this DPA,
            whether in contract, tort or under any other theory of liability, is
            subject to the limitations of liability contained in the Agreement.
            For the avoidance of doubt, each reference herein to the “DPA” means
            this DPA including its exhibits and appendices.
          </p>
          <Text>
            6.4 This DPA is without prejudice to the rights and obligations of
            the parties under the Agreement which shall continue to have full
            force and effect. This DPA does not confer any third-party
            beneficiary rights, is intended for the benefit of the parties
            hereto and their respective permitted successors and assigns only,
            and is not for the benefit of, nor may any provision hereof be
            enforced by, any other person. This DPA only applies to the extent
            Processor processes Personal Data on behalf of Customer. This DPA
            together with the Agreement is the final, complete and exclusive
            agreement of the Parties with respect to the subject matter hereof
            and supersedes and merges all prior discussions and agreements
            between the parties with respect to such subject matter.
          </Text>

          <p>
            Processor will maintain reasonable administrative, physical, and
            technical safeguards for protection of the security,
            confidentiality, and integrity of personal data transferred to
            Processor as described in the Agreement and this DPA.
          </p>

          <Text my="20px">
            Processor will maintain reasonable administrative, physical, and
            technical safeguards for protection of the security, confidentiality
            and integrity of Personal Data transferred to Processor as described
            in the Privacy Notice which is available at{" "}
            <a className="anchor" href="https://fixmeet.app/privacy">
              https://fixmeet.app/privacy
            </a>
          </Text>
        </div>
      </Box>
      <Footer />
    </Box>
  );
};
