import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Flex,
  Button,
  Text,
  Divider,
  Circle,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import { BsArrowLeft } from "react-icons/bs";
import CustomCalendar from "./components/CustomCalendar";
import { format, getDate, parse, isSameDay, isSameMonth } from "date-fns";
import { SingleTimeTab } from "./components/SingleTimeTab";
import { MultipleTimeTab } from "./components/MultipleTimeTab";
import { selectMultipleDates, selectSingleDates } from "./services/selectDates";
import { saveCreatePollData } from "../../features/poll";
import { useDispatch, useSelector } from "react-redux";
import { errorNotifier } from "../../components/NotificationHandler";
import dayjs from "dayjs";
import { nanoid } from "@reduxjs/toolkit";

const SelectMeetingDate = () => {
  const pcode = sessionStorage.getItem("pcode");
  // const endDate = useSelector((state) => state.polls.createPollData.end_date);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedDuration, setSelectedDuration] = useState("");
  const [, setEndTime] = useState("");
  const [actionNot, setActionNot] = useState(false);
  const [times, setTimes] = useState([
    { id: nanoid(), start: "01:00", end: "00:00", plus: true },
  ]);
  const [dividedTimes, setDividedTimes] = useState("");
  const [mode, setMode] = useState(true);
  const toast = useToast();
  const navigate = useNavigate();

  const handleDuration = (e) => {
    const value = e.target.value;
    setSelectedDuration(value);
  };
  const pollDuration = useSelector(
    (state) => state.polls.createPollData?.poll_duration
  );

  function timeConvert(n) {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    if (rhours === 0) {
      return rminutes + " minutes";
    } else if (rminutes === 0) {
      return rhours + " hour(s)";
    } else {
      return rhours + " hour(s) and " + rminutes + " minutes";
    }
  }

  const pollDivide =
    pollDuration > 59 && pollDuration % 60 !== 0
      ? `${Math.floor(pollDuration / 60)} hrs : ${pollDuration % 60} mins`
      : `${pollDuration <= 59}`
      ? `${pollDuration} mins`
      : `${pollDuration > 59}`
      ? `${pollDuration / 60} hr`
      : "";
  // console.log("selected", selectedDates)
  const handleDateClick = (date) => {
    const newDate = selectedDates.filter(
      (selectedDate) => selectedDate === format(date, "dd-MM-yyyy")
    );
    if (newDate.length !== 0) {
      return setSelectedDates(
        selectedDates.filter((selectedDate) => selectedDate !== newDate[0])
      );
    }
    if (
      dayjs(date).format("YYYY-MM-DD") >= dayjs(Date.now()).format("YYYY-MM-DD")
      // &&
      // dayjs(date).format("YYYY-MM-DD") <= dayjs(endDate).format("YYYY-MM-DD")
    )
      return setSelectedDates([...selectedDates, format(date, "dd-MM-yyyy")]);

    // if (dayjs(date).format("YYYY-MM-DD") >= dayjs(endDate).format("YYYY-MM-DD"))
    //   errorNotifier(
    //     `Please select a date less than the poll closing date, ${dayjs(
    //       endDate
    //     ).format("MMM")} ${dayjs(endDate).format("DD")} ${dayjs(endDate).format(
    //       "YYYY"
    //     )}`
    //   );
    if (
      dayjs(date).format("YYYY-MM-DD") < dayjs(Date.now()).format("YYYY-MM-DD")
    )
      errorNotifier(`You cant choose a date that is passed`);
  };
  // console.log("seleceted", selectedDates);
  const handleMultipleDateClick = (date) => {
    const dateFormat = {
      date: format(date, "dd-MM-yyyy"),
      times: [
        {
          id: nanoid(),
          start: "00:00",
          end: "00:00",
        },
      ],
    };
    const sameMonth = selectedDates.some((selectedDate) =>
      isSameMonth(parse(selectedDate.date, "dd-MM-yyyy", new Date()), date)
    );
    const sameDay = selectedDates.some((selectedDate) =>
      isSameDay(parse(selectedDate.date, "dd-MM-yyyy", new Date()), date)
    );

    if (sameDay && sameMonth) {
      const filterDates = selectedDates.filter(
        (selectedDate) => format(date, "dd-MM-yyy") !== selectedDate.date
      );
      return setSelectedDates(filterDates);
    }

    setSelectedDates([...selectedDates, dateFormat]);
  };

  const customRender = (date) => {
    const newDate = selectedDates.some(
      (selectedDate) => selectedDate === format(date, "dd-MM-yyyy")
    );

    return newDate ? (
      <Circle bg="primary" color="white" size="4ch" p={2}>
        {getDate(date)}
      </Circle>
    ) : (
      <span>{getDate(date)}</span>
    );
  };
  const customRenderMultiple = (date) => {
    const newDate = selectedDates.some(
      (selectedDate) => selectedDate.date === format(date, "dd-MM-yyyy")
    );
    return newDate ? (
      <Circle bg="primary" color="white" size="4ch" p={2}>
        {getDate(date)}
      </Circle>
    ) : (
      <span>{getDate(date)}</span>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    if (selectedDates.length === 0) {
      toast({
        title: "Please select your meeting dates",
        description: "Click on your preferred date to select",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      });

      return;
    }
    let formData = { pcode };
    if (selectedDates[0]?.date) {
      formData = { ...formData, data: selectedDates };
      if (dividedTimes === 0) {
        selectMultipleDates(formData)
          .then((data) => {
            if (data) {
              navigate("/poll-summary");
            }
          })
          .then(() => {
            dispatch(
              saveCreatePollData({
                title: "",
                description: "",
                poll_duration: "",
                endDate: "",
                hours: "",
                minutes: "",
              })
            );
          })
          .catch((e) => {
            if (e?.response) {
            } else {
              errorNotifier("Please Check Your network and try again");
            }
            // console.log(e);
            setLoading(false);
          });
      } else {
        errorNotifier(
          "The total time selected cannot be split by the poll duration"
        );
      }
    } else {
      // console.log("single");
      // setLoading(false);
      formData = {
        ...formData,
        dates: selectedDates,
        times,
      };
      if (dividedTimes === 0) {
        selectSingleDates(formData)
          .then((data) => {
            if (data) {
              navigate("/poll-summary");
            }
            // console.log("single", data);
          })
          .then(() => {
            dispatch(
              saveCreatePollData({
                title: "",
                description: "",
                poll_duration: "",
                endDate: "",
                hours: "",
                minutes: "",
              })
            );
          })
          .catch((e) => {
            if (e?.response) {
            } else {
              errorNotifier("Please Check Your network and try again");
            }
            // console.log(e);
            setLoading(false);
          });
      } else {
        setLoading(false);
        errorNotifier("The total time cannot be split by the poll duration");
      }
    }
  };

  return (
    <Flex width="100%" justify="center" py={[3, 3, 4, 4]}>
      <Flex
        w={["90%", "90%", "90%", "70%"]}
        direction="column"
        borderRadius="lg"
      >
        <Flex
          direction={["column", "column", "row", "row"]}
          gridGap={[4, 4, 0, 0]}
        >
          <Flex direction="column" flexBasis="55%" bg="white" px={4} pb={6}>
            <Text
              fontSize={["1rem", "1rem", "1.5rem", "1.5rem"]}
              textAlign={["center", "center", null, null]}
              color="#707070"
              mt={[0, 0, 2, 4]}
            >
              Select proposed dates <br />
              for your meeting
            </Text>
            <CustomCalendar
              textAlign="center"
              monthPadding="0"
              fontWeight="600"
              todayStyles={{
                content: `""`,
                position: "absolute",
                bottom: "4px",
                transform: "translate(-50%, 2%)",
                width: "4ch",
                height: "4ch",
                borderRadius: "50%",
                background: "#F3E6FF",
                left: "50%",
                top: "1px",
                zIndex: -1,
              }}
              handleChange={mode ? handleDateClick : handleMultipleDateClick}
              customRender={mode ? customRender : customRenderMultiple}
            />
          </Flex>
          <Divider orientation="vertical" />
          <Flex flexBasis="45%" direction="column">
            {mode ? (
              <SingleTimeTab
                times={times}
                setTimes={setTimes}
                dividedTimes={dividedTimes}
                setDividedTimes={setDividedTimes}
                setEndTime={setEndTime}
                actionNot={actionNot}
                pollDivide={pollDivide}
                setActionNot={setActionNot}
                setSelectedDuration={setSelectedDuration}
                selectedDuration={selectedDuration}
                handleDuration={handleDuration}
                timeConvert={timeConvert}
              />
            ) : (
              <MultipleTimeTab
                selectedDates={selectedDates}
                setSelectedDates={setSelectedDates}
                times={times}
                setTimes={setTimes}
                dividedTimes={dividedTimes}
                setDividedTimes={setDividedTimes}
                setEndTime={setEndTime}
                actionNot={actionNot}
                pollDivide={pollDivide}
                setActionNot={setActionNot}
                setSelectedDuration={setSelectedDuration}
                selectedDuration={selectedDuration}
                handleDuration={handleDuration}
                timeConvert={timeConvert}
              />
            )}
            <Flex
              color="#757575"
              bg="#F8F8F8"
              h="10%"
              p={2}
              my={10}
              mx={4}
              justify="center"
              align="center"
            >
              <Button
                bg="transparent"
                _hover={{ bg: "transparent" }}
                color="inherit"
                onClick={() => {
                  setMode(!mode);
                  setSelectedDates([]);
                }}
              >
                {mode
                  ? "Need different time for each day?"
                  : "Need same time for each day?"}
              </Button>
            </Flex>
          </Flex>
        </Flex>
        <Divider w="60%" />
        <Flex justify="flex-end" mt={4}>
          <Link to="/create-poll">
            <Button
              mr={2}
              leftIcon={<BsArrowLeft />}
              bg="transparent"
              border="2px solid"
              borderColor="primary"
              _hover={{ bg: "transparent" }}
              color="primary"
            >
              Back
            </Button>
          </Link>
          <Tooltip
            label={
              selectedDates.length < 1
                ? "Please select prefered date(s)"
                : actionNot
                ? `Start date should be less than close date by at least ${timeConvert(
                    pollDuration
                  )}`
                : ""
            }
            isOpen={selectedDates.length < 1 || actionNot}
            placement={"top"}
            hasArrow
          >
            <Button
              bg="primary"
              color="white"
              isDisabled={
                selectedDates.length < 1 || actionNot === true
                // dividedTimes !== 0
              }
              _hover={{ bg: "primary" }}
              onClick={handleSubmit}
              isLoading={loading}
              // isDisabled={time.start > time.end}
            >
              Continue
            </Button>
          </Tooltip>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SelectMeetingDate;
