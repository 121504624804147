import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import http from "../../../services/api";
import { AUTH_ROUTES } from "../../../services/routes.constants";

export const UpdateProfile = async (payload, setLoading) => {
  try {
    const response = await http.post(AUTH_ROUTES.UPDATE_ACCOUNT, payload);
    // console.log("profile", response);
    setLoading(false);
    response?.data?.status === "success"
      ? successNotifier(response?.data?.message)
      : errorNotifier(response?.data?.message);
    return response;
  } catch (e) {
    setLoading(false);
    // errorNotifier(e.response?.data?.message);
  }
};

export const UpdatePin = async (payload, setLoading) => {
  try {
    const response = await http.post(AUTH_ROUTES.UPDATE_PIN, payload);
    // console.log("profile", response);
    setLoading(false);
    response?.data?.status === "success"
      ? successNotifier(response?.data?.message)
      : errorNotifier(response?.data?.message);
    return response;
  } catch (e) {
    setLoading(false);

    // errorNotifier(e.response?.data?.message);
  }
};
