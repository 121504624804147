import { Tab } from "@chakra-ui/react";

const ContactsAndMeetingsTab = ({ children, clickText }) => {
  return (
    <Tab
      flexBasis="50%"
      outline="none !important"
      _focus={{ boxShadow: "none" }}
      px={4}
      py={[1, 1, 2]}
      color="#c99ef3"
      fontWeight="bold"
      borderBottom="4px solid #6c4097"
      // bg="rgb(247 239 255 / 30%)"
      _selected={{
        color: "primary",
        borderBottom: "4px solid #6c4097"
        // bg: "rgb(247 239 255 / 87%)",
      }}
      onClick={clickText}
    >
      {children}
    </Tab>
  );
};

export default ContactsAndMeetingsTab;
