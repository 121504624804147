import dayjs from "dayjs";

//  var time1 = "01:00:00";
export const timeRange = (time, pollDuration, setEndTime) => {
  //  var time2 = "00:00:00";
  var hour = 0;
  var minute = 0;
  //  var second = 0;

  var splitTime1 = pollDuration.split(":");
  var splitTime2 = time.split(":");

  hour = parseInt(splitTime1[0]) + parseInt(splitTime2[0]);
  minute = parseInt(splitTime1[1]) + parseInt(splitTime2[1]);
  //  hour = hour + minute / 60;
  minute = minute % 60;
  //  second = parseInt(splitTime1[2]) + parseInt(splitTime2[2]);
  //  minute = minute + second / 60;
  // second = second % 60;
  const newTime = hour + ":" + minute;
  setEndTime(newTime);
  return newTime;
};

export function generateStopTime(step, setAllTimes, hour, mins, startTime = 0) {
  const dt = new Date(1970, 0, 1, hour, mins, startTime);
  const times = [];
  while (dt.getDate() === 1) {
    const time = dt.toTimeString("en-US").substring(0, 8);
    const startTime = time?.split(":");
    const startHour = startTime[0] * 60;
    const startMins = startTime[1];
    const totalStartMins = parseInt(startHour) + parseInt(startMins);
    const duration = 60 * 60 * 1000;
    const total = totalStartMins * 60 * 1000 + duration;
    times.push(dt.toTimeString("en-US").substring(0, 8));
    // times.push(dt.toLocaleTimeString("en-US"));
    dt.setMinutes(dt.getMinutes() + step);
  }
  setAllTimes(times);
  return times;
}

export function generateStartTime(step, setAllTimes, startTime) {
  const dt = new Date(1970, 0, 1, startTime);
  const times = [];
  while (dt.getDate() === 1) {
    times.push(dt.toTimeString("en-US").substring(0, 8));
    dt.setMinutes(dt.getMinutes() + step);
  }
  setAllTimes(times);
  return times;
}
