import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Flex, Text, Box, Button, Textarea, Divider } from "@chakra-ui/react";
import { BsCalendar2Date, BsCalendarEvent } from "react-icons/bs";
import { BiMenuAltLeft } from "react-icons/bi";
import { MdPerson } from "react-icons/md";
import { AiFillStar } from "react-icons/ai";
import { GiTimeBomb } from "react-icons/gi";
import { IoTimeOutline } from "react-icons/io5";
import dayjs from "dayjs";
import { sendFinalDate } from "./service/sendFinalDate";
import { timeago } from "../votingScreen/utils/timeAgo";
import "./final.css";
import { closingDate } from "../../components/DateFormat";
import FullPageLoader from "../../components/full-page-loader";
import { useEffect } from "react";

const Vote = ({
  id,
  select,
  setSelect,
  setDescription,
  setSelectedMembers,
  date,
  start,
  end,
  votes,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  //Formatting the date to a valid date format, from "dd-mm-yyyy" to "yyyy-mm-dd"
  const splittedDate = date?.split("-");
  const Selecteddate = splittedDate[2];
  const month = splittedDate[1];
  const year = splittedDate[0];
  const validDate = year + "-" + month + "-" + Selecteddate;
  const weekDay = dayjs(validDate).format("ddd");
  const shortMonth = dayjs(validDate).format("MMM");
  const setTimezone = useSelector((state) => state?.user?.timezone);
  const localTimeZone = useSelector((state) => state?.user?.localTimeZone);
  const timezone = setTimezone || localTimeZone;
  const timezoneLabel = useSelector(
    (state) => state?.polls?.poll?.poll_description?.timezone_label
  );
  // console.log(closingDate(timezone, date, end).startEndTime);

  const handleChange = (id) => {
    setIsChecked(!isChecked);
    // const startTime = start?.slice(0, 5);
    // const endTime = end?.slice(0, 5);
    setSelect(id);
    setSelectedMembers(select);
    setDescription(
      `The meeting will be held on ${weekDay} ${Selecteddate} ${shortMonth}, ${year}  by ${
        closingDate(timezone, date, start).startEndTime
      } to ${closingDate(timezone, date, end).startEndTime} ${timezoneLabel}`
    );
  };

  return (
    <Flex direction="column" align="stretch" p={2}>
      <Flex
        direction="column"
        justify="center"
        textAlign="center"
        bg="white"
        py={2}
        minWidth={["4.5rem", "6rem"]}
        mb={1}
      >
        <Text fontSize="sm" color="#D2D2D2" fontWeight="bold">
          {closingDate(timezone, date, start).day}
        </Text>
        <Text fontSize={["1rem", "1.2rem", "2rem"]} fontWeight="bold">
          {closingDate(timezone, date, start).date}
        </Text>
        <Text fontSize=".6rem" color="#A8A5A5" letterSpacing="1.3px">
          {closingDate(timezone, date, start).startEndTime}
          {console.log(closingDate(timezone, date, start), ":::::::", start)}
        </Text>
        <Text fontSize=".6rem" color="#A8A5A5" letterSpacing="1.3px">
          {closingDate(timezone, date, end).startEndTime}
          {console.log(end, "ENDNDND")}
        </Text>
      </Flex>
      <Flex justify="center" align="center" bg="white" py={1} mb={2}>
        <MdPerson size="1.2rem" />
        <Text ml={2}>{votes?.length}</Text>
      </Flex>
      <Flex justify="center">
        <label htmlFor={id} className="container">
          <input
            onChange={() => handleChange(id)}
            type="radio"
            // color="red"
            id={id}
            name="radio"
            w="100px"
          />
          <span class="checkmark"></span>
        </label>
      </Flex>
    </Flex>
  );
};

const FinalDates = () => {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const data = useSelector((state) => state?.polls?.poll);
  const times = useSelector((state) => state?.polls?.pollTimes);
  const [description, setDescription] = useState("");
  const [select, setSelect] = useState("");
  const navigate = useNavigate();

  const pcode = data?.poll_description?.pcode;

  const { poll_description = [], top_vote } = data;
  const topVotes = top_vote;
  const setTimezone = useSelector((state) => state?.user?.timezone);
  const localTimeZone = useSelector((state) => state?.user?.localTimeZone);
  const timezone = setTimezone || localTimeZone;

  const splitDate =
    poll_description?.end_date && poll_description?.end_date.split(" ");
  const endDate = splitDate[0];
  const [selectedMembers, setSelectedMembers] = useState("");
  const handleSubmit = () => {
    const payload = { description, pcode, time_id: select };
    setLoading(true);
    sendFinalDate(payload, setLoading)
      .then((data) => (data?.status === 200 ? navigate("/completed") : ""))
      .then(() => setLoading(false))
      .catch((e) => setLoading(false));
  };

  useEffect(() => {
    setPageLoading(false);
  }, []);

  return pageLoading ? (
    <FullPageLoader />
  ) : (
    <>
      <Flex w="100%" color="#707070" direction="column" align="center" pb={4}>
        <Text as="h2" fontSize={["0.9rem", "1rem", "1.4rem"]} mt="20px">
          Choose the final date for the meeting
        </Text>
        <Flex
          w={["100%", "100%", "55%"]}
          p={4}
          direction={["column", "row"]}
          gridGap={4}
        >
          <Flex flexBasis="80%" direction="column">
            <Flex sx={{ "& > *": { lineHeight: "normal" } }}>
              <Flex direction="column">
                <Text
                  color="primary"
                  fontSize={["1rem", "1.5rem", "2rem", "2.2rem"]}
                  fontWeight={"semibold"}
                >
                  {poll_description?.poll_name}
                </Text>
                <Flex>
                  <Text
                    fontStyle="italic"
                    fontSize="0.9rem"
                    textTransform={"capitalize"}
                    mb="5px"
                  >
                    By {poll_description?.user?.name || "--"}
                    {","}
                  </Text>
                  <Text ml="6px">{timeago(poll_description.created_at)}</Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex sx={{ "& > *": { lineHeight: "normal" } }} width="85%">
              <Box mr={2}>
                <BiMenuAltLeft size={26} />
              </Box>
              <Flex direction="column">
                <Text fontSize="0.9rem" mb="5px">
                  {poll_description?.poll_description}
                </Text>
              </Flex>
            </Flex>
            <Flex sx={{ "& > *": { lineHeight: "normal" } }} width="80%">
              <Box mr={4}>
                <BsCalendarEvent />
              </Box>
              <Flex direction={["row"]}>
                <Text fontSize={["0.9rem", "1rem"]}>
                  {closingDate(timezone, endDate, "11:59:00 PM").month}{" "}
                  {closingDate(timezone, endDate, "11:59:00 PM").date}{" "}
                  {closingDate(timezone, endDate, "11:59:00 PM").year}
                </Text>
                <Flex ml="20px">
                  <IoTimeOutline />
                  <Text fontStyle="italic" fontSize="1rem" ml="5px">
                    {poll_description?.poll_duration % 60 !== 0
                      ? (poll_description?.poll_duration % 60) + " minutes"
                      : poll_description?.poll_duration / 60 +
                        `${
                          poll_description?.poll_duration / 60 > 1
                            ? " hours"
                            : " hour"
                        }`}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Divider display={["block", "none"]} />
          <Flex
            direction="column"
            flexBasis="50%"
            mt={["0px", "10px"]}
            px={["3%", 0]}
          >
            <Text color="primary">Top Vote</Text>
            <Flex direction={"column"} fontSize={"14px"}>
              <Flex alignItems={"center"} w="60px">
                <AiFillStar size="18px" color={"gold"} />
                <Text>
                  <Box as="span" fontWeight={"bold"}>
                    Yes:
                  </Box>{" "}
                  {top_vote[0]?.yes_count || "--"}
                </Text>
              </Flex>
              <Flex alignItems={"center"}>
                <BsCalendar2Date />
                <Text>
                  {" "}
                  <Box ml="5px" as="span" fontWeight={"bold"}>
                    Date:
                  </Box>{" "}
                  {topVotes.length > 0
                    ? closingDate(timezone, endDate, "11:59:00 PM").month
                    : ""}{" "}
                  {topVotes.length > 0
                    ? closingDate(timezone, endDate, "11:59:00 PM").date
                    : ""}{" "}
                  {topVotes.length > 0
                    ? closingDate(timezone, endDate, "11:59:00 PM").year
                    : ""}
                  {/* {dayjs(validDate).format("ddd")} {selectedDay}{" "}
                  {dayjs(validDate).format("MMM")}, {selectedYear} */}
                </Text>
              </Flex>
              <Flex alignItems={"center"}>
                <GiTimeBomb />
                <Text ml="5px">
                  <Box as="span" fontWeight={"bold"}>
                    Time:
                  </Box>{" "}
                  {topVotes.length > 0
                    ? closingDate(
                        timezone,
                        topVotes[0]?.date,
                        topVotes[0]?.start
                      ).startEndTime
                    : ""}{" "}
                  -{" "}
                  {topVotes.length > 0
                    ? closingDate(timezone, topVotes[0]?.date, topVotes[0]?.end)
                        .startEndTime
                    : ""}
                  {/* {top_vote[0]?.start} - {top_vote[0]?.end} */}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Divider />
        <Flex p={4} w={["100%", "100%", "60%"]} justifyContent={"center"}>
          <Flex
            px={[2, 4, 6, 10]}
            py={2}
            mt={[6, null, null, null]}
            bg="#F8F8F8"
            overflowX="scroll"
            sx={{
              "&::-webkit-scrollbar": {
                height: "15px",
                "&-track": {
                  backgroundColor: "#F9F9F9",
                  borderRadius: " 100px",
                },
                "&-thumb": {
                  border: "5px solid transparent",
                  borderRadius: "100px",
                  backgroundColor: "#e4e4e4",
                  backgroundClip: "content-box",
                },
              },
            }}
          >
            {times &&
              times?.map((item) => {
                return (
                  <Vote
                    key={item?.id}
                    selectedMembers={selectedMembers}
                    setSelectedMembers={setSelectedMembers}
                    setDescription={setDescription}
                    date={item?.date}
                    start={item?.start}
                    end={item?.end}
                    votes={item?.votes}
                    select={select}
                    setSelect={setSelect}
                    id={item?.id}
                  />
                );
              })}
          </Flex>
        </Flex>
      </Flex>
      <Flex
        justifyContent="center"
        mb={2}
        alignItems={"center"}
        gridGap="1rem"
        w="100%"
        direction={"column"}
      >
        <Box w={["80%", "80%", "40%"]}>
          <Text alignSelf={"left"}>Description</Text>
          <Textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Box>

        <Button
          bg="primary"
          color="white"
          w={["80%", "300px"]}
          _hove={{ bg: "primary" }}
          onClick={handleSubmit}
          isLoading={loading}
        >
          Submit
        </Button>
      </Flex>
    </>
  );
};

export default FinalDates;
