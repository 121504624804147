import dayjs from "dayjs";

export const formatTime = (date) => {
  const newDate = new Date(date || Date.now());
  const time =
    ("0" + newDate?.getUTCHours())?.slice(-2) +
    ":" +
    ("0" + newDate?.getUTCMinutes())?.slice(-2);
  return time;
};

export const formatDate = (date) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: "GMT",
  };
  const newDate = new Date(Date.parse(date) || Date.now());
  const formattedDate = newDate?.toLocaleString("en-US", options);
  return formattedDate;
};

export const formatFullDate = (date, timeZone) => {
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone,
    timeZoneName: "short",
  };
  const newDate = new Date(Date.parse(date) || Date.now());
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    newDate
  );

  return formattedDate;
};

const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const closingDate = (timeZone = localTimezone, dateAlone, timeAlone) => {
  // const options = {
  //   weekday: "short",
  //   year: "numeric",
  //   month: "short",
  //   day: "numeric",
  //   hour: "numeric",
  //   minute: "numeric",
  //   second: "numeric",
  //   timeZone,
  //   timeZoneName: "short",
  // };
  // const date =
  //   dateAlone &&
  //   timeAlone &&
  //   `${dateAlone}T${timeAlone?.split(" ")[0]}.000000Z`;
  // const s = dayjs(dateAlone || Date.now()).format("MMM D, YYYY");
  // const newDate = new Date(Date.parse(date) || Date.now());
  // const formattedDate =
  //   newDate && new Intl.DateTimeFormat("en-US", options).format(newDate);

  // const times = formattedDate?.split(" ");
  // const timesCommaFormatted = formattedDate?.split(",");

  // const formattedTime = times[4]?.split(":");
  // const newFormattedTime =
  //   formattedTime[0] + ":" + formattedTime[1] + " " + times[5];
  // const formatDateByComma = formattedDate?.split(",");
  // const splittedDate = formatDateByComma[1]?.split(" ");
  // const month = times[1];
  // const year = timesCommaFormatted[2];
  // const time = {
  //   day: times[0] + " " + times[1],
  //   date: splittedDate[2],
  //   startEndTime: newFormattedTime,
  //   month,
  //   year: year.trim(),
  //   // updatedDate: `${year,  month,  date}`,
  // };
  const getHr = timeAlone.split(":").slice(0, 2)?.[0];
  const getMin = timeAlone.split(":").slice(0, 2)?.[1];

  const myT = {
    day: dayjs(dateAlone || Date.now()).format("ddd, MMM"),
    date: dayjs(dateAlone || Date.now()).format("D"),
    month: dayjs(dateAlone || Date.now()).format("MMM"),
    year: dayjs(dateAlone || Date.now()).format("YYYY"),
    startEndTime: dayjs(dateAlone || Date.now())
      .hour(getHr)
      .minute(getMin)
      .format("h:mm A"),
  };
  return myT;
};
