import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Circle, Text } from "@chakra-ui/react";
import { BsCheckLg } from "react-icons/bs";

const Completed = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/dashboard");
    }, 2000);
  }, [navigate]);
  return (
    <Box h="calc(100vh - 12rem)" d="grid" placeContent="center" color="#707070">
      <Circle border="5px solid #707070" p={10}>
        <BsCheckLg size="9rem" />
      </Circle>
      <Text textAlign="center" fontSize="2.5rem" mt={3}>
        Completed
      </Text>
    </Box>
  );
};

export default Completed;
