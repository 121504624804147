import { useEffect, useState } from "react";
import { Flex, Text } from "@chakra-ui/react";
import HeadingCard from "./components/HeadingCard";
import MeetingsCard from "./components/MeetingsCard";
import ContactsAndMeetings from "./components/ContactsAndMeetings";
import FullPageLoader from "../../components/full-page-loader";
import { HowItWorks } from "./components/HowItWorksModal";
import { getContacts } from "./services/singleContact";
import { getMeetings } from "./services/getMeetings";
import { getGroups } from "./services/groupContact";
import { RiWifiOffLine } from "react-icons/ri";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [groups, setGroups] = useState([]);
  const [meetings, setMeetings] = useState([]);
  const [refresh, setRefresh] = useState({});
  const [error, setError] = useState();

  useEffect(() => {
    getMeetings(setLoading, setMeetings, setError);
    getContacts(setLoading, setContacts);
    getGroups(setLoading, setGroups);
  }, [refresh]);


  const updateCount = () => {
    let count;
    let contactCount = contacts?.length >= 1 ? 0 : 1;
    let groupCount = groups?.length >= 1 ? 0 : 1;
    let meetCount =
      meetings?.filter((meeting) => meeting?.status === "sent")?.length >= 1
        ? 0
        : 1;
    count = meetCount + groupCount + contactCount;
    return count;
  };

  if (meetings?.data?.message === "Not logged in, Log in to continue")
    localStorage.clear();

  return loading ? (
    <FullPageLoader />
  ) : (
    <>
      {error && (
        <Flex
        justifyContent={"center"}
          bg="transparent"
          color="gray"
          p="25px"
          alignItems={"center"}
          zIndex={10}
          // mt={["-20%", "-5%"]}
        >
          <RiWifiOffLine className="error-message" />
          <Text
            className="error-message"
            fontStyle={"italic"}
            fontSize={["14px", "18px"]}
            ml="20px"
          >
            Network error, please check your network connection and reload the
            page
          </Text>
        </Flex>
      )}

      <Flex p="20px" bg="#fafafa" wrap="wrap">
        <Flex
          direction="column"
          flexBasis={["100%", "100%", "100%", "60%"]}
          gridGap={4}
          mb={["20px", "20px", "20px", 0]}
        >
          <HeadingCard />
          <MeetingsCard meetings={meetings} />
        </Flex>

        <Flex
          flexBasis={["100%", "100%", "100%", "40%"]}
          px={4}
          borderRadius="10px"
          boxShadow="0px 6px 12px #00000005"
          minHeight="20rem"
          bg="#fff"
        >
          <Flex
            position={"fixed"}
            right={["30%", "10%"]}
            bottom={["0px", 10]}
            zIndex={10}
          >
            <HowItWorks
              setRefresh={setRefresh}
              contacts={contacts}
              groups={groups}
              meetings={meetings}
              updateCount={updateCount}
            />
          </Flex>
          <ContactsAndMeetings
            key={contacts}
            contacts={contacts}
            meetings={meetings}
            setContacts={setContacts}
            setMeetings={setMeetings}
            groups={groups}
            setRefresh={setRefresh}
          />
        </Flex>
      </Flex>
    </>
  );
};

export default Home;
