import { useState } from "react";
import {
  Flex,
  Circle,
  Text,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Portal,
  Spinner,
} from "@chakra-ui/react";
import { IoIosPeople } from "react-icons/io";
import { BsThreeDotsVertical } from "react-icons/bs";
import CustomModal from "../../../components/CustomModal";
import CautionAlertDialog from "../../../components/CautionAlertDialog";
import AddorDeleteGroupMemberForm from "./AddOrDeleteGroupMemberForm";
import {
  deleteGroup,
  getGroupMembers,
  getOtherContacts,
} from "../services/groupContact";
import EditGroupForm from "./EditGroupForm";

const ContactGroup = ({ group, setRefresh }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otherMembers, setOtherMembers] = useState([]);
  const [groupMembers, setGroupMembers] = useState([]);
  

  const handleDelete = async (id) => {
    await deleteGroup(id).then(() => setRefresh());
  };

  const getOtherMembers = (gId) => {
    setLoading(true);
    getOtherContacts(setLoading, setOtherMembers, gId);
  };

  const getSpecificGroupMembers = (gId) => {
    setLoading(true);
    getGroupMembers(setLoading, setGroupMembers, gId);
  };

  return (
    <Flex color="primary" align="center">
      <Flex>
        <Circle p={2} bg="#F9F3FF" mr={4} alignSelf="center">
          <IoIosPeople size="1.2rem" />
        </Circle>
        <Flex direction="column">
          <Text fontWeight="bold" textTransform={"capitalize"}>{group.group_name}</Text>
          <Text color="#A8A5A5">{group.group_description}</Text>
        </Flex>
      </Flex>
      <Box
        ml="auto"
        color="#A8A5A5"
        pos="relative"
        onClick={() => setShowOptions(!showOptions)}
      >
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<BsThreeDotsVertical color="#A8A5A5" />}
            bg="transparent"
            _hover={{ bg: "transparent" }}
          />
          <Portal>
            <MenuList>
              <MenuItem as={Box} onClick={() => getOtherMembers(group?.id)}>
                <CustomModal
                  buttonText="Add Members"
                  buttonProps={{
                    bg: "none",
                    _hover: { background: "none" },
                    flexBasis: "100%",
                    justifyContent: "flex-start",
                    color: "#A8A5A5",
                  }}
                  modalTitle={group?.group_name}
                >
                  {loading ? (
                    <Flex justifyContent="center">
                      <Spinner color="primary" alignSelf="center" />
                    </Flex>
                  ) : (
                    <AddorDeleteGroupMemberForm
                      contacts={otherMembers}
                      groupId={group?.id}
                      setRefresh={setRefresh}
                    />
                  )}
                </CustomModal>
              </MenuItem>
              <MenuItem
                as={Box}
                onClick={() => getSpecificGroupMembers(group?.id)}
              >
                <CustomModal
                  buttonText="Members"
                  buttonProps={{
                    bg: "none",
                    _hover: { background: "none" },
                    flexBasis: "100%",
                    justifyContent: "flex-start",
                    color: "#A8A5A5",
                  }}
                  modalTitle={group?.group_name}
                >
                  {loading ? (
                    <Flex justifyContent="center">
                      <Spinner color="primary" alignSelf="center" />
                    </Flex>
                  ) : (
                    <AddorDeleteGroupMemberForm
                      contacts={groupMembers}
                      groupId={group?.id}
                      setRefresh={setRefresh}
                      remove
                    />
                  )}
                </CustomModal>
              </MenuItem>
              <MenuItem as={Box}>
                <CustomModal
                  buttonText="Edit Group"
                  buttonProps={{
                    bg: "none",
                    _hover: { background: "none" },
                    flexBasis: "100%",
                    justifyContent: "flex-start",
                    color: "#A8A5A5",
                  }}
                  modalTitle={group?.group_name}
                >
                  <EditGroupForm
                    name={group?.group_name}
                    description={group?.group_description}
                    groupId={group?.id}
                    setRefresh={setRefresh}
                  />
                </CustomModal>
              </MenuItem>
              <MenuItem as={Box}>
                <CautionAlertDialog
                  buttonText="Delete"
                  buttonProps={{
                    bg: "none",
                    _hover: { background: "none" },
                    flexBasis: "100%",
                    justifyContent: "flex-start",
                    color: "#A8A5A5",
                  }}
                  cautionTitle="Are you sure you want to delete group?"
                  onContinue={() => handleDelete(group.id)}
                />
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </Box>
    </Flex>
  );
};

export default ContactGroup;
