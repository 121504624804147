import { useState, useRef } from "react";
import { Button, Flex, FormLabel, Input } from "@chakra-ui/react";
import { createContact } from "../services/singleContact";

const AddContactForm = ({ onClose, setRefresh }) => {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const formRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formRef.current.reportValidity()) return;
    setLoading(true);
      await createContact(formValues).then(()=>setRefresh([]));
      setLoading(false);
      onClose()
  };

  return (
    <Flex
      sx={{
        "& > form": {
          display: "flex",
          flexDirection: "column",
          width: "100%",
        },
      }}
    >
      <form ref={formRef}>
        <FormLabel>Name</FormLabel>
        <Input
          placeholder="Contact name"
          onChange={handleChange}
          mb={4}
          name="name"
          value={formValues.name}
          required
        />
        <FormLabel>Email address</FormLabel>
        <Input
          type="email"
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
          placeholder="Contact email"
          onChange={handleChange}
          mb={8}
          name="email"
          value={formValues.email}
          required
        />
        <Button
          alignSelf="stretch"
          type="submit"
          onClick={handleSubmit}
          isLoading={loading}
        >
          Create
        </Button>
      </form>
    </Flex>
  );
};

export default AddContactForm;
