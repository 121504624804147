import {
  // errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import http from "../../../services/api";
import { AUTH_ROUTES } from "../../../services/routes.constants";

export const contactUs = async (payload, setLoading) => {
  try {
    const response = await http.post(AUTH_ROUTES.CONTACT_US, payload);
    // console.log("voted", response);
    setLoading(false)
    successNotifier(response?.data?.message);
    return response;
  } catch (e) {
    setLoading(false)
    // errorNotifier(e.response?.data?.message);
    console.log(e);
  }
};
