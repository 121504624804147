import { Box, Input, Text, Button, Stack, Flex } from "@chakra-ui/react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { PinInput, PinInputField } from "@chakra-ui/react"
import { Link, Navigate } from "react-router-dom"
import { registerUser } from "../../../features/user"
import { useDispatch, useSelector } from "react-redux"

export const SignupModal = ({ showSignup, setShowSignup }) => {
  const [disabled, setDisabled] = useState(true)

  const [formValues, setFormValues] = useState({
    email: "",
    pin: "",
    name: "",
  })

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const { email, pin, name } = formValues
    if (!email || !pin || !name) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [formValues])

  const handleChange = e => {
    const { name, value } = e.target
    setFormValues(initial => ({ ...initial, [name]: value }))
  }

  const handlePinChange = e => {
    if (e.length !== 4) return
    setFormValues(initial => ({ ...initial, pin: e }))
  }

   const user = useSelector((state) => state.user);
   const dispatch = useDispatch();

   const handleSignup = (e) => {
     e.preventDefault();
     setLoading(true);
     dispatch(registerUser(formValues))
       .then(() => setLoading(false))
       .catch((err) => setLoading(false));
   };

   if (user.name && user.email) {
     return <Navigate replace to="/dashboard" />;
   }

  const FONT_STYLE = {
    fontWeight: "bold",
    fontSize: ".8em",
  }

  return (
    <Box>
      <Modal isOpen={showSignup} onClose={() => setShowSignup(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontWeight="bold" fontSize="1.5em">
              Create an account
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p="30px">
            <Stack spacing="30px">
              <Stack>
                <Text {...FONT_STYLE}>Full name</Text>
                <Input
                  onChange={handleChange}
                  name="name"
                  type="text"
                  placeholder="Enter name"
                />
              </Stack>
              <Stack>
                <Text {...FONT_STYLE}>Email</Text>
                <Input
                  type="email"
                  onChange={handleChange}
                  name="email"
                  placeholder="Enter email"
                />
              </Stack>
              <Stack>
                <Text {...FONT_STYLE}>Pin</Text>
                <Flex justifyContent="space-between">
                  <PinInput onChange={(e) => handlePinChange(e)} mask>
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </Flex>
              </Stack>
              <Button
                color="#fff"
                bg="#6C4097"
                _hover={{ bg: "#6C4097" }}
                onClick={handleSignup}
                isLoading={loading}
                disabled={disabled}
              >
                Create account
              </Button>
              <Flex>
                <Text mr="10px">Already have an account?</Text>
                <Link to="/login" style={{ color: "#6c4097", fontWeight: "bold" }}>Log In</Link>
              </Flex>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
