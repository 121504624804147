import {
  Flex,
  Circle,
  Text,
  Box,
  Menu,
  MenuButton,
  IconButton,
  Portal,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { IoMdPerson } from "react-icons/io";
import { BsThreeDotsVertical } from "react-icons/bs";
import CustomModal from "../../../components/CustomModal";
import CautionAlertDialog from "../../../components/CautionAlertDialog";
import EditContactForm from "./EditContactForm";
import { deleteContact } from "../services/singleContact";

const Contact = ({ contact, setRefresh }) => {
  const handleDelete = async (id) => {
      await deleteContact(id).then(() => setRefresh([]));
  };

  return (
    <Flex color="#A8A5A5" align="center">
      <Flex align="center">
        <Circle p={2} bg="#F9F3FF" mr={4} alignSelf="center" color="primary">
          <IoMdPerson size="1.2rem" />
        </Circle>
        <Box fontWeight={"semibold"}>
          <Text color="primary" textTransform={"capitalize"}>{contact?.name}</Text>
          <Text fontSize="12px">{contact?.email}</Text>
        </Box>
      </Flex>
      <Box ml="auto">
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<BsThreeDotsVertical color="#A8A5A5" />}
            bg="transparent"
            _hover={{ bg: "transparent" }}
          />
          <Portal>
            <MenuList>
              <MenuItem as={Box}>
                <CustomModal
                  buttonText="Edit"
                  buttonProps={{
                    bg: "none",
                    _hover: { background: "none" },
                    flexBasis: "100%",
                    justifyContent: "flex-start",
                    color: "#A8A5A5",
                  }}
                  modalTitle="Edit contact"
                >
                  <EditContactForm
                  contact_id={contact?.id} 
                  contact_name={contact?.contact_name} 
                  email={contact?.email} 
                  setRefresh={setRefresh}
                  />
                </CustomModal>
              </MenuItem>
              <MenuItem as={Box}>
                <CautionAlertDialog
                  buttonText="Delete"
                  buttonProps={{
                    bg: "none",
                    _hover: { background: "none" },
                    flexBasis: "100%",
                    justifyContent: "flex-start",
                    color: "#A8A5A5",
                  }}
                  cautionTitle="Are you sure you want to delete contact?"
                  onContinue={() => handleDelete(contact?.id)}
                />
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </Box>
    </Flex>
  );
};

export default Contact;
