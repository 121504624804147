import { useState, useRef } from "react";
import { Navigate } from "react-router-dom";
import {
  Button,
  Text,
  FormLabel,
  Input,
  PinInputField,
  PinInput,
  HStack,
  Box,
  Flex,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { registerUser } from "../../../features/user";
import { BiArrowBack } from "react-icons/bi";

const Signup = ({ setLogin, setLoginPage, pcode, email }) => {
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({
    name: "",
    email,
    pin: "",
    pcode,
  });
  const formRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handlePinChange = (e) => {
    if (e.length !== 4) return;
    setFormValues((initial) => ({ ...initial, pin: e }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!formRef.current.reportValidity()) return;
    dispatch(registerUser(formValues))
      .then(() => setLoading(false))
      .catch((err) => setLoading(false));
  };

  if (user.name && user.email) {
    return <Navigate replace to="/dashboard" />;
  }

  return (
    <Box width={"100%"}>
      <Flex my="20px" cursor={"pointer"}>
        <BiArrowBack size={26} onClick={() => setLoginPage("neutral")} />
      </Flex>
     
      <Text
        as="h1"
        color="primary"
        fontWeight="bold"
        fontSize={["1.4rem", "2rem", "2rem", "2.3rem"]}
        mb={["10px", "15px", "30px"]}
      >
        Complete Registration{" "}
      </Text>
      <form ref={formRef}>
        <FormLabel fontSize={["16px", "18px"]}>Enter Name </FormLabel>
        <Input
          bg="#fff"
          h={["40px", "40px", "50px"]}
          width={["96%", "100%", "100%", "72%"]}
          onChange={handleChange}
          mb={4}
          name="name"
          placeholder="Enter your full name"
          value={formValues.name}
          required
        />

        <FormLabel fontSize={["16px", "18px"]} isRequired>
          Create Pin
        </FormLabel>
        <HStack
          justifyContent={"left"}
          spacing={["10%", "12%"]}
          size="lg"
          width="100%"
        >
          <PinInput
            size="lg"
            placeholder=""
            mask
            onChange={(e) => handlePinChange(e)}
          >
            <PinInputField
              color="#000"
              isRequired
              bg="#fff"
            />
            <PinInputField bg="#fff" />
            <PinInputField bg="#fff" />
            <PinInputField bg="#fff" />
          </PinInput>
        </HStack>

        <Button
          h={["40px", "40px", "50px"]}
          width={["96%", "100%", "100%", "72%"]}
          isLoading={loading}
          type="submit"
          onClick={handleSubmit}
          my={["20px", "20px", "30px"]}
          fontSize="20px"
          isDisabled={
            formValues?.email === "" ||
            formValues?.pin === "" ||
            formValues?.name === ""
          }
          _hover={{ bg: "primary" }}
        >
          Send
        </Button>
        {/* <GoogleLoginButton /> */}
      </form>
    </Box>
  );
};

export default Signup;
