import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  poll: {
    votes_count: [],
    participants: [],
    poll_description: [],
  },
  pollTimes: [],
  pollCode: "",
  createPollData: {
    title: "",
    description: "",
    poll_duration: "",
    endDate: "",
    hours: "",
    minutes: "",
  }
};

export const userSlice = createSlice({
  name: "poll",
  initialState,
  reducers: {
    savePollData: (state, action) => {
      state.poll = action.payload;
    },
    savePollTimes: (state, action) => {
      state.pollTimes = action.payload;
    },
    savePollCode: (state, action) => {
      state.pollCode = action.payload;
    },
    saveCreatePollData: (state, action) => {
      state.createPollData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { savePollData, savePollTimes, savePollCode, saveCreatePollData } =
  userSlice.actions;

export const selectPollCode = (state) => state.user.user;

export default userSlice.reducer;
