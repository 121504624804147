import { useState, useRef } from "react";
import {
  Flex,
  Input,
  Button,
  Tag,
  TagLabel,
  TagCloseButton,
} from "@chakra-ui/react";
import {
  successNotifier,
  errorNotifier,
} from "../../../components/NotificationHandler";
// import { MdDelete } from "react-icons/md";
import { sendInvites } from "../services/invite";

const Email = ({ email, setEmails, emails }) => {
  const handleDelete = () => {
    setEmails(emails.filter((item) => item !== email));
  };
  return (
    <Tag
      // size={size}
      // key={size}
      borderRadius="full"
      variant="solid"
      colorScheme="purple"
    >
      <TagLabel>{email}</TagLabel>
      <TagCloseButton cursor={"pointer"} onClick={handleDelete} />
    </Tag>
    // <Flex
    //   color="#A8A5A5"
    //   align="center"
    //   bg="#F7EFFF"
    //   px={4}
    //   py={2}
    //   justify="space-between"
    // >
    //   <Text>{email}</Text>
    //   <MdDelete style={{ cursor: "pointer" }} onClick={handleDelete} />
    // </Flex>
  );
};

const InviteEmail = ({ onClose, pcode }) => {
  const [inputValue, setInputValue] = useState("");
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  const formRef = useRef();

  const handleSubmit = () => {
    setLoading(true);
    sendInvites({ pcode, emails })
      .then(() => successNotifier("Invite Sent successfully"))
      .catch((e) => {
        if (e?.response) {
        } else {
          errorNotifier("Please Check Your network and try again");
        }
        // console.log(e);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  const handleAdd = () => {
    if (!formRef.current.reportValidity()) return;
    setInputValue("");
    setEmails([...emails, inputValue]);
  };

  const handleKeyPress = (e) => {
    if (!formRef.current.reportValidity()) return;
    if (e.charCode === 13) handleAdd();
  };

  const handleKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      const email = inputValue.trim();

      if (email && isInList(email) && isEmail(email)) {
        setEmails([...emails, email]);
        setInputValue("");
      }
    }
  };

  const isInList = (email) => {
    if (emails.includes(email)) {
      errorNotifier(`${email} is already added`);
      return false;
    } else {
      return true;
    }
  };

  const isEmail = (email) => {
    return /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(email);
  };

  // const handlePaste = (evt) => {
  //   evt.preventDefault();

  //   const paste = evt.clipboardData.getData("text");
  //   const newEmails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

  //   if (newEmails) {
  //     const toBeAdded = newEmails.filter((email) => !isInList(email));

  //     setEmails([...emails, ...toBeAdded]);
  //   }
  // };

  const handleChange = (e) => {
    // e.preventDefault()
    setInputValue(e.target.value);
  };

  return (
    <>
      <Flex mb={2} justify="space-between">
        <form style={{ flexBasis: "100%" }} ref={formRef}>
          <Flex
            direction={["column", "row"]}
            gridGap={2}
            py={2}
            width={"100%"}
            wrap="wrap"
          >
            {emails.map((email, i) => (
              <Email
                key={i}
                email={email}
                emails={emails}
                setEmails={setEmails}
              />
            ))}
          </Flex>
          <Input
            type="email"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            variant="flushed"
            value={inputValue}
            onChange={handleChange}
            placeholder="Type or paste email address and press 'Enter' or  ' , '"
            onKeyPress={handleKeyPress}
            onKeyDown={handleKeyDown}
            // onPaste={handlePaste}
            required
          />
        </form>
        {/* <Button
          bg="none"
          _hover={{ bg: "none" }}
          _active={{ bg: "none" }}
          onClick={handleAdd}
          color="primary"
        >
          Add
        </Button> */}
      </Flex>
      {/* <Flex direction="column" gridGap={2} py={2}>
        {emails.map((email, i) => (
          <Email key={i} email={email} emails={emails} setEmails={setEmails} />
        ))}
      </Flex> */}
      <Flex justify="center" mt={2}>
        <Button
          color="white"
          fontWeight="normal"
          onClick={handleSubmit}
          disabled={emails.length === 0 ? true : null}
          isLoading={loading}
        >
          Send
        </Button>
      </Flex>
    </>
  );
};

export default InviteEmail;
