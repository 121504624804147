import http from "../../../services/api";
import { AUTH_ROUTES } from "../../../services/routes.constants";
import {
  successNotifier,
  errorNotifier,
} from "../../../components/NotificationHandler";

export const createContact = async (formData) => {
  try {
    const response = await http.post(AUTH_ROUTES.CREATE_CONTACT, formData);

    successNotifier(response.data.message);
  } catch (e) {
    errorNotifier(e.response);
  } finally {
    //  setLoading(false);
  }
};

export const getContacts = async (setLoading, setContacts) => {
  try {
    const response = await http.get(AUTH_ROUTES.GET_CONTACTS);
    setContacts(response?.data?.data);
    setLoading(false);
  } catch (e) {
    setLoading(false);
  }
};

export const editContact = async (payload, setLoading) => {
  try {
    const response = await http.post(AUTH_ROUTES.UPDATE_CONTACT, payload);
    setLoading(false);
    successNotifier(response?.data?.message);
  } catch (e) {
    setLoading(false);
    errorNotifier();
  }
};

export const uploadContacts = async (formData) => {
  const response = await http.post(AUTH_ROUTES.UPLOAD_CONTACTS, formData);
  return response;
};

export const searchContact = async (inputValue, setSearchContacts) => {
  try {
    const response = await http.get(AUTH_ROUTES.SEARCH_CONTACT(inputValue));
    // console.log("search", data)
    setSearchContacts({ response: response?.data?.data, show: true });
  } catch (e) {
    setSearchContacts({ response: [], show: false });
  }
};

export const deleteContact = async (id) => {
  try {
    const response = await http.delete(AUTH_ROUTES.DELETE_CONTACT(id));
    successNotifier(response?.data?.message);
  } catch (e) {
    errorNotifier();
  }
};
