import React from "react";
import { Box, Text, Heading } from "@chakra-ui/react";
import Nav from "../../../components/Nav";
import { Footer } from "../component/Footer";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
    
export const Terms = () => {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  return (
    <Box>
      {!isLoggedIn && <Nav />}
      <Box p={["20px 10%", "20px 10%", "50px 20%"]}>
        <Heading pb={["20px"]} color="primary">
          Terms of Use
        </Heading>
        <Text my="10px">
          Thanks for using Fixmeet. These Terms of Use (“Terms” or “Agreement”)
          are a legal agreement between you and Fixmeet, LLC (“Fixmeet,” “we,”
          “our,” or “us”) and govern your use of all the text, data,
          information, software, graphics, photographs and more that we and our
          affiliates may make available to you (all of which we refer to as
          “Documentation”), including our websites and any services, plug-ins,
          software or other Downloadable Tools (as defined below) that we may
          provide through any of our websites (the “Platform” or “Website” and
          together with the Materials, these “Service(s)”). Fixmeet “Users” are
          the people who have created a Fixmeet account (also referred to as
          “Customer(s)”), and Fixmeet “Invitees” are people who have scheduled a
          meeting with a Fixmeet User but may or may not have registered with
          Fixmeet. A Fixmeet “Viewer” is a person who visits the Fixmeet Website
          but may or may not be a Fixmeet User or Fixmeet Invitee.
        </Text>
        <Text my="10px">
          If you are entering into these Terms on behalf of a company or other
          organization, you hereby represent and warrant that you are authorized
          to enter into these Terms on behalf of such company or other
          organization (“Authorized User”).
        </Text>

        <Text>
          READ THESE TERMS CAREFULLY BEFORE BROWSING THIS WEBSITE. USING THESE
          SERVICES INDICATES THAT YOU HAVE BOTH READ AND ACCEPT THESE TERMS. YOU
          CANNOT USE THESE SERVICES IF YOU DO NOT ACCEPT THESE TERMS. THESE
          TERMS CONTAIN A DISPUTE RESOLUTION AND ARBITRATION PROVISION,
          INCLUDING A CLASS ACTION WAIVER THAT AFFECTS YOUR RIGHTS UNDER THESE
          TERMS AND WITH RESPECT TO DISPUTES YOU MAY HAVE WITH FIXMEET.
        </Text>

        <Text>
          IF YOU ARE A FIXMEET USER, AUTHORIZED USER, VIEWER, OR INVITEE LOCATED
          IN THE EUROPEAN ECONOMIC AREA, SWITZERLAND, OR UNITED KINGDOM, THE
          <a href="https:fixmeet.app/dpa">DATA PROCESSING ADDENDUM</a>{" "}
          ACCOMPANIES THESE TERMS AND SETS FORTH OTHER TERMS OF OUR AGREEMENT
          THAT APPLY SOLELY TO THE EXTENT ANY INFORMATION YOU PROVIDE TO FIXMEET
          INCLUDES PERSONAL DATA OF INDIVIDUALS LOCATED IN THE EUROPEAN ECONOMIC
          AREA, SWITZERLAND, AND THE UNITED KINGDOM.
        </Text>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Scope
        </Heading>
        <p>
          Fixmeet’s Services facilitate the online scheduling of appointments.
          You may access and use the Services only in accordance with these
          Terms. Please note that these Terms govern your use of the Services,
          including, but not limited to, the process through which your Invitees
          schedule appointments with you.
        </p>
        <Text my="10px">
          When using the features of the Services you are subject to any
          agreements, policies or guidelines applicable to such features that
          may be posted from time to time. All such agreements, policies or
          guidelines, including the{" "}
          <Link className="anchor" to="/privacy-policy">
            Privacy Policies
          </Link>{" "}
          and the{" "}
          <Link className="anchor" to="/dpa">
            Data Processing Addendum
          </Link>{" "}
          (as applicable) are hereby incorporated by reference into these Terms.
        </Text>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          General Use
        </Heading>
        <p>
          We invite you to use these Services for your individual purposes and
          not for the purposes of reselling ("Permitted Purposes") – enjoy!
        </p>
        <Text my="20px">
          Subject to these Terms, we grant you a limited, personal,
          non-exclusive and non-transferable license to use and to display the
          Documentation and a limited, personal, non-exclusive and
          non-transferable right to access and use the Service following the
          set-up of a Free Account or Paid Account as set forth below. You have
          no other rights in the Service and shall not use the Services for any
          purposes beyond the scope of the access granted in this Agreement.
          Customer shall not at any time, directly or indirectly, and to the
          extent Customer is a company or other organization, shall not permit
          any Authorized Users to: (i) copy, modify, or create derivative works
          of the Services or Documentation, in whole or in part; (ii) rent,
          lease, lend, sell, license, sublicense, assign, distribute, publish,
          transfer, or otherwise make available the Services or Documentation;
          (iii) reverse engineer, disassemble, decompile, decode, adapt, or
          otherwise attempt to derive or gain access to any software component
          of the Services, in whole or in part; (iv) remove any proprietary
          notices from the Services or Documentation; or (v) knowingly use the
          Services or Documentation in any manner or for any purpose that
          infringes, misappropriates, or otherwise violates any intellectual
          property right or other right of any person, or that violates any
          applicable law. If you make copies of any portion of this Website
          while engaging in Permitted Purposes then you agree to keep on these
          copies all of our copyright and other proprietary notices as they
          appear on this Website.
        </Text>
        <p>
          Subject to these Terms, we also grant you a limited, personal,
          non-exclusive, non-transferable license to download, install and
          operate any plug-ins, software, information, or other content that we
          may have specifically identified within the Website as available for
          download solely in connection with accessing the Website, including,
          but not limited to, relevant Fixmeet browser extensions (“Downloadable
          Tools”). Such Downloadable Tools are also subject to the additional
          terms provided to you upon download, including without limitation our
          End User License Agreement.
        </p>
        <Text mt="20px">
          Our Service is not intended for and may not be used by individuals
          under 13 years of age except as provided by the Educational Use
          Section of these Terms. By registering for a Fixmeet account, you
          state that you are at least 18 years of age, and you are responsible
          for ensuring that all Fixmeet Users and Invitees are at least 13 years
          old. Creating an account with false information is a violation of our
          Terms, including creating an account on behalf of others or persons
          under the age of 18.
        </Text>

        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Account Setup
        </Heading>
        <p>
          To use the Website, you need to set up a Fixmeet account. You may
          establish either a free account (“Free Account”) or a paid account
          (“Paid Account”). You are authorized by Fixmeet to set up one Free
          Account only. Fixmeet reserves the right to terminate any additional
          Free Accounts set up by you and any Free Accounts that have been
          inactive for over 6 months.
        </p>
        <Text my="20px">
          When you create an account, we collect registration-related
          information, which includes your email address and your name. Once you
          submit the required registration information, we alone will determine
          whether or not to approve your proposed account. If approved, you will
          be sent an email detailing how to complete your registration. For so
          long as you use the account, you agree to provide true, accurate,
          current, and complete information that can be accomplished by logging
          into your account and making relevant changes directly. You are
          responsible for complying with these Terms when you access this
          Website, whether directly or through any account that you may set up
          through or on this Website. Because it is your account, it is your job
          to obtain and maintain all equipment and services needed for access to
          and use of this Website as well as paying related charges. You agree
          to choose a strong and secure password(s) and maintain the
          confidentiality of your password(s), including any password of a
          third-party site that we may allow you to use to access this Website.
          Should you believe your password or security for this Website has been
          breached in any way, you must immediately notify us at{" "}
          <a className="anchor" href="mailto:support@fixmeet.app">
            support@fixmeet.app
          </a>
        </Text>
        <p>
          Fixmeet or our billing processors collect your billing information
          when you upgrade to a Paid Account. All information is protected in
          accordance with our{" "}
          <Link className="anchor" to="/privacy-policy">
            Privacy Policies
          </Link>
        </p>

        <Text mb="20px">
          Your account belongs to you. However, Fixmeet controls the URL(s)
          assigned to your account and may change or deactivate the URL(s)
          without prior notice. If your account is paid for by another party for
          you to use (for example, paid and managed by your employer), the party
          paying for your account has the right to control it, manage it, and
          get reports on your use of the Paid Account. The party paying for your
          account does not have rights nor access to your separate, personal
          account(s) that are not paid for nor managed by that party.
        </Text>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Renewal/Cancellation.
        </Heading>
        <p>
          Paid Accounts will automatically renew for the same subscription
          period unless you cancel the account by the end of the then-current
          subscription period. You can cancel your account at any time by
          contacting customer service at . Cancellation will be effective
          immediately. Please note that after you cancel your account, you may
          not be able to use or access the Platform and you will not be entitled
          to a refund of any fees that you've paid.
        </p>

        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Legal Compliance
        </Heading>
        <p>
          You agree to comply with all applicable domestic and international
          laws, statutes, ordinances, and regulations regarding your use of our
          Website. Fixmeet reserves the right to investigate complaints or
          reported violations of our Terms and to take any action we deem
          appropriate, including but not limited to canceling your User account,
          reporting any suspected unlawful activity to law enforcement
          officials, regulators, or other third parties and disclosing any
          information necessary or appropriate to such persons or entities
          relating to your profile, e-mail addresses, usage history, posted
          materials, IP addresses, and traffic information, as allowed under our
          <Link to="/privacy-policy">Privacy Policies</Link>. You agree to
          promptly notify Fixmeet in the event that you are made aware of any
          unauthorized or illegal use of the Website.
        </p>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Intellectual Property.
        </Heading>
        <p>
          Our Services may contain our service marks or trademarks as well as
          those of our affiliates or other companies, in the form of words,
          graphics, and logos, and any and all intellectual property provided to
          Customer or any Authorized User in connection with the Services
          (“Fixmeet IP”). Your use of our Services does not constitute any right
          or license for you to use such service marks/trademarks. Our Website
          is also protected under international copyright laws. The copying,
          redistribution, use, or publication by you of any portion of our
          Website is strictly prohibited. Your use of our Services do not grant
          you ownership rights of any kind in our Website or Materials. Fixmeet
          reserves all rights not expressly granted to Customer in this
          Agreement. Except for the limited rights and licenses expressly
          granted under this Agreement, nothing in this Agreement grants, by
          implication, waiver, estoppel, or otherwise, to Customer or any third
          party any intellectual property rights or other right, title, or
          interest in or to the Fixmeet IP.
        </p>

        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Notices and Message.
        </Heading>
        <p>
          By using the Services, you consent to receive emails from us. These
          emails may include notices about applicable fees and charges,
          transactional information and other information concerning or related
          to the Services. These emails are part of your relationship with us.
          By scheduling a meeting as an Invitee with a Fixmeet User, you consent
          to receive transactional emails related to the scheduled meeting (e.g.
          booking confirmations and reminders). If, as an Invitee, you provide
          your phone number for text reminders, you also consent to receive SMS
          text reminders from Fixmeet related to the scheduled meeting between
          you and the Fixmeet User. You agree that any notices, agreements,
          disclosures, or other communications that we send you electronically
          will satisfy any legal communication requirements, including that such
          communications be in writing. You agree to keep your contact
          information up to date, and to monitor your email settings to ensure
          that you receive emails from us at your discretion and as necessary.
        </p>

        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Links to Other Websites.
        </Heading>
        <p>
          Our Website may contain links to third party websites. These links are
          provided solely as a convenience to you. By linking to these websites,
          we do not create or have an affiliation with or sponsor such third
          party websites. The inclusion of links within our Website does not
          constitute any endorsement, guarantee, warranty, or recommendation of
          such third party websites. Fixmeet has no control over the legal
          documents and privacy practices of third party websites, and by using
          them, you may be giving those third parties permission to use or
          control your information in ways Fixmeet would not. As such, you
          access any third-party websites at your own risk.
        </p>

        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Unauthorized Activities.
        </Heading>
        <p>
          To be clear, we authorize your use of these Services only for
          Permitted Purposes. Any other use of these Services beyond the
          Permitted Purposes is prohibited and, therefore, constitutes
          unauthorized use which may result in the violation of various United
          States and international copyright laws, the deletion of your Fixmeet
          account, or a block from the Services. Unless you have written
          permission from us stating otherwise, you are not authorized to use
          these Services in any of the following ways. (Note that these are
          examples only, and the list below is not a complete list of everything
          that you are not permitted to do):
        </p>

        <Text my="20px">You are not allowed to use the Services:</Text>
        <Box p="20px 30px">
          <ul>
            <li>
              To create a competing product, resell or broker the Services to
              any third-party;
            </li>

            <li>
              In a manner that modifies, publicly displays, publicly performs,
              reproduces, or distributes any of the Services;
            </li>

            <li>
              In a manner that violates any relevant law or the Terms of this
              Agreement, including but not limited to anything that is illegal
              in the jurisdiction where the User, Invitee and/or Authorized User
              is located;
            </li>

            <li>
              To stalk, harass, spam, or harm another individual, organization,
              or business;
            </li>

            <li>
              To disclose information that you do not have permission to
              disclose;
            </li>

            <li>
              To impersonate any person or entity, misstate your Personal Data,
              or otherwise misrepresent your affiliation with a person or
              entity;
            </li>

            <li>
              To interfere with or disrupt the Services or servers or networks
              connected to the Services;
            </li>

            <li>
              To use any data mining, robots, or similar data gathering or
              extraction methods in connection with the Services;
            </li>

            <li>
              To disseminate any viruses, worms, or otherwise harmful code; or
            </li>

            <li>
              To attempt to gain unauthorized access to any portion of the
              Services or any other accounts, computer systems, or networks
              connected to the Services, whether through hacking, password
              mining, or any other means;
            </li>

            <li>
              To submit or store Payment Card Industry data (such as credit card
              numbers) without using an authorized Fixmeet payment integration;
            </li>

            <li>
              To disclose any types of information listed in the California
              Customer Records statute (Cal. Civ. Code § 1798.80(e)) or other
              relevant privacy regulations, including medical or health
              insurance information, education information, employment
              information, credit or debit card information, or any other
              financial information, driver’s license, or other government
              identification number;
            </li>

            <li>
              To disclose or collect any types of information considered to be
              protected classification characteristics under California or other
              relevant law, including Age (if 40 years or older), race, color,
              ancestry, national origin, citizenship, religion or creed, marital
              status, medical condition, physical or mental disability, sex
              (including gender, gender identity, gender expression, pregnancy
              or childbirth, and related medical conditions), sexual
              orientation, veteran or military status, and genetic information
              (including familial genetic information); and
            </li>

            <li>
              To disclose or collect any information defined by relevant laws as
              biometric information, or any information that falls within the
              definition of “special categories of data” under EU Data
              Protection Laws, “protected health information” under California
              or other relevant law or regulations. “Special categories of data”
              include, for example, information about an individual’s race,
              ethnic origin, politics, religion, trade union membership,
              genetics, biometrics, health, sex life, sexual orientation, or
              criminal records.
            </li>
          </ul>
        </Box>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Indemnification.
        </Heading>
        <p>
          You alone are responsible for any violation of these Terms by you. You
          agree to indemnify and hold Fixmeet, its parents, subsidiaries,
          affiliates, officers, agents, employees, resellers or other partners,
          and licensors harmless from any claim, demand, loss, or damages,
          including reasonable attorneys’ fees (“Losses”), resulting from any
          third-party claim, suit, action, or proceeding (“Third-Party Claim”)
          that the Customer Data, or the use thereof in accordance with this
          Agreement, infringes or misappropriates such third party’s
          intellectual property rights or violates any applicable law or
          regulation and any Third-Party Claims based on Customer’s or any
          Authorized Users’ (i) negligence or willful misconduct; (ii) use of
          the Services in a manner not authorized by this Agreement; (iii) use
          of the Services in combination with data, software, hardware,
          equipment, or technology not provided by Fixmeet or authorized by
          Fixmeet in writing; or (iv) modifications to the Services not made by
          Fixmeet, provided that Customer may not settle any Third-Party Claim
          against Fixmeet unless Fixmeet consents to such settlement, and
          further provided that Fixmeet will have the right, at its option, to
          defend itself against any such Third-Party Claim or to participate in
          the defense thereof by counsel of its own choice.
        </p>

        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Warranty Disclaimer.
        </Heading>

        <p>
          THE SERVICES ARE PROVIDED "AS IS" AND FIXMEET HEREBY DISCLAIMS ALL
          WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE. FIXMEET
          SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY,
          FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND ALL
          WARRANTIES ARISING FROM COURSE OF DEALING, USAGE, OR TRADE PRACTICE.
          FIXMEET MAKES NO WARRANTY OF ANY KIND THAT THE FIXMEET IP, OR
          SERVICES, OR ANY PRODUCTS OR RESULTS OF THE USE THEREOF, WILL MEET
          CUSTOMER'S OR ANY OTHER PERSON'S REQUIREMENTS, OPERATE WITHOUT
          INTERRUPTION, ACHIEVE ANY INTENDED RESULT, BE COMPATIBLE OR WORK WITH
          ANY SOFTWARE, SYSTEM, OR OTHER SERVICES, OR BE SECURE, ACCURATE,
          COMPLETE, FREE OF HARMFUL CODE, OR ERROR FREE. FIXMEET STRICTLY
          DISCLAIMS ALL WARRANTIES, WITH RESPECT TO ANY THIRD-PARTY
          INTEGRATIONS.
        </p>

        <Text mt="20px">
          THIS MEANS THAT WE DO NOT PROMISE YOU THAT THE SERVICES ARE FREE OF
          PROBLEMS. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE MAKE NO
          WARRANTY THAT THE SERVICES WILL MEET YOUR REQUIREMENTS OR WILL BE
          UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, OR THAT DEFECTS WILL BE
          CORRECTED. WE MAKE NO WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED
          OR AS TO THE ACCURACY OR RELIABILITY OF ANY INFORMATION OBTAINED
          THROUGH THE SERVICES. NO ADVICE OR INFORMATION, WHETHER ORAL OR
          WRITTEN, OBTAINED BY YOU THROUGH THE SERVICES OR FROM US OR OUR
          SUBSIDIARIES/OTHER AFFILIATED COMPANIES WILL CREATE ANY WARRANTY. WE
          DISCLAIM ALL EQUITABLE INDEMNITIES.
        </Text>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Limitation of Liability
        </Heading>
        <p>
          FIXMEET WILL NOT BE LIABLE TO YOU OR OTHERS FOR ANY DAMAGES RESULTING
          FROM YOUR USE OF THE SERVICES OR YOUR DISPLAYING, COPYING, OR
          DOWNLOADING ANY DOCUMENTATION TO OR FROM THE WEBSITE. IN NO EVENT WILL
          FIXMEET BE LIABLE UNDER OR IN CONNECTION WITH THIS AGREEMENT UNDER ANY
          LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT
          (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE, FOR ANY: (a)
          CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED, OR
          PUNITIVE DAMAGES; (b) INCREASED COSTS, DIMINUTION IN VALUE OR LOST
          BUSINESS, PRODUCTION, REVENUES, OR PROFITS; (c) LOSS OF GOODWILL OR
          REPUTATION; (d) USE, INABILITY TO USE, LOSS, INTERRUPTION, DELAY, OR
          RECOVERY OF ANY DATA, OR BREACH OF DATA OR SYSTEM SECURITY; OR (e)
          COST OF REPLACEMENT GOODS OR SERVICES, IN EACH CASE REGARDLESS OF
          WHETHER FIXMEET WAS ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR
          DAMAGES OR SUCH LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE.
        </p>
        <Text my="20px">
          IN NO EVENT WILL FIXMEET’S AGGREGATE LIABILITY OR FIXMEET’S
          INDEMNIFICATION OBLIGATIONS ARISING OUT OF OR RELATED TO THIS
          AGREEMENT UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF
          CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE
          EXCEED THE GREATER OF (I) THE AMOUNT PAID, IF ANY, BY YOU TO FIXMEET
          IN CONNECTION WITH THESE TERMS IN THE TWELVE (12) MONTHS PRIOR TO THE
          ACTION GIVING RISE TO LIABILITY OR (II) US $100.00.
        </Text>

        <p>
          THIS LIMITATION OF LIABILITY IS PART OF THE BASIS OF THE AGREEMENT
          BETWEEN YOU AND FIXMEET AND SHALL APPLY TO ALL CLAIMS OF LIABILITY,
          EVEN IF FIXMEET HAS BEEN TOLD OF THE POSSIBILITY OF ANY SUCH DAMAGE,
          AND EVEN IF THESE REMEDIES FAIL THEIR ESSENTIAL PURPOSE.
        </p>

        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Local Laws; Export Control.
        </Heading>
        <p>
          We control and operate the Services from our headquarters in the
          United States of America and the entirety of the Services may not be
          appropriate or available for use in other locations. If you use the
          Services outside the United States of America, you are solely
          responsible for following applicable local laws.
        </p>
        <Text mt="20px">
          Fixmeet and you shall comply with all relevant United States export
          control laws and regulations. Without limiting the foregoing: (a) you
          acknowledge that the Services may be subject to the United States
          Export Administration Regulations; (b) you affirm that you are not,
          and you will not (i) permit Fixmeet Users or Invitees to access or use
          the Services in a United States embargoed country; (ii) or permit
          Fixmeet Users or Invitees on the U.S. Commerce Department’s Denied
          Persons, Entity, or Unverified Lists or (iii) permit Fixmeet Users or
          Invitees on the U.S. Treasury Department’s list of Specially
          Designated Nationals and Consolidated Sanctions list; and (c) you are
          responsible for complying with any local laws and regulations which
          may impact your right to import, export, access, or use the Services.
        </Text>

        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Dispute Resolution and Arbitration; Class Action Waiver.
        </Heading>
        <p>
          Please Read This Provision Carefully. It Affects Your Legal Rights.
        </p>
        <Text my="20px">
          This provision facilitates the prompt and efficient resolution of any
          dispute that may arise between you and us. Effectively, then,
          “dispute” is given the broadest meaning enforceable by law and
          includes any claims against other parties relating to services or
          products provided or billed to you (such as our licensors, suppliers,
          dealers or third-party vendors) whenever you also assert claims
          against us in the same proceeding.
        </Text>
        <p>
          This provision provides that all disputes between you and us will be
          resolved by binding arbitration because acceptance of these Terms
          constitutes a waiver of your right to litigate claims and all
          opportunity to be heard by a judge or jury. We prefer this because we
          believe arbitration is less drama-filled than litigation. To be clear,
          there is no judge or jury in arbitration, and court review of an
          arbitration award is limited. The arbitrator must follow this
          Agreement and can award the same damages and relief as a court
          (including attorney’s fees). YOU AND FIXMEET AGREE THAT ANY AND ALL
          DISPUTES, AS DEFINED ABOVE, WHETHER PRESENTLY IN EXISTENCE OR BASED ON
          ACTS OR OMISSIONS IN THE PAST OR IN THE FUTURE, WILL BE RESOLVED
          EXCLUSIVELY AND FINALLY BY BINDING ARBITRATION RATHER THAN IN COURT IN
          ACCORDANCE WITH THIS PROVISION.
        </p>
        <Text my="20px">
          For all disputes, you must first give us an opportunity to cure or
          resolve the dispute which is first done by emailing us at{" "}
          <a className="anchor" href="mailto:support@fixmeet.app">
            support@fixmeet.app
          </a>{" "}
          the following information: (1) your name, (2) your address, (3) a
          written description of your claim, and (4) a description of the
          specific relief you seek. If we do not resolve the dispute within 45
          days after receiving your notification, then you may pursue your
          dispute in arbitration.
        </Text>

        <p>
          If this provision applies and the dispute is not resolved as provided
          above (Pre-Arbitration Claim Resolution) either you or we may initiate
          arbitration proceedings. JAMS,
          <a href="https://www.jamsadr.com">www.jamsadr.com</a> , will arbitrate
          all disputes, and the arbitration will be conducted before a single
          arbitrator. The arbitration will be commenced as an individual
          arbitration, and will in no event be commenced as a class arbitration.
          This provision governs in the event it conflicts with the applicable
          arbitration rules. Under no circumstances will class action procedures
          or rules apply to the arbitration. Because these Services and these
          Terms concern interstate commerce, the Federal Arbitration Act (“FAA”)
          governs the arbitrability of all disputes. However, the arbitrator
          will apply applicable substantive law consistent with the FAA and the
          applicable statute of limitations or condition precedent to suit. You
          or we may initiate arbitration in either Georgia or the U.S federal
          judicial district where you are located.
        </p>
        <Text my="20px">
          Except as otherwise provided in this provision, the arbitrator may not
          consolidate more than one person’s claims, and may not otherwise
          preside over any form of a class or representative proceeding or
          claims (such as a class action, consolidated action or private
          attorney general action) unless both you and Fixmeet specifically
          agree to do so following initiation of the arbitration.
        </Text>
        <p>
          You understand and agree that by accepting this provision in these
          Terms, you and we are each waiving the right to a jury trial or a
          trial before a judge in a public court. If any clause within this
          provision (other than the Class Action Waiver clause above) is found
          to be illegal or unenforceable, that clause will be severed from this
          provision whose remainder will be given full force and effect. If the
          Class Action Waiver clause is found to be illegal or unenforceable,
          this entire provision will be unenforceable and the dispute will be
          decided by a court. This provision will survive the termination of
          your account with us or our affiliates and your discontinued use of
          this Website. Notwithstanding any provision in this Agreement to the
          contrary, we agree that if we make any change to this provision (other
          than a change to the Notice Address), you may reject any such change
          and require us to adhere to the language in this provision if a
          dispute between us arises.
        </p>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          General Terms.
        </Heading>

        <p>
          We think direct communication resolves most issues – if we feel that
          you are not complying with these Terms, we will likely tell you. We
          may even provide you with recommended necessary corrective action(s)
          if possible. However, certain violations of these Terms, as determined
          by us, may require immediate termination of your access to this
          Website without prior notice to you.
        </p>
        <Text my="20px">
          The Federal Arbitration Act, Georgia state law and applicable U.S.
          federal law, without regard to the choice or conflicts of law
          provisions, will govern these Terms to the fullest extent permitted by
          applicable law. Except for disputes subject to arbitration as
          described above, any disputes relating to these Terms or this Website
          will be heard in the courts located in Fulton County, Georgia. Except
          as otherwise set forth in the dispute resolution provision, if any of
          these Terms are deemed inconsistent with applicable law, then such
          term(s) will be interpreted to reflect the intentions of the parties,
          and no other terms will be modified. By choosing not to enforce any of
          these Terms, we are not waiving our rights. These Terms, together with
          our{" "}
          <Link className="anchor" to="/privacy-policy">
            Privacy Policies
          </Link>{" "}
          and the{" "}
          <Link className="anchor" to="/dpa">
            Data Processing Addendum
          </Link>{" "}
          (as applicable), contain the entire agreement between you and us and,
          therefore, supersede all prior or contemporaneous negotiations,
          discussions or agreements between Fixmeet and you about the Services.
          Any definitions found in the{" "}
          <Link className="anchor" to="/privacy-policy">
            Privacy Policies
          </Link>{" "}
          and the{" "}
          <Link className="anchor" to="/dpa">
            Data Processing Addendum
          </Link>{" "}
          are hereby adopted by reference in this Agreement. The rights of
          Fixmeet under our Terms will survive the termination of this
          Agreement. No additional terms, conditions, consent, waiver,
          alteration, or modification shall be binding unless in writing and
          signed by both parties. Acceptance of any order by Fixmeet is
          expressly limited to the terms and conditions of this Agreement. Any
          proposed additional or inconsistent terms or conditions, including
          those in or accompanying any Customer proposal, any Customer purchase
          order, or other agreement or any other Customer document issued in
          connection with the sale or delivery of Products or Services is deemed
          by Fixmeet to be a material change and is objected to and rejected by
          Fixmeet. Fixmeet's acceptance of any orders will not constitute
          acceptance of any terms and conditions contained therein.
        </Text>
        <p>
          In no event shall Fixmeet be liable to Customer, or be deemed to have
          breached this Agreement, for any failure or delay in performing its
          obligations under this Agreement, if and to the extent such failure or
          delay is caused by any circumstances beyond Fixmeet's reasonable
          control, including but not limited to acts of God, flood, fire,
          earthquake, explosion, war, terrorism, invasion, riot or other civil
          unrest, strikes, epidemics, contractor difficulties, labor stoppages
          or slowdowns or other industrial disturbances, or passage of law or
          any action taken by a governmental or public authority, including
          imposing an embargo.
        </p>
        <Text mt="20px">
          Unless Customer has specifically notified Fixmeet to the contrary in
          writing (including via email to{" "}
          <a className="anchor" href="mailto:support@fixmeet.app">
            support@fixmeet.app
          </a>
          ), Fixmeet may disclose your company as a customer of Fixmeet (if you
          use Fixmeet for business purposes and/or use a business email address
          as your Fixmeet User email address) and may use your company’s name
          and logo on the Website and in Fixmeet’s promotional content in
          accordance with Customer’s brand guidelines or related documentation
          if provided by Customer. Fixmeet will be granted a revocable,
          non-exclusive, non-sub-licensable, non-transferable, royalty-free
          right and license (the "Trademark License") to use, display and
          reproduce such party's name, logo, trademarks and service marks (the
          "Trademarks"). Both acknowledge that the other party's Trademark is
          and will remain the exclusive property of such party.
        </Text>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Changes.
        </Heading>
        <p>
          We may amend our Terms at any time by publishing revised Terms on our
          Website and/or by sending an email to the address you gave us. These
          Terms are current as of the Effective Date set forth above. If any
          changes are made to these Terms, we’ll post them on this page, so
          please be sure to check back periodically. If you continue to use
          Fixmeet after those changes are in effect, you agree to the revised
          Terms. Therefore, it is important for you to periodically review our
          Terms to make sure you still agree to them. If you object to any such
          modifications, your sole recourse will be to cease using this Website.
          Fixmeet reserves the right to change any and all features of our
          Services, at any time, without notice.
        </p>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Canada.
        </Heading>
        <p>
          The parties declare that they have required that these Terms and
          Conditions and all documents related hereto, either present or future,
          be drawn up in the English language only. Les parties déclarent par
          les présentes qu’elles exigent que cette entente et tous les documents
          y afferents, soit pour le present ou le future, soient rédigés en
          langue anglaise seulement.
        </p>
        <Heading
          my="10px"
          color={"primary"}
          as="h2"
          fontSize={["20px"]}
          fontWeight="bold"
        >
          Contact Us.
        </Heading>
        <p>
          If you have any questions about these Terms or otherwise need to
          contact us for any reason, you can reach us at{" "}
          <a className="anchor" href="mailto:support@fixmeet.app">
            support@fixmeet.app
          </a>
        </p>
      </Box>
      <Footer />
    </Box>
  );
}
