import { useState } from "react";
import {
  Button,
  Input,
  FormControl,
  FormLabel,
  useDisclosure,
} from "@chakra-ui/react";
import { editGroup } from "../services/groupContact";

const EditGroupForm = ({
  groupId,
  name,
  description,
  setRefresh,
}) => {
  const { onClose } = useDisclosure();
  const [groupName, setGroupName] = useState(name);
  const [groupDesc, setGroupDesc] = useState(description);
  const [btnLoading, setBtnLoading] = useState(false);

  const handleEditGroup = async (id) => {
    const payload = {
      group_name: groupName,
      group_description: groupDesc,
    };
    setBtnLoading(true);
    await editGroup(id, payload, setBtnLoading).then(() => setRefresh([]));
    // setBtnLoading(false);
    onClose()
    
  };

  return (
    <>
      <FormControl>
        <FormLabel htmlFor="name">Group Name</FormLabel>
        <Input
          id="name"
          type="text"
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
        />
      </FormControl>
      <FormControl mt="10px" mb="20px">
        <FormLabel htmlFor="description">Description</FormLabel>
        <Input
          id="description"
          type="text"
          value={groupDesc}
          onChange={(e) => setGroupDesc(e.target.value)}
        />
      </FormControl>

      <Button
        color="black"
        fontWeight="normal"
        w="100%"
        bg="#d8e8ff"
        _hover={{ bg: "#d8e8ff" }}
        isLoading={btnLoading}
        onClick={() => handleEditGroup(groupId)}
      >
        Save Changes
      </Button>
    </>
  );
};

export default EditGroupForm;
