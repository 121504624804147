import { Flex, Text, Img, Button } from "@chakra-ui/react";
import { AiOutlinePlus } from "react-icons/ai";
import { Link } from "react-router-dom";

const HeadingCard = () => {
  return (
    <Flex
      boxShadow="0px 6px 12px #00000005"
      gridGap={2}
      p={[5, 5, 6, 10]}
      bg="#fff"
      w="98%"
      borderRadius="10px"
    >
      <Flex
        direction="column"
        flexBasis={["100%", "100%", "50%", "50%"]}
        justify={["space-between", "center", "center", "space-between"]}
      >
        <Text
          as="h2"
          color="#707070"
          fontSize={["1.4rem", "1.6rem", "1.9rem"]}
          fontWeight="bolder"
          // maxWidth={["300px", "350px", "400px"]}
        >
          Hassle-free polling, Made just for You
        </Text>
        <Link to="/create-poll">
          <Button
            alignSelf="start"
            mt={2}
            leftIcon={<AiOutlinePlus />}
            px="50px"
            py="10px"
          >
            Create
          </Button>
        </Link>
      </Flex>
      <Flex flexBasis={[0, "50%", "50%", "50%"]}>
        <Img src="HomePageImage.svg" boxSize="100%" />
      </Flex>
    </Flex>
  );
};

export default HeadingCard;
