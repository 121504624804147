import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Text,
  Flex,
  FormLabel,
  Input,
  Button,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { MdArrowBack } from "react-icons/md";
import { UpdatePin, UpdateProfile } from "./service";
import { updateProfile } from "../../features/user";
import { useSelector } from "react-redux";
import { FiEye, FiEyeOff } from "react-icons/fi";
import TimezoneSelect from "react-timezone-select/dist";
import { useDispatch } from "react-redux";
import { errorNotifier } from "../../components/NotificationHandler";

const Profile = () => {
  const user = useSelector((state) => state.user);
  const currentTimeZone = useSelector((state) => state.user.timezone);
  const [show, setShow] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState({});

  const handleClick = () => setShow(!show);
  const [formValues, setFormValues] = useState({
    name: user?.name,
    old_pin: "",
    new_pin: "",
    confirm_pin: "",
  });

  // console.log("seee", selectedTimezone);
  // const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // console.log(timezone)

  const [loading, setLoading] = useState(false);
  const [pinLoading, setPinLoading] = useState(false);
  const dispatch = useDispatch();
  const formRef = useRef();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      name: formValues.name,
      timezone: selectedTimezone.value || currentTimeZone,
      label: selectedTimezone.label || currentTimeZone,
      // || localTimezone
    };
    if (!formRef.current.reportValidity()) return;
    setLoading(true);
    UpdateProfile(payload, setLoading)
      .then((response) => {
        const {
          data: { data },
        } = response;
        dispatch(
          updateProfile({
            name: data?.name,
            timezone: data?.timezone,
            label: selectedTimezone?.label || currentTimeZone,
          })
        );
      })
      .catch((e) => {
        if (e?.response) {
          errorNotifier(e.response?.data?.message);
        } else {
          errorNotifier("Please Check Your network and try again");
        }
        // console.log(e);
        setLoading(false);
      });
  };

  const handleUpdatePin = (e) => {
    e.preventDefault();
    const payload = {
      old_pin: formValues.old_pin,
      new_pin: formValues.new_pin,
      confirm_pin: formValues.confirm_pin,
      // || localTimezone
    };
    if (!formRef.current.reportValidity()) return;
    setPinLoading(true);

    UpdatePin(payload, setLoading)
      .then((response) => {
        const {
          data: { data },
        } = response;

        //  dispatch(
        //    updateProfile({
        //      name: data?.name,
        //      timezone: data?.timezone,
        //      label: selectedTimezone?.label || currentTimeZone,
        //    })
        //  );
        setPinLoading(false);
      })
      .catch((e) => {
        if (e?.response) {
          errorNotifier(e.response?.data?.message);
        } else {
          errorNotifier("Please Check Your network and try again");
        }

        setPinLoading(false);
      });
  };

  return (
    <Flex
      borderRadius="lg"
      bg="#fbfbfb"
      direction="column"
      justifyContent={["center"]}
      alignItems={["center"]}
      p={[0, 4]}
      py={[3, 3, 4, 4]}
      w={["95%", "100%", "100%", "100%"]}
      h={["100%", "85vh"]}
    >
      <Flex
        color="#707070"
        bg="#fff"
        p={[4, 2, 2, 4]}
        w={["100%", "100%", "60%"]}
      >
        <Link
          to=".."
          style={{
            alignSelf: "flex-start",
          }}
        >
          <MdArrowBack
            style={{
              marginTop: ".5rem",
              fontSize: "1.5rem",
            }}
          />
        </Link>
        <Flex direction="column" flexGrow={1}>
          <Text
            as="h2"
            fontSize={["20px", "26px"]}
            mb={6}
            ml="20px"
            fontWeight="semibold"
          >
            Profile Update
          </Text>
          <Flex
            justify={"space-between"}
            direction={["column", "column", "row"]}
          >
            <Flex direction="column" width={["100%", "45%"]}>
              <FormLabel fontSize={["14px", "16px"]}>Name</FormLabel>
              <Input
                bg="#fbfbfb"
                width="100%"
                height={["35px", "39px"]}
                name="name"
                value={formValues.name}
                onChange={handleChange}
                // required
              />
            </Flex>
            <Flex
              width={["100%", "45%"]}
              direction={["column"]}
              alignItems={["left"]}
              mt={["20px", 0]}
            >
              <FormLabel>Select your timezone</FormLabel>
              <TimezoneSelect
                className="timezone"
                value={selectedTimezone}
                onChange={setSelectedTimezone}
              />
            </Flex>
          </Flex>
          <Button
            w={["100%", "45%"]}
            height={["35px", "39px"]}
            my="30px"
            type="submit"
            onClick={handleSubmit}
            isLoading={loading}
            // isDisabled={
            //   formValues.confirm_pin === "" ||
            //   formValues.new_pin === "" ||
            //   formValues.old_pin === "" ||
            //   formValues.name === ""
            // }
            _hover={{ bg: "primary" }}
          >
            Save
          </Button>
          <hr style={{ marginBottom: "30px" }} />
          <FormLabel fontSize={"26px"} fontWeight="semibold">
            Pin Update
          </FormLabel>
          <form ref={formRef} autoComplete="false">
            <Flex
              direction={["column", "column", "row"]}
              wrap="wrap"
              sx={{
                "& > div": {
                  flex: "0 1 45%",
                  marginBottom: ".2rem",
                },
              }}
              gridGap={2}
              flexBasis="auto"
              justify="space-between"
            >
              <Flex direction="column">
                <FormLabel fontSize={["14px", "16px"]}>Current Pin</FormLabel>
                <InputGroup size="md">
                  <Input
                    type={show ? "text" : "password"}
                    bg="#fbfbfb"
                    height={["35px", "39px"]}
                    name="old_pin"
                    autoComplete="new-password"
                    value={formValues.old_pin}
                    onChange={handleChange}
                    // required
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      _hover={{ bg: "transparent" }}
                      bg="transparent"
                      h="1.75rem"
                      size="sm"
                      onClick={handleClick}
                    >
                      {show ? (
                        <FiEyeOff color="grey" />
                      ) : (
                        <FiEye color="gray" />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <Text fontSize="sm" mt={1}>
                  Forget Pin?{" "}
                  <Link to="/forget-password">
                    <Text as="span" fontWeight="bold">
                      Click here.
                    </Text>
                  </Link>
                </Text>
              </Flex>

              <Flex direction="column">
                <FormLabel fontSize={["14px", "16px"]}>
                  Create new pin (Max of four numbers)
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    type={show ? "text" : "password"}
                    bg="#fbfbfb"
                    height={["35px", "39px"]}
                    name="new_pin"
                    autoComplete="new-password"
                    value={formValues.new_pin}
                    onChange={handleChange}
                    // required
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      _hover={{ bg: "transparent" }}
                      bg="transparent"
                      h="1.75rem"
                      size="sm"
                      onClick={handleClick}
                    >
                      {show ? (
                        <FiEyeOff color="grey" />
                      ) : (
                        <FiEye color="gray" />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </Flex>
            </Flex>
            <Flex justify="space-between" direction={["column", "row"]} mt={4}>
              <Flex direction="column" w={["100%", "45%"]}>
                <FormLabel fontSize={["14px", "16px"]}>
                  Confirm new pin
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    type={show ? "text" : "password"}
                    bg="#fbfbfb"
                    autoComplete="new-password"
                    w={["100%", "100%"]}
                    height={["35px", "39px"]}
                    name="confirm_pin"
                    value={formValues.confirm_pin}
                    onChange={handleChange}
                    // required
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      _hover={{ bg: "transparent" }}
                      bg="transparent"
                      h="1.75rem"
                      size="sm"
                      onClick={handleClick}
                    >
                      {show ? (
                        <FiEyeOff color="grey" />
                      ) : (
                        <FiEye color="gray" />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </Flex>

              <Button
                w={["100%", "45%"]}
                height={["35px", "39px"]}
                my="30px"
                type="submit"
                onClick={handleUpdatePin}
                isLoading={pinLoading}
                isDisabled={
                  formValues.confirm_pin === "" ||
                  formValues.new_pin === "" ||
                  formValues.old_pin === ""
                  // formValues.name === ""
                }
                _hover={{ bg: "primary" }}
              >
                Save
              </Button>
            </Flex>
          </form>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Profile;
