import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  ModalOverlay,
  Text,
  Flex,
  Divider,
  Box,
  Circle,
} from "@chakra-ui/react";
import CustomModal from "../../../components/CustomModal";
import AddContactForm from "./AddContactForm";
import CreateGroupForm from "./CreateGroupForm";
import { AiOutlineClose } from "react-icons/ai";
import { BsFillCheckCircleFill } from "react-icons/bs";

export const HowItWorks = ({ setRefresh, contacts, groups, meetings, updateCount }) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [open, setOpen] = useState(true);

  const updateOpenModal = () => {
    onOpen()
    setOpen(false)
  }

    const updateOpen = () => {
      setOpen(true);
      onClose()
    };

  return (
    <>
      {open ? (
        <Flex>
          <Button onClick={updateOpenModal} mb={["30px", 0]}>How It Works</Button>
          <Circle
            size="20px"
            bg="red"
            color="white"
            ml={"-10px"}
            mt={-3}
            zIndex={10}
          >
            <Text fontSize={"10px"}>{updateCount()}</Text>
          </Circle>
        </Flex>
      ) : (
        <Circle
          bg="primary"
          color="white"
          cursor={"pointer"}
          ml={"-10px"}
          mt={-3}
          p="5px"
          onClick={updateOpen}
        >
          <AiOutlineClose color="white" size={36} />
        </Circle>
      )}

      <Modal isOpen={isOpen} onClose={updateOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p="15px 20px" bg="primary" color={"#fff"}>
            How to fix meetings
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Box my="20px">
              <Flex lineHeight={1.6}>
                {meetings?.filter((meeting) => meeting?.status === "sent")
                  ?.length > 0 ? (
                  <Box ml="-12px" mr="5px" mt="3px">
                    <BsFillCheckCircleFill color="green" size="18px" />
                  </Box>
                ) : (
                  <Text pr="5px">1.</Text>
                )}
                <Text mb="8px" fontWeight={"bold"}>
                  Create a meeting poll
                </Text>
              </Flex>
              <Text fontSize={"12px"} pl="15px" mb="10px" lineHeight={1.2}>
                Define the poll title, description, closing date of the poll and
                duration as well as select preferred meeting times.
              </Text>
              <Button
                fontSize={"12px"}
                ml="17px"
                px="18px"
                onClick={() => navigate("/create-poll")}
              >
                Create Poll
              </Button>
              <Divider mt="10px" />
            </Box>
            <Box>
              <Flex lineHeight={1.6}>
                {contacts?.length > 0 ? (
                  <Box ml="-12px" mr="5px" mt="3px">
                    <BsFillCheckCircleFill color="green" size="18px" />
                  </Box>
                ) : (
                  <Text pr="5px">2.</Text>
                )}
                <Text fontWeight={"bold"} mb="8px">
                  Create Contact
                </Text>
              </Flex>
              <Text fontSize={"14px"} pl="15px" mb="10px" lineHeight={1.2}>
                Create and manage frequently used email for easy access.
              </Text>
              <CustomModal
                buttonText="Add Contact"
                fontSize={"12px"}
                buttonProps={{
                  _hover: { background: "primary" },
                  justifyContent: "flex-start",
                  bg: "primary",
                  border: "1px solid white",
                  color: "#fff",
                  borderColor: "primary",
                  marginLeft: "17px",
                  paddingLeft: "12px",
                  paddingRight: "12px",
                }}
                modalTitle="Add Contact"
              >
                <AddContactForm AddContactForm setRefresh={setRefresh} />
              </CustomModal>
              <Divider mt="10px" />
            </Box>
            <Box mt="10px">
              <Flex lineHeight={1.6}>
                {groups?.length > 0 ? (
                  <Box ml="-12px" mr="5px" mt="3px">
                    <BsFillCheckCircleFill color="green" size="18px" />
                  </Box>
                ) : (
                  <Text pr="5px">3.</Text>
                )}
                <Text fontWeight={"bold"} mb="8px">
                  Create contact groups
                </Text>
              </Flex>
              <Text fontSize={"14px"} pl="15px" mb="10px" lineHeight={1.2}>
                Contact can be sorted in groups having names & description for
                faster invites.
              </Text>
              <CustomModal
                buttonText="Add Group"
                size="sm"
                width="100px"
                px="20px"
                py={"10px"}
                fontSize={"12px"}
                buttonProps={{
                  _hover: { background: "primary" },
                  justifyContent: "flex-start",
                  bg: "primary",
                  border: "1px solid white",
                  color: "#fff",
                  borderColor: "primary",
                  marginLeft: "17px",
                  paddingLeft: "18px",
                  paddingRight: "18px",
                }}
                modalTitle="Add Group"
              >
                <CreateGroupForm setRefresh={setRefresh} />
              </CustomModal>
              <Divider mt="10px" />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
