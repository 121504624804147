import { useState, useRef } from "react";
// import { Navigate, Link } from "react-router-dom";
import {
  Button,
  Text,
  FormLabel,
  Input,
  Box,
  // InputGroup,
  // InputRightElement,
  // Tooltip,
} from "@chakra-ui/react";
import GoogleLoginButton from "./GoogleLoginButton";
// import { emailLoginUser, loginUser } from "../../../features/user";
// import {  useSelector } from "react-redux";
// import { FiEye, FiEyeOff } from "react-icons/fi";
import { getUser } from "../services/getUser";
import { useEffect } from "react";
import { errorNotifier } from "../../../components/NotificationHandler";

export const LoginVerification = ({ setLoginPage, email, setEmail, pcode }) => {
  // const user = useSelector((state) => state.user);
  // const dispatch = useDispatch();
  const formRef = useRef();
  // const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (
      email
        ?.toLowerCase()
        .trim()
        .match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [email]);

  // const handleClick = () => setShow(!show);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    if (!formRef.current.reportValidity()) return;
    getUser({ email })
      .then((data) => {
        // console.log("data", data);
        if (data?.message === "complete") setLoginPage("complete");
        if (data?.message === "incomplete") setLoginPage("incomplete");

        setLoading(false);
      })
      .catch((e) => {
        if (e?.response) {
          // errorNotifier(e.response?.data?.message);
        } else {
          errorNotifier("Please Check Your network and try again");
        }
        // console.log(e);
        setLoading(false);
      });
    // setLoading(false)
  };

  // if (user.name && user.email) {
  //   return <Navigate replace to="/dashboard" />;
  // }

  return (
    <>
      <Text
        as="h1"
        color="primary"
        textAlign={"left"}
        fontWeight="bold"
        fontSize={["1.7rem", "2rem", "2rem", "2.5rem"]}
        mb={["10px", "15px", "30px"]}
      >
        Welcome to Fixmeet
      </Text>
      <form ref={formRef}>
        <FormLabel htmlFor="email" fontSize={["16px", "20px"]} isRequired>
          Enter email to continue{" "}
        </FormLabel>
        <Input
          bg="#fff"
          h={["40px", "40px", "50px"]}
          width={["96%", "100%", "100%", "72%"]}
          onChange={handleChange}
          mb={"40px"}
          id="email"
          name="email"
          placeholder="Enter your email"
          value={email.trim()}
          required
          // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
        />

        <Button
          h={["40px", "40px", "50px"]}
          width={["96%", "100%", "100%", "72%"]}
          isLoading={loading}
          mb={["20px", "30px"]}
          type="submit"
          onClick={handleSubmit}
          // mb={2}
          isDisabled={disabled}
          _hover={{ bg: "primary" }}
          fontSize="20px"
        >
          Continue
        </Button>
        <Box mb={[0, "40px", 0]}>
          <GoogleLoginButton pcode={pcode} />
        </Box>
      </form>
    </>
  );
};
