import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import http from "../../../services/api";
import { AUTH_ROUTES } from "../../../services/routes.constants";

export const sendVote = async (payload) => {
  try {
    const response = await http.post(AUTH_ROUTES.SEND_VOTE, payload);
    successNotifier(response?.data?.message);
    return response;
  } catch (e) {
    // setLoading(false)
    errorNotifier(e.response?.data?.message);
  }
};

//None works for me
export const voteAllNo = async (payload) => {
  try {
    const response = await http.post(AUTH_ROUTES.VOTE_ALL_NO, payload);
    // console.log("voted", response)
    successNotifier(response?.data?.message);
    return response;
  } catch (e) {
    // setLoading(false)
    errorNotifier(e.response?.data?.message);
  }
};

//All works for me
export const voteAllYes = async (payload) => {
  try {
    const response = await http.post(AUTH_ROUTES.VOTE_ALL_YES, payload);
    // console.log("voted", response)
    successNotifier(response?.data?.message);
    return response;
  } catch (e) {
    // setLoading(false)
    errorNotifier(e.response?.data?.message);
  }
};
