import { AUTH_ROUTES } from "../../../services/routes.constants";
import http from "../../../services/api";

export const selectSingleDates = async (data) => {
  const result = await http.post(AUTH_ROUTES.SELECT_SINGLE_DATES, data);
  return result;
};

export const selectMultipleDates = async (data) => {
  const result = await http.post(AUTH_ROUTES.SELECT_MULTIPLE_DATES, data);
  return result;
};
