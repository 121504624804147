import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Flex } from "@chakra-ui/react";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Banner from "./components/Banner";
import { useDispatch, useSelector } from "react-redux";
import { savePollData, savePollTimes } from "../../features/poll";
import { errorNotifier } from "../../components/NotificationHandler";
import { getPollSummary, getPollTimes } from "../home/services/pollSummary";
import bannerImg from "./components/images/banner.png";
import bannerTwo from "./components/images/cover-two.jpg";
import skedu from "./components/images/skedu.png";
import { LoginVerification } from "./components/LoginVerification";

const LoginSignUp = () => {
  const [email, setEmail] = useState("");
  // const [login, setLogin] = useState(true);
  const [loginPage, setLoginPage] = useState("neutral");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.userId);
  const url = window.location.href;
  const urlString = new URL(url);
  const pcode = urlString.searchParams?.get("pcode");
  // console.log("pcode", pcode);
  const slideImages = [bannerImg, bannerTwo, skedu];

  if (localStorage.getItem("11#221#")) {
    if (pcode) {
      getPollSummary(pcode)
        .then((data) => {
          dispatch(savePollData(data));
          if (
            data?.poll_description.closed === 1 &&
            userId === data?.poll_description?.user_id
          ) {
            navigate("/final-dates");
          } else if (data?.poll_description.closed === 0) {
            navigate("/vote");
          } else if (
            data?.poll_description.closed === 1 &&
            userId !== data?.poll_description?.user_id
          ) {
            errorNotifier("This Poll is closed");
            navigate("/dashboard");
          } else {
            errorNotifier("Invalid Poll");
            navigate("/dashboard");
          }
        })
        // .then(() => setLoadSpinner(false))
        .catch((err) => {
          window.location.href = "/login";
          localStorage.clear();
        });
      getPollTimes(pcode).then((data) => {
        dispatch(savePollTimes(data));
      });
      // return;
      // };
    }
    return <Navigate replace to="/dashboard" />;
  }
  return (
    <Flex
      h={["100vh", "100%", "100vh"]}
      bg="#e9e9e9"
      w="100%"
      direction={["column", "column", "row"]}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Flex
        direction="column"
        justifyContent={"center"}
        w={["100%", "100%", "50%"]}
        px={["30px", "40px", "70px"]}
        h="100%"
        order={[2, 2, 1]}
        sx={{
          "&": {
            " > form": {
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "stretch",
            },
          },
        }}
      >
        {loginPage === "complete" ? (
          <Login pcode={pcode} email={email} setLoginPage={setLoginPage} />
        ) : loginPage === "incomplete" ? (
          <Signup pcode={pcode} email={email} setLoginPage={setLoginPage} />
        ) : (
          <LoginVerification
            setLoginPage={setLoginPage}
            email={email}
            setEmail={setEmail}
            pcode={pcode}
          />
        )}
      </Flex>
      <Flex
        w={["100%", "100%", "50%"]}
        h={["220px", "100%"]}
        display={["block", "flex"]}
        justify="center"
        alignItems="center"
        order={[1, 1, 2]}
        p="20px"
      >
        <Banner
          w="100%"
          boxH={["100%", "95vh"]}
          h={["220px", "100%"]}
          data={slideImages}
        />
      </Flex>
    </Flex>
  );
};

export default LoginSignUp;
