import { useState, useRef } from "react";
import { Button, Flex, FormLabel, Input, Textarea } from "@chakra-ui/react";
import { createGroup } from "../services/groupContact";

const CreateGroupForm = ({ onClose, setRefresh }) => {
  const [formValues, setFormValues] = useState({
    groupName: "",
    groupDescription: "",
  });
  const [loading, setLoading] = useState(false);
  const formRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formRef.current.reportValidity()) return;
    // console.log(formValues);
    setLoading(true);
      await createGroup({
        group_name: formValues.groupName,
        group_description: formValues.groupDescription,
      }).then(()=> setRefresh([]));
      onClose();
      setLoading(false);
  };

  return (
    <Flex
      sx={{
        "& > form": {
          display: "flex",
          flexDirection: "column",
          width: "100%",
        },
      }}
    >
      <form ref={formRef}>
        <FormLabel>Group Name</FormLabel>
        <Input
          placeholder="Enter group name"
          onChange={handleChange}
          mb={4}
          name="groupName"
          value={formValues.groupName}
          required
        />
        <FormLabel>Description</FormLabel>
        <Textarea
          placeholder="Enter short description about the group"
          onChange={handleChange}
          mb={4}
          name="groupDescription"
          value={formValues.groupDescription}
          resize="none"
          required
        />
        <Button
          alignSelf="stretch"
          type="submit"
          onClick={handleSubmit}
          isLoading={loading}
        >
          Create
        </Button>
      </form>
    </Flex>
  );
};

export default CreateGroupForm;
