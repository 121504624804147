import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import http from "../../../services/api";
import { AUTH_ROUTES } from "../../../services/routes.constants";

export const createGroup = async (formData) => {
  try {
    const response = await http.post(AUTH_ROUTES.CREATE_GROUP, formData);
    // return response;
    successNotifier(response.data.message);
    // onClose();
  } catch (e) {
    console.log(e.response);
    errorNotifier(e.response);
  }
};

export const getGroups = async (setLoading, setGroups) => {
  try {
    const response = await http.get(AUTH_ROUTES.GET_GROUPS);
    setGroups(response?.data?.data);
    setLoading(false);
  } catch (e) {
    setLoading(false);
  }
};

export const getOtherContacts = async (
  setLoading,
  setOtherContacts,
  groupId
) => {
  try {
    const response = await http.get(AUTH_ROUTES.GET_OTHER_MEMBERS(groupId));
    setOtherContacts(response?.data?.data);
    setLoading(false);
  } catch (e) {
    setLoading(false);
  }
};

export const getGroupMembers = async (setLoading, setGroupMembers, groupId) => {
  try {
    const response = await http.get(AUTH_ROUTES.GET_GROUP_CONTACTS(groupId));
    setGroupMembers(response?.data?.data);
    // console.log("mmm", response)
    setLoading(false);
  } catch (e) {
    setLoading(false);
  }
};

export const searchGroup = async (inputValue, setSearchGroups) => {
  try {
    const response = await http.get(AUTH_ROUTES.SEARCH_GROUP(inputValue));

    // setSearchGroups(response.data.data);
    setSearchGroups({ response: response?.data?.data, show: true });
    // console.log(response)
  } catch (e) {
    setSearchGroups({ response: [], show: false });
  }
};

export const deleteGroup = async (id) => {
  try {
    const response = await http.delete(AUTH_ROUTES.DELETE_GROUP(id));
    successNotifier(response?.data?.message);
  } catch (e) {
    errorNotifier();
  }
};

export const editGroup = async (id, payload, setLoading) => {
  try {
    const response = await http.put(AUTH_ROUTES.EDIT_GROUP(id), payload);
    setLoading(false);
    successNotifier(response?.data?.message);
  } catch (e) {
    setLoading(false);
    errorNotifier();
  }
};

export const addMembersToGroup = async (payload, onClose, setLoading) => {
  try {
    setLoading(true);
    const response = await http.post(AUTH_ROUTES.ADD_MEMBERS_TO_GROUP, payload);
    successNotifier(response?.data?.message);
    // console.log("mem", response)
    onClose();
  } catch (e) {
    errorNotifier();
  } finally {
    setLoading(false);
  }
};

export const deleteMembersFromGroup = async (payload, onClose, setLoading) => {
  try {
    setLoading(true);
    const response = await http.post(
      AUTH_ROUTES.REMOVE_MEMBERS_FROM_GROUP,
      payload
    );
    successNotifier(response?.data?.message);
    onClose();
  } catch (e) {
    errorNotifier();
  } finally {
    setLoading(false);
  }
};
