import React from "react"
import { Box, Heading, Img, Stack, Text } from "@chakra-ui/react"
import messageDot from "../../assets/message-dot.svg"

import imgDecor from "../../assets/img-decor.png"

export const TestimonialContent = ({
  description,
  img,
  companyImg,
  name,
  company,
  ...props
}) => {
  return (
    <Stack
      w="100% "
      display="flex"
      justifyContent="space-between"
      direction={["column-reverse", "row"]}
      width="100%"
      alignItems="center"
      {...props}
    >
      <Box color="#fff">
        <Heading
          fontSize={["1em", "1.2em"]}
          marginTop={["20px", "initial"]}
          maxWidth={["300px", "400px"]}
          textAlign={["center", "initial"]}
          lineHeight="1.5"
        >
          {description}
        </Heading>
        <Stack
          direction="row"
          alignItems="center"
          mt="20px"
          justifyContent={["center", "initial"]}
        >
          {/* <Img width="40px" src={companyImg} alt="company img" /> */}
          <Box>
            <Text color="#FEF5E4">{name}</Text>
            <Text fontSize=".5em">{company}</Text>
          </Box>
        </Stack>
      </Box>
      <Box position="relative">
        <Img
          src={messageDot}
          alt="message dot"
          position="absolute"
          left={["-60px", "-80px"]}
          top={["70px", "140px"]}
          width={["40px", "70px"]}
        />
        <Img
          src={imgDecor}
          alt="dots"
          position="absolute"
          bottom="-10px"
          left="-40px"
          width="120px"
        />
        <Img
          width={["100px", "200px"]}
          height={["100px", "200px"]}
          src={img}
          alt="person"
          position="relative"
          borderRadius={"50%"}
        />
      </Box>
    </Stack>
  )
}
