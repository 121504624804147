import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import http from "../../../services/api";
import { AUTH_ROUTES } from "../../../services/routes.constants";

export const forgotPassword = async (payload, setLoading) => {
  try {
    const response = await http.post(AUTH_ROUTES.FORGOT_PASSWORD, payload);
    // console.log("password", response);
    setLoading(false);
    successNotifier(response?.data?.message);
    return response;
  } catch (e) {
    setLoading(false);
    errorNotifier(e.response?.data?.message);
  }
};
