import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Heading,
  Img,
  Stack,
  Text,
  Image,
} from "@chakra-ui/react";
import { Nav } from "../nav";
import surprise from "../assets/user-surprise.png";

import { FeatureList } from "../component/FeatureList";

import messageCircle from "../assets/message-circle.svg";
import videoIcon from "../assets/video-icon.svg";
import circleLine from "../assets/Mask Group 9.svg";
import dot from "../assets/dots.svg";
import award from "../assets/award.svg";
import bigCircle from "../assets/big-circle.svg";
// import { VideoShowCase } from "../component/VideoShowCase";
import { Testimonial } from "../component/testimonial/Testimonial";
import { useState } from "react";
import { SignupModal } from "../component/SignupModal";
import { Footer } from "../component/Footer";

import semiCircle from "../assets/semiCircle.svg";
import semiCircleRight from "../assets/semiRight.svg";
import { useNavigate } from "react-router-dom";

export const FrontPage = () => {
  const [showSignup, setShowSignup] = useState(false);
  const navigate = useNavigate()

  return (
    <>
      <Box px={["10px", "20px", "150px"]}>
        <SignupModal showSignup={showSignup} setShowSignup={setShowSignup} />
        <Nav setShowSignup={setShowSignup} />
      </Box>

      <Box
        height="100%"
        width="100%"
        id="home"
        // fontFamily="Trenda-regular !important"
        bg="#fff"
        px={["10px", "50px", "50px", "50px", "300px"]}
        pb="40px"
        textAlign="center"
      >
        <Img
          src={bigCircle}
          alt="big circle"
          position="absolute"
          width="1100px"
          top="0"
          left="50%"
          zIndex="8"
          transform="translate(-50%, -50%)"
        />
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          mb="150px"
          position="relative"
          zIndex="9"
        >
          <Img
            src={circleLine}
            alt="circle line"
            position="absolute"
            left="-50px"
            top="100px"
            zIndex="9"
            width={["80px", "initial"]}
          />
          <Box
            position="relative"
            justifyContent="center"
            alignItems="center"
            w="100px"
            h="100px"
            mb="20px"
          >
            <Img src={messageCircle} alt="video" position="absolute" />
            <Img
              src={videoIcon}
              alt="video icon"
              position="absolute"
              top="30px"
              left="25px"
            />
          </Box>

          <Heading
            fontSize={["2em", "3em"]}
            color="#6C4097"
            mb="20px"
            // fontFamily="Trenda-bold"
          >
            Meetings Made Simple
          </Heading>
          <Text
            maxW="480px"
            textAlign="center"
            color="#707070"
            fontSize={["1.2em", "1.2em", "1.7em"]}
            mb="20px"
            lineHeight={"1.2"}
          >
            Save time choosing when to meet in groups
          </Text>
          <Button
            color="#fff"
            bg="#6C4097"
            _hover={{ bg: "#6C4097" }}
            boxShadow="2px 12px 20px 0px #6c409773"
            width="150px"
            onClick={() => navigate("/login")}
            // onClick={() => setShowSignup(true)}
          >
            Create
          </Button>
          <Img
            src={dot}
            alt="circle line"
            position="absolute"
            right={["10px", "-30px"]}
            top={["280px", "250px"]}
            width={["80px", "initial"]}
          />
        </Box>

        <section>
          <img src={surprise} alt="surprise face" />
        </section>

        <section id="about-fixmeet">
          <Heading fontSize="1.5em" color="#6C4097" my="40px">
            Key Features
          </Heading>
          <FeatureList />
        </section>

        {/* <section>
        <Heading my="40px" fontSize="1.5em" color="#6C4097">
          Companies that trust us
        </Heading>
        <Flex
          w="100%"
          h="200px"
          bg="#F8F8F8"
          p="10px"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Image
            src={sua}
            width={["80px", "150px"]}
            height={["20px", "50px"]}
            alt="Skillup Africa logo"
          />
          <Image
            src={euidem}
            width={["80px", "150px"]}
            height={["20px", "50px"]}
            alt="Euidem logo"
          />
          <Image
            src={metamorphosis}
            width={["80px", "150px"]}
            height={["20px", "50px"]}
            alt="Metamorphosis logo"
          />
          <Image
            src={zealight}
            width={["80px", "150px"]}
            height={["20px", "50px"]}
            alt="Zealight Labs logo"
          />
        </Flex>
      </section> */}
        <Box my="100px" position={"relative"}>
          <Box
            position={"absolute"}
            right={["-3%", 0, "-40%", "-40%"]}
            top={[0, 0, "-45%"]}
            width={["350px", "150px", "350px"]}
          >
            <Image src={semiCircleRight} alt="" />
          </Box>
          {/* <Box position={"relative"} zIndex={100} id="video-tutorial">
            <VideoShowCase />
          </Box> */}
        </Box>
        <Box position={"relative"}>
          <Box position={"relative"} zIndex={100}>
            <Testimonial />
          </Box>
          <Box position={"absolute"} left={"-39.8%"} top="10%" width={"350px"}>
            <Image src={semiCircle} alt="" />
          </Box>
        </Box>
        <section style={{ textAlign: "left", marginBottom: "20px" }}>
          <Box
            bg="#6c409782"
            p="30px 40px"
            borderRadius="10px"
            position={"relative"}
            zIndex={100}
          >
            <Stack
              direction={["column", "row"]}
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack
                alignItems="center"
                direction={["column", "row"]}
                spacing="30px"
              >
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  width="80px"
                  h="80px"
                  borderRadius="full"
                  bg="#fff"
                >
                  <Img src={award} alt="award" />
                </Flex>

                <Stack>
                  <Heading fontSize="1.5em" color="#fff">
                    Set a professional tone
                  </Heading>
                  <Text
                    color="#fff"
                    textAlign={["center", "left"]}
                    maxW="250px"
                    fontSize=".9em"
                  >
                    Too many changes and updates can look unorganized
                  </Text>
                </Stack>
              </Stack>
              <Link to="/login">
                <Button
                  color="#fff"
                  bg="#6C4097"
                  _hover={{ bg: "#6C4097" }}
                  boxShadow="2px 12px 20px 0px #6c409773"
                  width="150px"
                  // onClick={() => setShowSignup(true)}
                >
                  Get Started
                </Button>
              </Link>
            </Stack>
          </Box>
        </section>
      </Box>
      <Box position={"relative"} zIndex={100}>
        <Footer />
      </Box>
    </>
  );
};
