import {
  Button,
  Divider,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { MdUploadFile } from "react-icons/md";
import { FileInput } from "./FileInput";
import sampleFile from "../images/bulkSample.PNG";

export default function BulkUpload({ setInputFile, modalName, bg }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef();
  const finalRef = React.useRef();

  return (
    <>
      <Button
        bg={bg || "primary"}
        height={"38px"}
        leftIcon={<MdUploadFile color={"white"} />}
        onClick={onOpen}
      >
        {modalName || "Bulk"}
      </Button>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload Bulk Contacts</ModalHeader>
          <Text px="25px">See sample below</Text>
          <ModalCloseButton />
          <Divider mb="3px" />
          <ModalBody mb="10px">
            <Image sizes="md" src={sampleFile} alt="bulk contact sample" />
          </ModalBody>

          <ModalFooter>
            <FileInput setInputFile={setInputFile} />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
